// General Use
import React from "react";
import RocketBoy from "../../../DesignAssets/Rocket-Boy.png";
import { Button } from "react-bootstrap";

// Initial Profile Landing Step
const ProfileSetupInitial = (props) => {
    return (
        <section className="guide-profile-process-1-wrapper">
            <img src={RocketBoy} className="rocket-boy-bg" alt="Rocket Boy" />
            <div className="guide-process-1-inner">
                <p className="guide-through-head">Set up your Profile</p>
                <div className="guidethrough-body">
                    <p>
                        Create a personna on who you are to make connecting with people
                        easier and fun!
                    </p>
                </div>
                <Button className="continue-button" onClick={() => props.goNext("initial")}>Continue</Button>
            </div>
        </section>
    )
}

export default ProfileSetupInitial