/* eslint-disable jsx-a11y/img-redundant-alt */
//General Imports
import React, { useState, useRef, useEffect } from 'react';
import { sendEmailLink } from '../../../GeneralUse/FunctionBucket';
import { Button, Form } from 'react-bootstrap';
import { checkFileValid, fileConstraints } from "../../../GeneralUse/CheckValidFunctions"
import { generateKeywords } from "../../../GeneralUse/PrimeFunctions"
import { capitalizeName } from "../../../GeneralUse/FunctionBucket"
import { LoadingSmallIcon } from "../../../Loading/LoadingPageIcon"
import { addFilesToDatabase } from "../../../GeneralUse/UploadFirebase"
import { Trash } from 'react-feather'
import { changeMatching, changeMyUserProfile } from '../../../Redux/actions';

import { firebaseAnalytics } from "../../../firebase";
import { X, File, UserPlus } from 'react-feather'

import { useSelector, useDispatch } from 'react-redux';
import myFirebase from '../../../firebase';

import timelessPicture from "../../../DesignAssets/Time.png";
import MarketPlacePartnerstackImg from "../../../DesignAssets/partnerstack-logo-vector-condensed.svg";
import MarketPlacePay2UImg from "../../../DesignAssets/pay2u-logo.svg";

export const PopupSendEmail = (props) => {
    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) { props.closePopup() }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => { document.removeEventListener("mousedown", handleClickOutside) }
        }, [ref])
    }
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const [emailInput, changeEmailInput] = useState("")
    const [nameInput, changeNameInput] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const [emailSubmitted, setEmailSubmitted] = useState(false)
    const [emailSending, setEmailSending] = useState(false)

    const { myCompany } = useSelector(state => ({ // For each redux state you want to access, i dont actually use it here but serves as example
        myCompany: state.myCompany,
    }))

    function submitEmail(e) {
        e.preventDefault()
        if (nameInput.length === 0) {
            setErrorMessage("Name can't be empty!")
            return
        }
        if (emailInput.length === 0) {
            setErrorMessage("Your email input can't be empty!")
            return
        }
        setEmailSending(true)
        sendEmailLink(nameInput, emailInput, myCompany.companyName).then((emailresponse) => {
            setEmailSending(false)
            setEmailSubmitted(true)
        })
    }
    if (emailSubmitted) {
        return (
            <div className='popup' id="email-popup-wrapper">
                <div className='popup_inner'>
                    <div className="email-successful-response">
                        <h5>Email Sent!</h5>
                        <p>The email is on the way!</p>
                    </div>
                    <button className="close-popup-small-x-bttn" onClick={props.closePopup}><X className="cancel-icon" /></button>
                </div>
            </div>
        )
    }
    return (
        <div className='popup' id="email-popup-wrapper">
            <div className='popup_inner' ref={wrapperRef}>
                <h4>Send Email Invite</h4>
                <form onSubmit={submitEmail}>
                    <div>
                        <p className="send-email-db-topic">This person will receive an email with a link to the Coopsight network.</p>
                    </div>
                    <div>
                        <label>Person Name</label>
                        <input
                            placeholder="Enter the person's name..."
                            value={nameInput}
                            disabled={emailSending}
                            type="text"
                            onChange={(e) => { changeNameInput(e.target.value); setErrorMessage("") }}
                        />
                    </div>
                    <div>
                        <label>Email Address</label>
                        <input
                            placeholder="Enter the person's email address..."
                            value={emailInput}
                            disabled={emailSending}
                            type="email"
                            onChange={(e) => { changeEmailInput(e.target.value); setErrorMessage("") }}
                        />
                    </div>
                    <p className="error-message">{errorMessage}</p>

                    <Button className="send-email-join" disabled={emailSending} type="submit">{emailSending ? ('Loading...') : ('Send')}</Button>
                </form>
                <button className="close-popup-small-x-bttn" onClick={props.closePopup}><X className="cancel-icon" /></button>
            </div>
        </div>
    )
}

export const PublicFilesPopup = (props) => {
    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) { props.closePopup() }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => { document.removeEventListener("mousedown", handleClickOutside) }
        }, [ref])
    }
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    return (
        <div className='popup' id="ufiles-popup-wrapper">
            <div className='popup_inner' ref={wrapperRef}>
                <h4>Choose Public Files</h4>
                <ul className="popup-files-middle-wrapper">
                    {props.files.length === 0 && (
                        <h6>This company does not have any files!</h6>
                    )}
                    {props.files.map((eachFile, index) => {
                        return (
                            <li key={index}>

                                {eachFile.name}
                            </li>
                        )
                    })}
                </ul>
                <div className="popup-files-bottom-wrapper">
                    <Button className="cancel-button-popup-files" onClick={props.closePopup}>Cancel</Button>
                    <Button className="confirm-button-popup-files" onClick={props.closePopup}>Confirm</Button>
                </div>
            </div>
        </div>
    )
}

export const PrivateFilesPopup = (props) => {
    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) { props.closePopup() }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => { document.removeEventListener("mousedown", handleClickOutside) }
        }, [ref])
    }
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const [combinedFiles, setCombinedFiles] = useState(props.company.files.filesList)
    const [newFiles, setNewFiles] = useState([])
    const [errorMessage, setErrorMessage] = useState("")

    function addFileToForm(e) {
        if (errorMessage.length > 0) { setErrorMessage("") }
        const newFile = e.target.files[0];
        let inputCheck = checkFileValid(newFile, combinedFiles)
        document.getElementById("file").value = "";
        if (!inputCheck.valid) {
            setErrorMessage(inputCheck.message)
        } else {
            setErrorMessage("")
            let newFilesCopy = [...newFiles]
            newFilesCopy.push({ name: newFile.name, fileBlob: newFile })
            setNewFiles(newFilesCopy)
            let combinedFilesCopy = [...combinedFiles]
            combinedFilesCopy.push({ name: newFile.name, fileBlob: newFile })
            setCombinedFiles(combinedFilesCopy)
        }
    }
    function removeFile(index, type) {
        const combinedFilesCopy = [...combinedFiles]
        combinedFilesCopy.splice(index, 1)
        setErrorMessage("")
        setCombinedFiles(combinedFilesCopy)
    }

    async function submitFilesChange() {
        triggerUpdating(true)

        let cleanNewFiles = []
        let cleanOldFiles = []

        combinedFiles.forEach(eachFile => { if (!!eachFile.fileBlob) { cleanNewFiles.push(eachFile) } else { cleanOldFiles.push(eachFile) } })
        let fileBinRef = props.company.files.selfref
        const filesUpdateResponse = await addFilesToDatabase(props.company.selfid, cleanNewFiles, { oldFiles: cleanOldFiles, fileBinRef: fileBinRef })

        let cleanFileURIS = filesUpdateResponse.filesList.map(eachFile => { return eachFile.fileURI })
        await generateKeywords(props.company.selfid, props.parentCompany.type, props.parentCompany.selfid, props.company.selfref.path, cleanFileURIS)

        // edits local
        props.company.files.filesList = filesUpdateResponse.filesList
        triggerUpdating(false)
        props.closePopup()
    }

    const [isUpdating, triggerUpdating] = useState(false)

    return (
        <div className='popup' id="private-files-popup-wrapper">
            <div className='popup_inner' ref={wrapperRef}>
                <h4>Edit Private Files</h4>
                {isUpdating ? (
                    <div className="updating-company-wrapper">
                        <LoadingSmallIcon />
                        <p className="smll-loading-txt">Please wait, while we update this company...</p>
                    </div>
                ) : (
                    <div>
                        <div className="add-private-file-main-wrapper">
                            <input
                                type="file"
                                onChange={addFileToForm}
                                accept={fileConstraints.fileTypes}
                                disabled={isUpdating}
                                className="add-private-file-input-button"
                                size="60"
                                id="file"
                            />
                            <label for="file">Select File</label>
                        </div>

                        <p className="error-message">{errorMessage}</p>
                        <ul className="popup-files-middle-wrapper">
                            {combinedFiles.length === 0 && (<h6>This company does not have any files!</h6>)}
                            {combinedFiles.map((eachFile, index) => {
                                return (
                                    <li key={index}>
                                        <File className="upload-file-icon" size={24} />
                                        <p className="upload-file-name">{eachFile.name}</p>
                                        <Trash className="upload-file-trash-icon" size={24} disabled={isUpdating} onClick={() => removeFile(index)} />
                                    </li>
                                )
                            })}
                        </ul>


                        <div className="private-files-bottom-button-wrapper">
                            <Button className="cancel-button-popup-files" disabled={isUpdating} onClick={props.closePopup}>Cancel</Button>
                            <Button className="confirm-button-popup-files" disabled={isUpdating} onClick={submitFilesChange}>Confirm</Button>
                        </div>
                    </div>
                )
                }
                <button className="close-popup-small-x-bttn" onClick={() => { props.closePopup() }}><X className="cancel-icon" /></button>
            </div>
        </div>
    )
}

export const FiltersT1Popup = (props) => {
    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) { props.closePopup() }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => { document.removeEventListener("mousedown", handleClickOutside) }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [ref, props.closePopup])
    }
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    return (
        <div className='popup' id="filters-t1-popup-wrapper">
            <div className='popup_inner' ref={wrapperRef}>
                <h4 className="filter-header">Match Filters</h4>
                <h6 className="filter-sub-header">I'm Interested In...</h6>
                <div className="filter-bottom-bulk">
                    <p className="filter-advanced-desc">Filters coming soon...</p>
                    <p className="filter-check-back">Please check our blog <a href="https://coopsight.medium.com/" target="_blank">here</a> to check for updates!</p>
                </div>

                <button className="close-popup-small-x-bttn" onClick={() => { props.closePopup() }}><X className="cancel-icon" /></button>
            </div>
        </div>
    )
    /////////////////////////
}

export const FiltersT2Popup = (props) => {
    const dispatch = useDispatch()
    const { matching, myUserProfile } = useSelector(state => ({ // For each redux state you want to access, i dont actually use it here but serves as example
        matching: state.matching,
        myUserProfile: state.myUserProfile
    }))
    const [currentMatchFilters, changeMatchFilters] = useState(myUserProfile.currentMatchFilters.length !== 0 ? myUserProfile.currentMatchFilters[0] : "");
    async function confirmCurrentMatchFilters() {
        if (currentMatchFilters.length === 0) {
            return
        }
        if (myUserProfile.currentMatchFilters[0] === currentMatchFilters) {
            props.closePopup(currentMatchFilters)
            return
        }

        props.closePopup(currentMatchFilters)

        let filtersToDatabase = null
        if (currentMatchFilters === "corporation") {
            filtersToDatabase = ["corporation", "investor"]
        } else {
            filtersToDatabase = ["startup"]
        }
        myUserProfile.currentMatchFilters = filtersToDatabase
        matching.deck = []
        dispatch(changeMyUserProfile(myUserProfile))

        await myUserProfile.selfref.update({ currentMatchFilters: filtersToDatabase })

        dispatch(changeMatching(matching))

    }
    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) { confirmCurrentMatchFilters(currentMatchFilters) }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => { document.removeEventListener("mousedown", handleClickOutside) }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [ref, currentMatchFilters])
    }
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    return (
        <div className='popup' id="filters-t1-popup-wrapper">
            <div className='popup_inner' ref={wrapperRef}>
                <h5 className="match-filters-subheader">Match Filters</h5>
                <div className="top-filter-selection-bulk">
                    <h5 className="filter-filter-topic">I'm interested in...</h5>
                    <div className="toggle-filter-group-wrapper">
                        <Button
                            className={
                                currentMatchFilters === "startup"
                                    ? "each-filter-value selected-filter"
                                    : "each-filter-value"
                            }
                            onClick={() => {
                                changeMatchFilters("startup")
                            }}>
                            Startups
                    </Button>
                        <Button
                            className={
                                currentMatchFilters === "corporation"
                                    ? "each-filter-value selected-filter"
                                    : "each-filter-value"
                            }
                            onClick={() => {
                                changeMatchFilters("corporation")
                            }}>
                            Investors & Corporations
                </Button>
                    </div>
                </div>
                <div className="bottom-filter-selection-bulk">
                    <h5 className="filter-filter-topic">Advanced</h5>
                    <p className="filter-advanced-desc">More filters coming soon...</p>
                </div>
                <button className="close-popup-small-x-bttn" onClick={() => { confirmCurrentMatchFilters(currentMatchFilters) }}><X className="cancel-icon" /></button>
            </div>
        </div>
    )
}

export const SelectChatWithPopup = (props) => {
    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) { props.closePopup() }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => { document.removeEventListener("mousedown", handleClickOutside) }
        }, [ref])
    }
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    return (
        <div className='popup' id="select-chat-with-popup-wrapper">
            <div className='popup_inner' ref={wrapperRef}>
                <h5>Select Who to Chat With</h5>
                <h6>Contacts From {props.likedCompany.companyName}</h6>
                <ul>
                    {props.team.admins.map((eachMember, index) => {
                        return (
                            <li key={index} onClick={() => props.matchSuccess(eachMember, props.score, props.likedCompany)}>
                                <img className="mini-popup-profile-picture"
                                    src={eachMember.profilePicture.fileURL}
                                    alt="" />
                                <div className="mini-popup-profile-name">{eachMember.name}</div>
                            </li>
                        )
                    })}
                </ul>
                <button className="close-popup-small-x-bttn" onClick={props.closePopup}><X className="cancel-icon" /></button>
            </div>
        </div>
    )
}

export const SelectChatWithT1Popup = (props) => {
    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) { props.closePopup() }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => { document.removeEventListener("mousedown", handleClickOutside) }
        }, [ref])
    }
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    return (
        <div className='popup' id={props.team.admins.length === 0 ? "select-chat-with-t1-popup-wrapper-empty" : "select-chat-with-t1-popup-wrapper"}>
            <div className='popup_inner' ref={wrapperRef}>
                {props.team.admins.length === 0 ? (
                    <div>
                        <h5>Select Who To Chat With</h5>
                        <h6 className="contacts-empty-from-explain">Please note that this company still has users pending</h6>
                        <h6 className="contacts-from-header">Available Company Chats</h6>
                        <ul>
                            <li onClick={() => props.redirectToChat(true)}>
                                <div className="user-plus-icon-wrapper">
                                    <UserPlus className="user-plus-icon" />
                                </div>
                                <div className="company-direct-chat-with-name">{capitalizeName(props.likedCompany.companyName)}</div>
                            </li>
                        </ul>
                    </div>
                ) : (
                    <div>
                        <h5 className="next-steps-match-header">Next Steps After Matching</h5>
                        <Button
                            className="go-to-chat-bttn-from-match"
                            onClick={() => props.redirectToChat(true)}>Go To Chat</Button>
                        <h6 className="marketplace-header">Marketplace Tools</h6>
                        <ul className="marketplace-list">
                            <li className="each-marketplace-app">
                                <a href="https://partners.grsm.io/coopsight" target="_blank" rel="noreferrer">
                                    <img
                                        src={MarketPlacePartnerstackImg}
                                        onClick={() => firebaseAnalytics.logEvent("partnerstackReferralRedirect", {
                                            timestamp: new Date(),
                                        })}
                                        alt="PartnerStack Image"
                                        className="marketplace-app-img-1"
                                    />
                                </a>
                                <h6 className="marketplace-app-header">PartnerStack helps manage partnerships, and build new revenue streams through Partnerships </h6>
                            </li>
                            <li className="each-marketplace-app">
                                <a href="https://pay2u.ru/" target="_blank" rel="noreferrer">
                                    <img
                                        src={MarketPlacePay2UImg}
                                        onClick={() => firebaseAnalytics.logEvent("pay2uReferralRedirect", {
                                            timestamp: new Date(),
                                        })}
                                        alt="Pay2U Image"
                                        className="marketplace-app-img-2"
                                    />
                                </a>
                                <h6 className="marketplace-app-header">PAY2U is a platform that helps embed affiliate services, offers and content inside mobile/web applications </h6>
                            </li>
                        </ul>
                    </div>
                )
                }
                <button className="close-popup-small-x-bttn" onClick={props.closePopup}><X className="cancel-icon" /></button>
            </div >
        </div >
    )
}

// Filtering Your Chat Messages (returns the new value)
export const FilterChatPopup = (props) => {
    const [filterByValue, changeFilterValue] = useState(props.currentFilterChatMessages)
    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) { props.closePopupWithChatFilter(filterByValue) }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => { document.removeEventListener("mousedown", handleClickOutside) }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [ref, filterByValue])
    }
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    return (
        <div className='popup' id="filters-message-popup-wrapper">
            <div className='popup_inner' ref={wrapperRef}>
                <h5>Filter Chats</h5>
                <ul>
                    <li>
                        <Button className={filterByValue === "Startups" ? "filter-msg-icon-bttn-wrapper selected" : "filter-msg-icon-bttn-wrapper"} onClick={() => changeFilterValue("Startups")}>
                            <div className="smaller-circle" />
                        </Button>
                        <h6 className="filter-msg-icon-label">Startups</h6>
                    </li>
                    <li>
                        <Button className={filterByValue === "Investors" ? "filter-msg-icon-bttn-wrapper selected" : "filter-msg-icon-bttn-wrapper"} onClick={() => changeFilterValue("Investors")}>
                            <div className="smaller-circle" />
                        </Button>
                        <h6 className="filter-msg-icon-label">Investors</h6>
                    </li>
                    <li>
                        <Button className={filterByValue === "Corporations" ? "filter-msg-icon-bttn-wrapper selected" : "filter-msg-icon-bttn-wrapper"} onClick={() => changeFilterValue("Corporations")}>
                            <div className="smaller-circle" />
                        </Button>
                        <h6 className="filter-msg-icon-label">Corporations</h6>
                    </li>
                    <li>
                        <Button className={filterByValue === "Best Score" ? "filter-msg-icon-bttn-wrapper selected" : "filter-msg-icon-bttn-wrapper"} onClick={() => changeFilterValue("Best Score")}>
                            <div className="smaller-circle" />
                        </Button>
                        <h6 className="filter-msg-icon-label">Best Score</h6>
                    </li>
                    <li>
                        <Button className={filterByValue === "Recent" ? "filter-msg-icon-bttn-wrapper selected" : "filter-msg-icon-bttn-wrapper"} onClick={() => changeFilterValue("Recent")}>
                            <div className="smaller-circle" />
                        </Button>
                        <h6 className="filter-msg-icon-label">Recent</h6>
                    </li>
                </ul>
                <button className="close-popup-small-x-bttn" onClick={() => props.closePopupWithChatFilter(filterByValue)}><X className="cancel-icon" /></button>
            </div>
        </div>
    )
}

export const NoMatchesPopup = (props) => {
    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) { props.closePopup() }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => { document.removeEventListener("mousedown", handleClickOutside) }
        }, [ref])
    }
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    return (
        <div className='popup' id="no-matches-remaining-with-popup-wrapper">
            <div className='popup_inner' ref={wrapperRef}>
                <img src={timelessPicture} alt="" className="nm-image" />
                <div className="nm-wrapper">
                    <h1 className="yikes">UH OH!</h1>
                    <h5 className="ran-out-matches-sub-title">You have ran out of matches</h5>
                    <p className="dont-worry-zero-matches">But don't worry! You will get another 5 matches at the start of every week! If you want to match more now, buy more matches below!</p>
                    <button className="to-payments-from-non-matches" onClick={() => { props.history.push('/payment/main') }}>Continue to Payments</button>
                </div>
                <button className="close-popup-small-x-bttn" onClick={props.closePopup}><X className="cancel-icon" /></button>
            </div>
        </div>
    )
}
// This is the popup used when the user has run out of matches!
export const NoMatchesCondensedPopup = (props) => {
    function useOutsideAlerter(ref) { // This func just handles clicking outside of hte popup to close it
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) { props.closePopup() }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => { document.removeEventListener("mousedown", handleClickOutside) }
        }, [ref])
    }
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    // For the initial classnames, handles basic popup info (can view in src/Design/Shared/Popup)
    return (
        <div className='popup-special-t1-filter' id="con-no-matches-remaining-with-popup-wrapper">
            <div className='popup_inner' ref={wrapperRef}>
                <img src={timelessPicture} alt="" className="nm-image" /> {/* Imported at top of page */}
                <div className="nm-wrapper">
                    <h1 className="yikes">UH OH!</h1>
                    <h5 className="ran-out-matches-sub-title">You have ran out of matches</h5>
                    <p className="dont-worry-zero-matches">But don't worry! You will get another 5 matches at the start of every week! If you want to match more now, buy more matches below!</p>
                    <Button className="to-payments-from-non-matches" onClick={() => { props.history.push('/payment/main') }}>Continue to Payments</Button>
                </div>
                <button className="close-popup-small-x-bttn" onClick={props.closePopup}><X className="cancel-icon" /></button> {/* Calls parent func to change the parents state to no longer display popup */}
            </div>
        </div>
    )
}

export const ReportMatchPopup = (props) => {
    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) { props.closePopup() }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => { document.removeEventListener("mousedown", handleClickOutside) }
        }, [ref])
    }
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);


    const [reportMessageInput, handleReportMessageInput] = useState("")
    const [isLoading, setLoading] = useState(false)
    const [reportFinished, setReportStatus] = useState(false)

    async function sendReport(e) {
        e.preventDefault()

        setLoading(true)
        try {
            await myFirebase.firestore().collection("reports").add({
                reportingUserid: props.userid,
                reportingCompanyid: props.myCompany.selfid,
                reportingCompanyName: props.myCompany.companyName,
                suspectCompanyid: props.suspectCompany.selfid,
                suspectCompanyName: props.suspectCompany.companyName,
                suspectType: props.suspectCompany.type,
                reason: reportMessageInput,
                timestamp: new Date(),
            })
        } catch (error) { console.log(error) }

        setReportStatus(true)
        setLoading(false)
    }

    return (
        <div className='popup' id="report-match-popup-wrapper">
            <div className='popup_inner' ref={wrapperRef}>
                {reportFinished ? (
                    <>
                        <h5>Report Successful!</h5>
                        <p>We thank you for helping improve Coopsight! We will look into this report request and may get in touch with you.</p>
                        <Button className="close-nm-bttn" onClick={props.closePopup}><X className="cancel-icon" /></Button>
                    </>
                ) : (
                    <>
                        <h5>Report Company</h5>
                        <p>Tell us more about why you are reporting this company.</p>
                        <Form onSubmit={sendReport} className="report-company-form">
                            <textarea
                                autoComplete="off"
                                value={reportMessageInput}
                                onChange={(e) => handleReportMessageInput(e.target.value)}
                                disabled={isLoading}
                            />
                            <Button type="submit" disabled={isLoading}>Send Report</Button>
                        </Form>
                        <button className="close-popup-small-x-bttn" onClick={props.closePopup}><X className="cancel-icon" /></button>
                    </>
                )}

            </div>
        </div>
    )
}
