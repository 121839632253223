// General Use
import React, { useState } from 'react';
import myFirebase from "../../firebase"
import { useSelector, useDispatch } from 'react-redux'
import { resetStates } from '../../Redux/actions';
import { Col, Button, Form } from 'react-bootstrap';
import { updateUserLoginEmail } from "../../GeneralUse/UploadFirebase"
import { checkPasswordValid } from "../../GeneralUse/CheckValidFunctions"
import TermsOfUseBottomBar from "../Tier1/Components/TermsOfUseBottomBar"
import { handleLoginError } from "../../GeneralUse/ErrorHandling"
import { ArrowLeft, Eye, EyeOff } from 'react-feather'

const ChangeLoginInfoSettings = (props) => {
    const dispatch = useDispatch()
    const { myUserLogin } = useSelector(state => ({
        myUserLogin: state.myUserLogin,
    }))

    async function fullSignOut() { // Needs to redirect and clear redux
        await myFirebase.auth().signOut()
        props.history.push('/login')
        dispatch(resetStates())
    }

    // Password Eye Stuff
    const [showPassword1, setPassword1] = useState(false)
    const [showPassword2, setPassword2] = useState(false)

    // Handling New Email
    const [emailForm, setEmailForm] = useState({ email: "", emailConfirm: "" })
    const [emailError, setEmailError] = useState("")
    function handleEmailInput(e) {
        if (emailError.length > 0) setEmailError("")
        const emailFormCopy = { ...emailForm }
        emailFormCopy[e.target.name] = e.target.value
        setEmailForm(emailFormCopy)
    }
    async function submitEmailChange(e) {
        e.preventDefault()
        if (emailForm.email !== emailForm.emailConfirm) { // Verification for confirm
            setEmailError("Your Emails Are NOT the Same!")
            return
        }
        setVerified(true)
    }

    // Handling New Password
    const [passwordForm, setPasswordForm] = useState({ password: "", passwordConfirm: "" })
    const [passwordError, setPasswordError] = useState("")
    function handlePasswordInput(e) {
        if (passwordError.length > 0) setPasswordError("")
        const passwordFormCopy = { ...passwordForm }
        passwordFormCopy[e.target.name] = e.target.value
        setPasswordForm(passwordFormCopy)
    }
    async function submitPasswordChange(e) {
        e.preventDefault()
        if (passwordForm.password !== passwordForm.passwordConfirm) { // Verification
            setPasswordError("Your Passwords Are NOT the Same!")
            return
        }
        let checkPassword1 = checkPasswordValid(passwordForm.password)
        if (!checkPassword1.valid) {
            setPasswordError(checkPassword1.message)
            return
        }
        setVerified(true)
    }

    // Handling Verification
    const [verifyForm, setVerifyForm] = useState({ email: "", password: "" });
    const [needVerify, setVerified] = useState(false)
    const [verifyError, setVerifyError] = useState("")
    function handleVerificationInput(e) {
        if (verifyError.length > 0) setVerifyError("")
        const verifyFormCopy = { ...verifyForm }
        verifyFormCopy[e.target.name] = e.target.value
        setVerifyForm(verifyFormCopy)
    }
    async function submitVerification(e) {
        e.preventDefault()
        try {
            await props.currentUser.reauthenticateWithCredential(
                myFirebase.firebase_.auth.EmailAuthProvider.credential(verifyForm.email, verifyForm.password)
            )
            if (!!emailForm.email) {
                await updateUserLoginEmail(myUserLogin, emailForm.email) // updates email backend
                await props.currentUser.updateEmail(emailForm.email) // updates currentUser (user token)
            } else if (!!passwordForm.password) {
                await props.currentUser.updatePassword(passwordForm.passwordConfirm)
            }
            resetForm()
            setVerified(true)
        } catch (err) {
            setVerifyError(handleLoginError(err))
        }
    }
    function resetForm() {
        setVerifyForm({ email: "", password: "" })
        setEmailForm({ email: "", emailConfirm: "" })
        setPasswordForm({ password: "", passwordConfirm: "" })
    }

    // Main Login Info
    const [newLoginKey, setNewLoginKey] = useState(null)

    if (newLoginKey === null) { // Default Shows Information
        return (
            <Col className="col-wrapper">
                <h5 className="t1-subheading">Login Details</h5>
                <div className="login-info-email">
                    <label className="email-label-t2-settings">Email: </label>
                    <input className="email-input-t2-settings" value={props.currentUser.email} disabled={true} />
                </div>
                <div className="reset-buttons">
                    <Button className="change-new-email-input-bttn" onClick={() => { setVerified(false); resetForm(); setNewLoginKey('email'); props.setChangingLoginInfo(true) }}>Change Email</Button>
                    <Button className="change-new-password-input-bttn" onClick={() => { setVerified(false); resetForm(); setNewLoginKey('password'); props.setChangingLoginInfo(true) }}>Change Password</Button>
                </div>

                <Button className="t2-settings-log-out" onClick={() => fullSignOut()}>Log Out</Button>
                <TermsOfUseBottomBar extraTop={true} />
            </Col>
        )
    }
    if (needVerify) { // If need to reverfiy credentials
        return (
            <Col className="col-wrapper">
                <button onClick={() => setNewLoginKey(null)} className="cancel-login-change"><ArrowLeft className="cancel-arrow-icon" />Cancel</button>
                <h4 className="change-header-settings-t2">Verify Your Credentials</h4>
                <Form onSubmit={submitVerification} className="form-change-settings-t2">
                    <div className="each-input-label-group">
                        <label className="label-new-change">Email</label>
                        <input
                            name="email"
                            type="text"
                            value={verifyForm.email}
                            onChange={handleVerificationInput}
                            placeholder="Enter Your Current Email"
                            autoComplete="off"
                        />
                    </div>
                    <div className="each-input-label-group">
                        <label className="label-new-change">Password</label>
                        <div className="input-password-wrapper">
                            <input
                                name="password"
                                type={showPassword1 ? "text" : "password"}
                                value={verifyForm.password}
                                onChange={handleVerificationInput}
                                placeholder="Enter Your Current Password"
                                autoComplete="off"
                            />
                            <button className="showPass" onClick={() => setPassword1(!showPassword1)}>
                                {!showPassword1 ? (
                                    <div className="eye"><EyeOff /></div>
                                ) : (<div className="eye"><Eye /></div>)}
                            </button>
                        </div>
                    </div>
                    <Button className="submit-new-login-bttn" type="submit">Verify</Button>
                    <div className="generic-error-message">{verifyError}</div>
                </Form>
            </Col>
        )
    }
    if (newLoginKey === "email") { // Form for changing the email
        return (
            <Col className="col-wrapper">
                <button onClick={() => { setNewLoginKey(null); props.setChangingLoginInfo(false) }} className="cancel-login-change"><ArrowLeft className="cancel-arrow-icon" />Cancel</button>
                <h4 className="change-header-settings-t2">Change Your Email</h4>
                <Form onSubmit={submitEmailChange} className="form-change-settings-t2">
                    <div className="each-input-label-group">
                        <label className="label-new-change">New Email</label>
                        <input
                            name="email"
                            type="text"
                            value={emailForm.email}
                            onChange={handleEmailInput}
                            placeholder="Enter Your New Email"
                            autoComplete="off"
                        />
                    </div>
                    <div className="each-input-label-group">
                        <label className="label-new-change">Confirm New Email</label>
                        <input
                            name="emailConfirm"
                            type="text"
                            value={emailForm.emailConfirm}
                            onChange={handleEmailInput}
                            placeholder="Reconfirm Your New Email"
                            autoComplete="off"
                        />
                    </div>
                    <Button className="submit-new-login-bttn" type="submit">Submit</Button>
                    <div className="generic-error-message">{emailError}</div>
                </Form>
            </Col>
        )
    }
    if (newLoginKey === "password") { // Form for changing the password
        return (
            <Col className="col-wrapper">
                <button onClick={() => { setNewLoginKey(null); props.setChangingLoginInfo(false) }} className="cancel-login-change"><ArrowLeft className="cancel-arrow-icon" />Cancel</button>
                <h4 className="change-header-settings-t2">Change Your Password</h4>
                <Form onSubmit={submitPasswordChange} className="form-change-settings-t2">
                    <div className="each-input-label-group">
                        <label className="label-new-change">New Password</label>
                        <div className="input-password-wrapper">
                            <input
                                name="password"
                                type={showPassword1 ? "text" : "password"}
                                value={passwordForm.password}
                                onChange={handlePasswordInput}
                                placeholder="Enter Your New Password"
                                autoComplete="off"
                            />
                            <button className="showPass" onClick={() => setPassword1(!showPassword1)}>
                                {!showPassword1 ? (
                                    <div className="eye"><EyeOff /></div>
                                ) : (<div className="eye"><Eye /></div>)}
                            </button>
                        </div>
                    </div>
                    <div className="each-input-label-group">
                        <label className="label-new-change">Confirm New Password</label>
                        <div className="input-password-wrapper">
                            <input
                                name="passwordConfirm"
                                type={showPassword2 ? "text" : "password"}
                                value={passwordForm.passwordConfirm}
                                onChange={handlePasswordInput}
                                placeholder="Reconfirm Your New Password"
                                autoComplete="off"
                            />
                            <button className="showPass" onClick={() => setPassword2(!showPassword2)}>
                                {!showPassword2 ? (
                                    <div className="eye"><EyeOff /></div>
                                ) : (<div className="eye"><Eye /></div>)}
                            </button>
                        </div>
                    </div>
                    <Button className="submit-new-login-bttn" type="submit">Submit</Button>
                    <div className="generic-error-message">{passwordError}</div>
                </Form>
            </Col>
        )
    }
    return (<div></div>)
}
export default ChangeLoginInfoSettings