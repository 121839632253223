//General Imports
import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "./Authentication";
import { Container } from 'react-bootstrap';

// Route Wrapper for Payment(All)
// - As long as you are logged in, you can buy stuff
const PrivateRoutePayment = ({ component: RouteComponent, ...rest }) => {
    const { currentUser, accountType } = useContext(AuthContext);

    //If login is good, allow access or redirect to login
    if (!!currentUser) {
        return (
            <Route
                {...rest}
                render={routeProps =>
                    (accountType === "tier1" || accountType === "tier2") ? (
                        <>
                            <Container fluid className="payment-main-container-wrapper">
                                <RouteComponent {...routeProps}
                                    currentUser={currentUser}
                                    accountType={accountType} />
                            </Container>
                        </>
                    ) : (<Redirect to={"/incorrect-login"} />)
                }
            />
        )
    } else {
        //Bad login
        return (
            <Route
                {...rest}
                render={() => (
                    <Redirect to={"/login"} />
                )}
            />
        )
    }
};

export default PrivateRoutePayment
