//General Imports
import React from "react";
import { Route } from "react-router-dom";
import { Container } from 'react-bootstrap';

// Route Wrapper for Main (landing, login, signup, forgotpassword, etc.)
const MainRoute = ({ component: RouteComponent, ...rest }) => {
    return (
        <Route
          {...rest}
          render={routeProps =>
              <Container fluid className="main-container-wrapper">
                <RouteComponent {...routeProps} />
              </Container>
          }
        />
      )
}

export default MainRoute
