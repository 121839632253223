// File Used for Dev Stuff and Functions
import emailjs from 'emailjs-com';
import { Users } from 'react-feather';
import myFirebase from "../firebase";
import { createNewChatRoom } from "./UploadFirebase";

//const sgMail = require('@sendgrid/mail')
import sgMail from "@sendgrid/mail"

export function devIndustries() {

}

export const devAcctID = "T3uklKMfvZWwWNvyEoBW81c0w5z1";

export function sendVerificationEmail(userdata, companydata) {
    const apiEmailUser = process.env.REACT_APP_EMAIL_API
    const template = process.env.REACT_APP_EMAIL_VERIFICATION_TEMPLATE

    let timeSent = new Date()
    const params = {
        "request_time": timeSent.toLocaleString() + " " + timeSent.toTimeString().slice(9),
        "user_name": userdata.name,
        "user_title": userdata.title,
        "user_email": userdata.email,
        "user_id": userdata.id,
        "company_name": companydata.name,
        "company_type": companydata.type,
        "company_description": companydata.description,
        "company_id": companydata.id,
    }
    return emailjs.send('gmail', template, params, apiEmailUser)
}

//Function to add chat with coopsight to every current user
export async function registeredUserCoopChat() {
    try {
        let allUsers = await myFirebase.firestore().collection('userProfiles').get().then((queryResult) => {
            queryResult.forEach(async (doc) => {
                const userData = doc.data();
                const uid = userData.userid;
                let memberList = [uid, devAcctID];
                let dataToAdd = {
                    chatCreated: new Date(),
                    members: memberList,
                    initiatedByUser: uid,
                };
                let welcomeMessage = "Welcome to Coopsight Chat! If you have any questions, issues, or feedback related to this platform, please let us know by messaging us here! You may also receive updates about this service or important Coopsight events via this chat!"
                let firstText = {
                    message: welcomeMessage,
                    sender: devAcctID,
                    timestampSent: new Date(),
                    type: "text",
                }
                let chatRoomRef = await myFirebase
                    .firestore()
                    .collection("chats")
                    .add(dataToAdd).then(docRef => {
                        docRef.collection('texts').add(firstText);
                    });
            })
        }
        );
    } catch (err) { console.log(err) }
}

//function to send a mass message through the coopsight account
export async function sendMassMessage(message) {
    try {
        const dataToAdd = {
            message: message,
            sender: devAcctID,
            timestampSent: new Date(),
            type: "text",
        }

        //iterate through docs
        const chatSet = await myFirebase.firestore().collection('texts').where("members", "array-contains", devAcctID).get().then(queryResult => {
            queryResult.forEach((doc) => {
                doc.ref.collection('texts').add(dataToAdd);
            })
        });
        console.log("Got it");

    } catch (err) { console.log(err) }
}


///////
///////
///////
///////
///////
///////
///////
///////
///////
///////
///////
///////
///////
///////
///////
///////
///////



/////// For email sending off
export async function sendEmailMatchBundle() {
    // 30 days:   2592000000
    // 2 weeks:   1209600000
    // 1 week :   604800000
    // 3 days :   259200000
    // 1 day  :   86400000
    var beginningDate = Date.now() - 604800000
    var beginningDateObject = new Date(beginningDate);
    let recentMatchCards = await myFirebase.firestore().collection('matchCardAnalytics').where('timestampMatched', '>', beginningDateObject).orderBy('timestampMatched').get()
    if (!recentMatchCards.empty) {
        const userMatchesMasterBundle = []
        const userMatchBundlesPromises = []
        recentMatchCards.forEach(async (eachCard) => {
            let cardData = eachCard.data()
            userMatchBundlesPromises.push(getEmailsFromCompany(cardData.toMatch.companyid).then(teamEmails => {
                userMatchesMasterBundle.push({
                    companyName: cardData.toMatch.companyName,
                    companyid: cardData.toMatch.companyid,
                    teamEmails: teamEmails,
                    matches: [cardData.fromMatch]
                })
            }))
        })
        let finalMasterBundle = []
        await Promise.all(userMatchBundlesPromises)

        // Makes sure duplicates are added onto self bundle of matches, rather than repeats
        userMatchesMasterBundle.forEach((eachMatchBulk) => {
            let foundMasterCardIndex = finalMasterBundle.findIndex(masterCard => masterCard.companyid === eachMatchBulk.companyid)
            if (foundMasterCardIndex !== -1) {
                finalMasterBundle[foundMasterCardIndex].matches.push(eachMatchBulk)
            } else {
                finalMasterBundle.push(eachMatchBulk)
            }
        })
        return finalMasterBundle
    }
};

async function getEmailsFromCompany(companyid) {
    const emailList = []
    const teamPromises = []
    try {
        // Finds my associated team (where my userid appears)
        const teamsSnapshot = await myFirebase.firestore().collection("teams").where("companyDocid", "==", companyid).get();
        if (!teamsSnapshot.empty) {
            let useridsToGet = teamsSnapshot.docs[0].data().members.concat(teamsSnapshot.docs[0].data().admins)
            if (useridsToGet.length === 0) { return [] }
            let emailPromise = myFirebase.firestore().collection('userLogins').where('userid', 'in', useridsToGet).get()
                .then(fullUserLoginData => {
                    if (!fullUserLoginData.empty) {
                        fullUserLoginData.forEach(eachUserLoginData => {
                            emailList.push(eachUserLoginData.data().email)
                        })
                    }
                })
            teamPromises.push(emailPromise)
            await Promise.all(teamPromises)
            return emailList
        }
    } catch (error) { console.log(error) }
}

export function godSpeed(masterEmailList) {
    //const key = "SG.WJOcyzQOSHisoxGgeu-jcg.4YFEwZAk_uf3FaSXbXvH8zr5be7X6n3U-vQFXTxGQ9"
    const key = ""
    sgMail.setApiKey(key)

    const emailGodList = []
    masterEmailList.forEach(eachEmailSendout => {
        const emailObj = {
            to: eachEmailSendout.teamEmails[0],
            html: ''
        }
        const matchPeopleList = eachEmailSendout.matches.map(o => o.companyName)
        let matchPeopleString;
        if (matchPeopleList.length <= 1) {
            matchPeopleString = matchPeopleList.join(', ')
        } else if (matchPeopleList.length === 2) {
            matchPeopleString = matchPeopleList.join(' and ')
        } else {
            matchPeopleList[matchPeopleList.length - 1] = `and ${matchPeopleList[matchPeopleList.length - 1]}`
            matchPeopleString = matchPeopleList.join(', ')
        }
        const emailHtml = `
            <div>
                <p>Hello <strong><span>${eachEmailSendout.companyName}</span></strong>,</p>
                <p>This has been an active week for you on Coopsight, You have received <strong><span>${eachEmailSendout.matches.length}</span></strong> partnership match -
                from the Open Platform Companies including:</p>
                <p><strong>${matchPeopleString}</strong></p>
                <p>Login now to view all partnership matches, and begin chat with the most compatible partner.</p>
                <p><a href="app.coopsight.com" target="_blank">app.coopsight.com</a></p>
                <p>In case you have any questions, or are having issues logging-in don’t hesitate to reach us at
                <a href="mailto: info@coopsight.com">info@coopsight.com</a></p>
                <p>- Coopsight Team</p>
                <p><i>If you are unfamilar with Coopsight, you can safely disregard this email.</i></p>
            </div>
        `
        emailObj.html = emailHtml
        emailGodList.push(emailObj)
    })

    emailGodList.forEach((eachFinal) => {
        console.log(`Sending to ${eachFinal.to}...`)
        const finalEmailSendOff = {
            to: 'erolmb@gmail.com', // Change to your recipient
            from: 'coopsight@gmail.com', // Change to your verified sender
            subject: 'Matches From Coopsight',
            text: 'Matches From Coopsight: ',
            html: eachFinal.html,
        }

        console.log(finalEmailSendOff)

        sgMail.setApiKey(key)
        sgMail
            .send(finalEmailSendOff)
            .then((res) => {
                console.log('Email sent!!!')
                console.log("Successfully sent: ", res)
            })
            .catch((error) => {
                console.error(error)
            })
    })
}

///////
///////
///////
///////

export async function sendEmailChatBundle() {
    // 30 days:   2592000000
    // 2 weeks:   1209600000
    // 1 week :   604800000
    // 3 days :   259200000
    // 1 day  :   86400000

    var beginningDate = Date.now() - 604800000
    var beginningDateObject = new Date(beginningDate);

    // Gets all recent texts in specified timestamp
    let recentChatMessages = await myFirebase.firestore().collectionGroup('texts').where('timestampSent', '>', beginningDateObject).get()
    if (!recentChatMessages.empty) {
        const masterChatMessageList = []
        recentChatMessages.docs.forEach((eachRecentText) => {
            const recentTextData = eachRecentText.data()
            masterChatMessageList.push({
                parentid: eachRecentText.ref.parent.parent.id,
                sender: recentTextData.sender,
                message: recentTextData.message,
                timestampSent: recentTextData.timestampSent,
            })
        })

        // Bundles all messages under the common parent doc
        const finalMasterBundle = []
        masterChatMessageList.forEach((eachMatchBulk) => {
            let foundMasterCardIndex = finalMasterBundle.findIndex(masterCard => masterCard.parentid === eachMatchBulk.parentid)
            if (foundMasterCardIndex !== -1) {
                finalMasterBundle[foundMasterCardIndex].childMessages.push({
                    sender: eachMatchBulk.sender,
                    message: eachMatchBulk.message,
                    timestampSent: eachMatchBulk.timestampSent
                })
            } else {
                finalMasterBundle.push({
                    parentid: eachMatchBulk.parentid,
                    childMessages: [{
                        sender: eachMatchBulk.sender,
                        message: eachMatchBulk.message,
                        timestampSent: eachMatchBulk.timestampSent
                    }],
                })
            }
        })

        // Gets the data of the parent doc
        const masterChatPromiseList = []
        finalMasterBundle.forEach((eachMatchBulk) => {
            let parentChatPromise = myFirebase.firestore().collection('chats').doc(eachMatchBulk.parentid).get()
                .then((parentDocRef) => {
                    eachMatchBulk.parentDoc = parentDocRef.data()
                })
            masterChatPromiseList.push(parentChatPromise)
        })
        await Promise.all(masterChatPromiseList)

        // Nice formats for the OTHER person who didnt send the txt messages
        const personChatEmailList = []
        finalMasterBundle.forEach(eachChatRoom => {
            const chatMembers = eachChatRoom.parentDoc.members
            eachChatRoom.childMessages.forEach(eachChildMsg => {
                const otherMember = chatMembers.find(o => o !== eachChildMsg.sender)
                if (!!otherMember) { // for weird error handling
                    let foundMasterCardIndex = personChatEmailList.findIndex(masterCard => masterCard.sendToUserid === otherMember)
                    if (foundMasterCardIndex !== -1) {
                        personChatEmailList[foundMasterCardIndex].recentChats.push(eachChildMsg)
                    } else {
                        personChatEmailList.push({
                            sendToUserid: otherMember,
                            sendToEmail: null,
                            recentChats: [eachChildMsg]
                        })
                    }

                }
            })
        })

        // Gets the email and name of the person sending email out to
        const emailPromisesList = []
        personChatEmailList.forEach(eachPersonChatEmail => {
            let emailPromise = getEmailsFromUserid(eachPersonChatEmail.sendToUserid)
                .then(newEmail => {
                    eachPersonChatEmail.sendToEmail = newEmail
                })
            let namePromise = getNameFromUserid(eachPersonChatEmail.sendToUserid)
                .then(userName => {
                    eachPersonChatEmail.sendToName = userName
                })
            emailPromisesList.push(emailPromise)
            emailPromisesList.push(namePromise)
        })
        await Promise.all(emailPromisesList)

        // Final Sendoff
        chatGodSpeed(personChatEmailList)
    }
}

async function getEmailsFromUserid(userid) { // from userid, get email
    try {
        const fullUserLoginData = await myFirebase.firestore().collection('userLogins').where('userid', '==', userid).get()
        if (!fullUserLoginData.empty) {
            return fullUserLoginData.docs[0].data().email
        }
    } catch (error) { console.log(error) }
}
async function getNameFromUserid(userid) { // from userid, get name
    try {
        const fullUserLoginData = await myFirebase.firestore().collection('userProfiles').where('userid', '==', userid).get()
        if (!fullUserLoginData.empty) {
            return fullUserLoginData.docs[0].data().name
        }
    } catch (error) { console.log(error) }
}

// Final Sendoff
function chatGodSpeed(chatBulkList) {
    console.log(chatBulkList)

   // const key = "SG.WJOcyzQOSHisoxGgeu-jcg.4YFEwZAk_uf3FaSXbXvH8zr5be7X6n3U-vQFXTxGQ9"
    const key = ""
    sgMail.setApiKey(key)

    const emailGodList = []
    chatBulkList.forEach(eachEmailSendout => {
        const emailObj = {
            to: eachEmailSendout.sendToEmail,
            html: ''
        }

        const emailHtml = `
                <p>Hello <strong><span>${eachEmailSendout.sendToName}</span></strong>,</p>
                <p>You have recently received <strong><span>${eachEmailSendout.recentChats.length}</span></strong> chat messages from your matches.</p>
                <p>Login now to view chat messages, and reply to your potential partners!</p>
                <p><a href="app.coopsight.com" target="_blank">app.coopsight.com</a></p>
                <p>In case you have any questions, or are having issues logging-in don’t hesitate to reach us at
                    <a href="mailto: info@coopsight.com">info@coopsight.com</a>
                </p>
                <p>- Coopsight Team</p>
                <p><i>If you are unfamilar with Coopsight, you can safely disregard this email.</i></p>`

        emailObj.html = emailHtml
        emailGodList.push(emailObj)
    })

    emailGodList.forEach((eachFinal) => {
        console.log(`Sending to ${eachFinal.to}...`)
        const finalEmailSendOff = {
            to: 'erol@coopsight.com', // Change to your recipient
            from: 'coopsight@gmail.com', // Change to your verified sender
            subject: 'Chats From Coopsight',
            text: 'Chats From Coopsight: ',
            html: eachFinal.html,
        }

        console.log(finalEmailSendOff)

        sgMail.setApiKey(key)
        sgMail
            .send(finalEmailSendOff)
            .then((res) => {
                console.log('Email sent!!!')
                console.log("Successfully sent: ", res)
            })
            .catch((error) => {
                console.error(error)
            })
    })
}