// General Use
import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import ChatMessages from "./Components/ChatMessages"
import ChatRoom from "./Components/ChatRoom"
import { FilterChatPopup } from "./Components/Popups"
import { ArrowLeft, AlignRight } from 'react-feather'
import { connect } from 'react-redux';
import { changeMyChats } from '../../Redux/actions';
import { sortChatMessage, capitalizeName } from "../../GeneralUse/FunctionBucket"

/*
    Pro (T1) Chat Page
    * Chat Page main wrapper 
    * relies on shared components with other login types
*/

function mapStateToProps(state) { return { reduxState: state } }
function mapDispatchToProps(dispatch) { // To CHANGE redux values
    return {
        reduxDispatch: {
            changeMyChats: value => dispatch(changeMyChats(value)),
        }
    }
}

class Tier1ChatPage extends React.Component {
    constructor(props) {
        super();
        this.state = {
            myChats: props.reduxState.myChats,
            localChatRooms: [...props.reduxState.myChats.chatRooms],
            currentChatRoom: null,
            otherMembers: [],

            filterChatPopup: false,
            currentFilterChatMessages: "Recent"
        }
    }

    componentDidMount() {
        const { myChats, currentFilterChatMessages } = this.state

        let localChatRooms = sortChatMessage(myChats.chatRooms, currentFilterChatMessages, this.props.currentUser.uid)
        this.setState({ localChatRooms })
    }

    selectChatRoom = (selectedChatRoom, isNew = false) => { // Which chat room in the sidebar was selected (who current chatting with?)
        if (isNew) {
            const myChats = this.state.myChats
            myChats.chatRooms.forEach((eachChatRoom, index) => {
                if (eachChatRoom.selfid === selectedChatRoom.selfid) {
                    myChats.chatRooms[index] = selectedChatRoom
                }
            })
            let sortedChatRoomsLocal = sortChatMessage(myChats.chatRooms, this.state.currentFilterChatMessages, this.props.currentUser.uid)
            this.setState({ myChats: myChats, localChatRooms: sortedChatRoomsLocal, currentChatRoom: null })
            this.selectChatRoom(selectedChatRoom)
        } else {
            const otherMembers = selectedChatRoom.members.filter(eachMember => eachMember.userid !== this.props.currentUser.uid)
            selectedChatRoom.unreadMessagesCount = 0
            this.setState({ currentChatRoom: selectedChatRoom, otherMembers })
        }
    }

    updateParentChatRoom = (chatRoom) => { // Updates the sidebar with recent information where im chatting with them
        let sortedChatRoomsLocal = sortChatMessage(this.state.localChatRooms, this.state.currentFilterChatMessages, this.props.currentUser.uid)
        this.setState({ localChatRooms: sortedChatRoomsLocal })
        this.setState({ currentChatRoom: chatRoom })
    }


    render() {
        const { myChats, currentChatRoom, otherMembers, filterChatPopup, localChatRooms } = this.state
        console.log(currentChatRoom)
        if (!!currentChatRoom) { // If I am currently chatting with someone
            let waitingOnCompany = currentChatRoom.waitingOnCompany

            let displayChatRoomName = null
            let underDisplayName = null

            if (otherMembers.length === 0) {
                displayChatRoomName = capitalizeName(currentChatRoom.waitingOnCompany.companyName)
                underDisplayName = ""
            } else {
                displayChatRoomName = otherMembers[0].name
                underDisplayName = capitalizeName(otherMembers[0].companyData.companyName)
            }
            return (
                <Row className="t1-page-wrapper t1-chat-page">
                    <Col className="t1-inner-page">

                        {/* Top Header */}
                        <div className="t1cc-chat-room-top">
                            <Button
                                className="leave-chat-room-t1"
                                onClick={() => this.setState({ currentChatRoom: null })}>
                                <ArrowLeft />
                            </Button>

                            <div className="top-chat-room-info">
                                <h4 className="display-chat-room-name">{displayChatRoomName}</h4>
                                <h4 className="top-chat-room-company-info">
                                    {underDisplayName}
                                </h4>
                            </div>

                        </div>

                        <Row className="t1cc-chat-room-shared">
                            <ChatRoom
                                {...this.props}
                                currentChatRoom={{ ...currentChatRoom }}
                                waitingOnCompany={waitingOnCompany}
                                updateParentChatRoom={this.updateParentChatRoom}
                                selectChatRoom={this.selectChatRoom}
                                updateLocalChatRooms={(newChatRooms) => {
                                    let sortedChatRoomsLocal = sortChatMessage(newChatRooms, "Recent", this.props.currentUser.uid)
                                    this.setState({ localChatRooms: sortedChatRoomsLocal })
                                }}
                            />
                        </Row>
                    </Col>
                </Row>
            )
        }

        return ( // Main Wrapper
            <Row className="t1-page-wrapper t1-chat-page">
                <Col className="t1-inner-page">
                    <Row className="t1cc-top-title">
                        <Col>
                            <h1 className="t1-header">Chat</h1>
                        </Col>
                    </Row>

                    {/* Request Wrapper (where someone just matched with me and i didnt respond yet) */}
                    <Row className="t1cc-request-wrapper">
                        <Col>
                            <Row>
                                <p className="t1f-chat-header-request">All Requests</p>
                                <div className="t1f-chat-header-r-n">{myChats.requests.length}</div>
                            </Row>
                            <Row>
                                {myChats.requests.length === 0 ? (
                                    <div className="no-pending-request">You Have No Pending Requests</div>
                                ) : (
                                        <ul className="t1f-request-table">
                                            {myChats.requests.map((eachRequest, requestIndex) => {
                                                return (
                                                    <li key={requestIndex} className="each-chat-request-wrapper">
                                                        <button className="each-chat-request-bttn" onClick={() => this.selectChatRoom(eachRequest.chatRoom)}>
                                                            <img
                                                                src={eachRequest.otheruser.profilePicture.fileURL}
                                                                alt=""
                                                                className="tm-profile-picture-small"
                                                            />
                                                            <p>{eachRequest.otheruser.name}</p>
                                                        </button>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    )}
                            </Row>
                        </Col>
                    </Row>

                    {/* Chat Wrapper */}
                    <Row className="t1cc-convo-wrapper">
                        <Col className="t1cc-inner-convo">
                            <Row>
                                <p className="t1f-chat-header-convo">Conversations</p>
                                <div className="t1f-chat-header-c-n">{!!localChatRooms ? localChatRooms.length : 0}</div>
                                <AlignRight className="filter-messages-chat-icon" onClick={() => this.setState({ filterChatPopup: true })} />
                                {filterChatPopup && (
                                    <FilterChatPopup
                                        currentFilterChatMessages={this.state.currentFilterChatMessages}
                                        closePopupWithChatFilter={(newChatFilter) => {
                                            const { myChats } = this.state
                                            let sortedChatRoomsLocal = sortChatMessage(myChats.chatRooms, newChatFilter, this.props.currentUser.uid)
                                            this.setState({
                                                localChatRooms: sortedChatRoomsLocal,
                                                currentFilterChatMessages: newChatFilter,
                                                filterChatPopup: false
                                            })
                                        }}
                                    />
                                )}
                            </Row>
                            <Row className="chat-in-betwen-msg">
                                {localChatRooms.length === 0 ? (
                                    <div className="no-chats-available">No Chats Available</div>
                                ) : (
                                        <ChatMessages
                                            userid={this.props.currentUser.uid}
                                            chatRooms={[...this.state.localChatRooms]}
                                            selectChatRoom={this.selectChatRoom}
                                        />
                                    )}
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row >
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Tier1ChatPage)
