// General Imports
import { cleanScore } from "../GeneralUse/FunctionBucket.js";
import myFirebase from "../firebase";
import { firebaseAnalytics } from "../firebase";

export async function saveMatchData(myCompany, toCompany, displayedMatch, userid, status) {
    try {
        const fromCompanyData = myCompany
        const matchSuccessData = {
            timestampMatched: new Date(),
            fromMatch: {
                companyName: fromCompanyData.companyName,
                companyid: fromCompanyData.selfid,
                industries: fromCompanyData.industryKeywords.industry,
                userid: userid
            },
            toMatch: {
                companyName: toCompany.companyName,
                companyid: toCompany.selfid,
                industries: toCompany.industries,
            },
            matchCard: {
                score: cleanScore(displayedMatch.score),
                toLabel: displayedMatch.to_label,
                matchType: displayedMatch.match_type,
                matchCacheRef: displayedMatch.selfref,
                industryKeywords: Object.keys(displayedMatch.synergies).length > 0
                    ? displayedMatch.synergies[Object.keys(displayedMatch.synergies)[0]]
                    : [],
                synergyKeywords: displayedMatch[toCompany.selfid],
            },
            status: status,
        }

        // Add to Firestore Database
        await myFirebase.firestore().collection("matchCardAnalytics").add(matchSuccessData)

        // Analytics
        if (status === "liked") {
            firebaseAnalytics.logEvent("successfulMatches", matchSuccessData);
        } else if (status === "passed") {
            firebaseAnalytics.logEvent("passedMatches", matchSuccessData);
        }
        firebaseAnalytics.logEvent("allMatchCardsHistory", matchSuccessData);

    } catch (error) { console.log(error) }
}