// General Use
import React from "react";
import { Button } from "react-bootstrap";
import LocationMap from "../../Tier1/Components/LocationMap";

// Uploading Location
const CompanySetupLocation = (props) => {
    return (
        <section className="guide-company-process-location-wrapper">
            <p className="guide-profile-process-header">
                Provide a company location:<span className="asterisk-required"> *</span>
            </p>

            <LocationMap
                currentLocation={props.form.location}
                handleLocationChange={(locationInput) => props.handleLocationChange(locationInput)}
            />
            <div className="error-message-main">{props.errorMessage}</div>
            <div className="profile-buttons">
                <Button className="buttons-guide-back" onClick={() => props.goBack()}>
                    Back
                </Button>
                <Button
                    disabled={props.form.location.length === 0}
                    className="buttons-guide-continue"
                    onClick={() => props.goNext("location")}
                >
                    Continue
                </Button>
            </div>
        </section>
    );
};
export default CompanySetupLocation