//General Imports
import React, { useState } from "react";
import { convertTimestamp, getChatTimestamp } from "../../../GeneralUse/FunctionBucket";
import { addFilesToStorage } from "../../../GeneralUse/UploadFirebase";
import { checkFileValid, checkFileChatValid, fileConstraints, checkWebsiteValid, } from "../../../GeneralUse/CheckValidFunctions";
import { firebaseAnalytics } from "../../../firebase";
import { Row, Col, Button, Form } from "react-bootstrap";
import SelectChatFromMatch from "./SelectChatFromMatch";
import { Plus, MessageCircle, File, Send, Trash, } from "react-feather";
import partnerstackchatappimg from "../../../DesignAssets/partnerstack-logo-vector-condensed.svg";
import pay2uchatappimg from "../../../DesignAssets/pay2u-logo.svg";

//Redux Stuff
import { connect } from "react-redux";
import { changeMyChats } from "../../../Redux/actions"; // all functions to change

function mapStateToProps(state) {
  return { reduxState: state };
} // To READ reduxState values

function mapDispatchToProps(dispatch) {
  // To CHANGE redux values
  return {
    reduxDispatch: {
      changeMyChats: (value) => dispatch(changeMyChats(value)),
    },
  };
}

class ChatRoom extends React.Component {
  constructor(props) {
    super();
    this.state = {
      currentChatRoom: props.currentChatRoom,
      otherMembers: props.currentChatRoom.members,
      messagesAgg: props.currentChatRoom.messages.aggregate,

      newChatMessage: "",
      files: [],
      pitchdeck: {},
      lastTimestamp: null,
      renderAdditionalInput: false,
      renderTypeAdditionalInput: "general",
      marketplaceApps: [
        {
          id: "parternstack-app",
          name: "Partnerstack",
          img: partnerstackchatappimg,
          analyticid: "partnerstackReferralRedirect",
          url: "https://partners.grsm.io/coopsight",
          expanded: false,
        },
        {
          id: "pay2u-app",
          name: "Pay2U",
          img: pay2uchatappimg,
          analyticid: "pay2uReferralRedirect",
          url: "https://pay2u.ru/",
          expanded: false,
        },
      ],

      companyAttached: props.currentChatRoom.waitingOnCompany,

      presendType: null,
    };
  }

  componentDidMount() {
    const otherMembers = this.props.currentChatRoom.members.filter(
      (eachMember) => eachMember.userid !== this.props.currentUser.uid
    );
    this.setState({ otherMembers });
    this.setState({
      currentChatRoom: { ...this.props.currentChatRoom },
      messagesAgg: this.props.currentChatRoom.messages.aggregate,
    });
    this.setState({
      companyAttached: this.props.currentChatRoom.waitingOnCompany,
    });

    this.chatListenerTexts(this.props.currentChatRoom);
    this.chatListenerFiles(this.props.currentChatRoom);
    this.chatListenerPitchdecks(this.props.currentChatRoom);

    this.scrollToBottom();
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentChatRoom.selfid !== prevProps.currentChatRoom.selfid) {
      this.unsubscribeTexts();
      this.unsubscribeFiles();
      this.unsubscribePitchdecks();

      const otherMembers = this.props.currentChatRoom.members.filter(
        (eachMember) => eachMember.userid !== this.props.currentUser.uid
      );
      this.setState({ otherMembers });
      this.setState({
        currentChatRoom: { ...this.props.currentChatRoom },
        messagesAgg: this.props.currentChatRoom.messages.aggregate,
      });
      this.setState({
        companyAttached: this.props.currentChatRoom.waitingOnCompany,
      });

      this.chatListenerTexts(this.props.currentChatRoom);
      this.chatListenerFiles(this.props.currentChatRoom);
      this.chatListenerPitchdecks(this.props.currentChatRoom);

      this.scrollToBottom();
    }
  }

  componentWillUnmount() {
    this.unsubscribeTexts();
    this.unsubscribeFiles();
    this.unsubscribePitchdecks();
    // update the lastViewed timestamp of a chatroom
    this.updateLeaveChatroomTimestamp();
  }

  updateLeaveChatroomTimestamp = async () => {
    const currentChatRoomID = this.state.currentChatRoom.selfid;
    const notificationObj = this.props.reduxState.feed.notifications
    if (Object.keys(notificationObj).length !== 0) {
      let flag = false;
      let arrayToUpdate = notificationObj.lastViewedChat;
      // update the timestamp if the chatroomID has been logged before
      arrayToUpdate.forEach((eachChat) => {
        if (eachChat.chatRoomid === currentChatRoomID) {
          eachChat.lastViewed = new Date();
          flag = true;
        }
      });
      // if the chatroom timestamp has never been logged before
      // create a new object in the array
      if (flag !== true) {
        arrayToUpdate.push({
          chatRoomid: currentChatRoomID,
          lastViewed: new Date(),
        });
      }
      await notificationObj.selfref.update({ lastViewedChat: arrayToUpdate });
    }


    // Updates all the msgs with the old notif then
    this.state.messagesAgg.map((eachText) => {
      if (!!eachText.newMsg) {
        eachText.newMsg = false
      }
    })
  };

  chatListenerTexts = (currentChatRoom) => {
    this.unsubscribeTexts = currentChatRoom.selfref.collection("texts").where("timestampSent", ">", new Date()).orderBy("timestampSent", "asc").onSnapshot((querySnapshot) => {
      querySnapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          var messageData = change.doc.data();
          messageData.selfref = change.doc.ref;
          currentChatRoom.messages.aggregate.push(messageData);
          this.setState({ currentChatRoom: { ...currentChatRoom } });
          this.props.updateParentChatRoom(currentChatRoom);
          this.scrollToBottom();
        }
      });
    });
  };

  chatListenerFiles = (currentChatRoom) => {
    this.unsubscribeFiles = currentChatRoom.selfref.collection("files").where("timestampSent", ">", new Date()).orderBy("timestampSent", "asc").onSnapshot((querySnapshot) => {
      querySnapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          var messageData = change.doc.data();
          messageData.selfref = change.doc.ref;
          currentChatRoom.messages.aggregate.push(messageData);
          this.setState({
            currentChatRoom: { ...currentChatRoom },
            messagesAgg: currentChatRoom.messages.aggregate,
          });
          this.props.updateParentChatRoom(currentChatRoom);
          this.scrollToBottom();
        }
      });
    });
  };

  chatListenerPitchdecks = (currentChatRoom) => {
    this.unsubscribePitchdecks = currentChatRoom.selfref.collection("pitchdecks").where("timestampSent", ">", new Date()).orderBy("timestampSent", "asc").onSnapshot((querySnapshot) => {
      querySnapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          var messageData = change.doc.data();
          messageData.selfref = change.doc.ref;
          currentChatRoom.messages.aggregate.push(messageData);
          this.setState({
            currentChatRoom: { ...currentChatRoom },
            messagesAgg: currentChatRoom.messages.aggregate,
          });
          this.props.updateParentChatRoom(currentChatRoom);
          this.scrollToBottom();
        }
      });
    });
  };

  // Functions for Handling Sending
  sendMessage = async (e) => {
    !!e && e.preventDefault();
    // If is request, just removed from myChats.requests
    if (
      this.props.reduxState.myChats.requests.some(
        (rqst) => rqst.chatRoom.selfid === this.state.currentChatRoom.selfid
      )
    ) {
      this.props.reduxState.myChats.requests = this.props.reduxState.myChats.requests.filter(
        (eachrequest) => {
          return (
            eachrequest.chatRoom.selfid !== this.state.currentChatRoom.selfid
          );
        }
      );
      this.props.reduxState.myChats.chatRooms.push(this.state.currentChatRoom);
      this.props.updateLocalChatRooms(this.props.reduxState.myChats.chatRooms);
    }
    const messageInfo = {
      message: this.state.newChatMessage,
      sender: this.props.currentUser.uid,
      timestampSent: getChatTimestamp(),
      type: "text",
    };
    const currentChatRoom = this.state.currentChatRoom;
    this.setState({ newChatMessage: "" });

    await currentChatRoom.selfref.collection("texts").add(messageInfo);
    this.updateLeaveChatroomTimestamp()
    !!e && this.scrollToBottom();
  };
  sendFiles = async (filesToSend) => {
    const currentChatRoom = this.state.currentChatRoom;
    const localFiles = await addFilesToStorage(null, filesToSend, {
      path: `ChatRooms/${currentChatRoom.selfid}/${this.props.currentUser.uid}`,
    });

    const fileInfo = {
      files: localFiles,
      sender: this.props.currentUser.uid,
      timestampSent: getChatTimestamp(),
      type: "file",
    };

    await currentChatRoom.selfref.collection("files").add(fileInfo);
    this.setState({ presendType: null });
    this.scrollToBottom();
  };

  sendPitchdeckRequest = async () => {
    const pitchdeckRequest = {
      pitchdeck: null,
      accepted: null,
      sender: this.props.currentUser.uid,
      timestampSent: getChatTimestamp(),
      type: "pitchdeck",
    };
    const currentChatRoom = this.state.currentChatRoom;
    this.setState({ presendType: null });

    // Removes old pitchdeck requests you have already sent in this chat (becasue you are sending a new one)
    let currentPitchdeckRequests = currentChatRoom.messages.aggregate.filter(
      (obj) => {
        // filter to find current pending requests
        return (
          obj.type === "pitchdeck" &&
          obj.sender === this.props.currentUser.uid &&
          obj.accepted === null
        );
      }
    );
    let deletingOldRequestsPromise = [];
    currentPitchdeckRequests.forEach((eachOldRequest) => {
      deletingOldRequestsPromise.push(eachOldRequest.selfref.delete());
    }); // deletes old
    await Promise.all(deletingOldRequestsPromise); // For legacy, may be > 1 pitchdeck requests sent so handles
    currentChatRoom.messages.aggregate = currentChatRoom.messages.aggregate.filter(
      (obj) => {
        return currentPitchdeckRequests.includes(obj) === false;
      }
    ); // removes locally

    await currentChatRoom.selfref
      .collection("pitchdecks")
      .add(pitchdeckRequest); // adds new pitchdeck request

    this.setState({ currentChatRoom }); // update with removed pitchdeck requests
    this.scrollToBottom();
  };
  sendPitchdeckResponse = async (pitchdeckRequestInfo, pitchdeckFile) => {
    const currentChatRoom = this.state.currentChatRoom;
    const pitchdeckParsed = await addFilesToStorage(null, [pitchdeckFile], {
      path: `ChatRooms/${currentChatRoom.selfid}/${this.props.currentUser.uid}`,
    });

    await pitchdeckRequestInfo.selfref.update({
      pitchdeck: pitchdeckParsed[0],
      accepted: true,
      timestampSent: getChatTimestamp(),
    });

    currentChatRoom.messages.aggregate = currentChatRoom.messages.aggregate.filter(
      (msg) => msg !== pitchdeckRequestInfo
    );
    this.setState({ currentChatRoom });
    this.scrollToBottom();
  };
  rejectPitchdeck = async (pitchdeckRequestInfo) => {
    const currentChatRoom = this.state.currentChatRoom;
    currentChatRoom.messages.aggregate = currentChatRoom.messages.aggregate.filter(
      (msg) => msg !== pitchdeckRequestInfo
    );

    await pitchdeckRequestInfo.selfref.update({
      timestampSent: getChatTimestamp(),
      accepted: false,
    });

    this.setState({ currentChatRoom: { ...currentChatRoom } });
    this.scrollToBottom();
  };
  handleInputChange = (e) => {
    e.preventDefault();
    this.setState({ newChatMessage: e.target.value });
  };

  renderDividerNewMessage = (messagesAgg, message, messageIndex) => {
    var previousMessageFlag = null;
    if (messageIndex > 0) {
      previousMessageFlag = messagesAgg[messageIndex - 1].newMsg;
    }
    if (previousMessageFlag === false && message.newMsg === true) {
      return <hr />
      // return <div class="new-message-divider">---------New Messages---------</div>
    }
  }

  renderAppropiateMessage = (message, displayedTimestamp) => {

    function renderText(txt) {
      let parts = txt.split(' ')
      const newParts = []
      for (let i = 0; i < parts.length; i += 1) {
        let currentWord = parts[i]
        if (checkWebsiteValid(currentWord).valid) {
          newParts[i] = <a key={'link' + i} href={currentWord} target="_blank" rel="noreferrer">{currentWord}</a>
        } else {
          newParts[i] = ` ${currentWord} `
        }
      }

      let newMessage = []
      for (let i = 0; i < newParts.length; i += 1) {
        if (typeof newParts[i - 1] === 'object' && typeof newParts[i] === 'object') {
          newMessage.push(" ")
        }
        newMessage.push(newParts[i])
      }
      return newMessage
    }

    switch (message.type) {
      case "text":
        return (
          <div className="inner-msg-wrapper">
            <p className="displayed-message-tmstamp">
              {displayedTimestamp.time}
            </p>
            {checkWebsiteValid(message.message).valid ? (
              <p className="regular-text-msg">
                {renderText(message.message)}
              </p>
            ) : (
              <p className="regular-text-msg">{message.message}</p>
            )}

            {/* <div className={message.newMsg ? "text-msg-view-status text-msg-new-status" : "text-msg-view-status text-msg-old-status"}>
              {message.newMsg ? "New" : "Old"}
            </div> */}

          </div>
        );
      case "file":
        return (
          <div className="inner-msg-files-sent">
            <p className="displayed-message-tmstamp-special">
              {displayedTimestamp.time}
            </p>
            <FilesMessage files={message.files} />
          </div>
        );
      case "pitchdeck":
        if (message.accepted === true) {
          return (
            <div className="inner-msg-pitchdeck-accepted">
              <p className="displayed-message-tmstamp-special">
                {displayedTimestamp.time}
              </p>
              <PitchdeckAccepted pitchdeck={message.pitchdeck} />
            </div>
          );
        }
        if (message.accepted === false) {
          return (
            <div className="inner-msg-pitchdeck-rejected">
              <p className="displayed-message-tmstamp-special">
                {displayedTimestamp.time}
              </p>
              <PitchdeckRejected />
            </div>
          );
        }
        if (message.sender === this.props.currentUser.uid) {
          return (
            <div className="inner-msg-pitchdeck-outstanding-sender">
              <p className="displayed-message-tmstamp-special">
                {displayedTimestamp.time}
              </p>
              <PitchdeckOutstandingRequestS
                message={message}
                myUserProfile={this.props.reduxState.myUserProfile}
              />
            </div>
          );
        } else {
          return (
            <div className="inner-msg-pitchdeck-outstanding-reciever">
              <p className="displayed-message-tmstamp-special">
                {displayedTimestamp.time}
              </p>
              <PitchdeckOutstandingRequestR
                otherMember={this.state.otherMembers[0]}
                rejectPitchdeck={this.rejectPitchdeck}
                sendPitchdeckResponse={this.sendPitchdeckResponse}
                pitchdeckRequestInfo={message}
                sender={
                  this.state.otherMembers.filter(
                    (member) => member.userid === message.sender
                  )[0]
                }
              />
            </div>
          );
        }
      default:
        return <></>;
    }
  };

  renderPresend = () => {
    const { presendType } = this.state;
    switch (presendType) {
      case "pitchdeck":
        return (
          <PitchdeckPresend
            other={this.state.otherMembers[0].name}
            cancelPresend={() => this.setState({ presendType: null })}
            sendPitchdeckRequest={this.sendPitchdeckRequest}
          />
        );
      case "files":
        return (
          <FilesPresend
            sendFiles={this.sendFiles}
            cancelPresend={() => this.setState({ presendType: null })}
          />
        );
      default:
        return <></>;
    }
  };

  updateLocal = (newChatRoom) => {
    console.log("updating local ", newChatRoom);
    //this.props.updateParentChatRoom(newChatRoom)
    //this.setState({ companyAttached: false })
    this.props.selectChatRoom(newChatRoom, true);
    //this.setState({ currentChatRoom: newChatRoom, otherMembers: newChatRoom.members, messagesAgg: [], companyAttached: false, })
  };

  scrollToBottom = () => {
    this.el.scrollIntoView({ behavior: "auto" });
  };

  render() {
    const {
      presendType,
      messagesAgg,
      marketplaceApps,
      renderAdditionalInput,
      renderTypeAdditionalInput,
      companyAttached,
    } = this.state;

    if (!!companyAttached) {
      if (companyAttached.team.admins.length !== 0) {
        // if there are people attached to company
        return (
          <Col className="shared-chat-room-wrapper">
            <SelectChatFromMatch
              company={companyAttached}
              userid={this.props.currentUser.uid}
              chatRoom={this.props.currentChatRoom}
              updateLocal={this.updateLocal}
            />
            <div
              ref={(el) => {
                this.el = el;
              }}
            />
          </Col>
        );
      }
    }

    // Detect if waiting on company
    //      if empty, show please not, still has pending users
    //      else show selectchatfrommatch
    //          on click, change currentChatRoom

    return (
      <Col className="shared-chat-room-wrapper">
        <Row
          className="shared-chat-messages-main-body"
          id={
            this.state.renderAdditionalInput ? "extended-chat-additional" : ""
          }
        >
          {" "}
          {/* Main Body */}
          {messagesAgg.length === 0 && presendType === null ? (
            <div className="empty-chat-room-say-hello-wrapper">
              {!!companyAttached ? (
                <h4 className="say-hello-chat">
                  Please Note That This Company Still Has Pending Users
                </h4>
              ) : (
                <h4 className="say-hello-chat">Say Hello!</h4>
              )}

              <div
                ref={(el) => {
                  this.el = el;
                }}
              />
            </div>
          ) : (
            <ul className="main-message-unorderdlist">
              {messagesAgg.map((message, messageIndex) => {
                let displayedTimestamp = convertTimestamp(
                  message.timestampSent
                );
                let displayNewDay = false;
                const lastMessage = messagesAgg[messageIndex - 1];
                if (!!lastMessage) {
                  let lastDisplayedTimestamp = convertTimestamp(
                    lastMessage.timestampSent
                  );
                  if (lastDisplayedTimestamp.day !== displayedTimestamp.day) {
                    displayNewDay = true;
                  }
                } else {
                  displayNewDay = true;
                }
                const iAmSender = message.sender === this.props.currentUser.uid;
                return (
                  <li key={messageIndex}>
                    {displayNewDay ? (
                      <h6
                        key={displayedTimestamp.day}
                        className="day-timestamp-chat"
                      >
                        {displayedTimestamp.day}
                      </h6>
                    ) : (
                      <></>
                    )}
                    {this.renderDividerNewMessage(
                      messagesAgg,
                      message,
                      messageIndex
                    )}
                    <div
                      className={
                        iAmSender
                          ? "each-message my-messages"
                          : "each-message other-messages"
                      }
                    >
                      {this.renderAppropiateMessage(
                        message,
                        displayedTimestamp
                      )}
                    </div>
                  </li>
                );
              })}
              {this.renderPresend()}
              <div
                ref={(el) => {
                  this.el = el;
                }}
              />
            </ul>
          )}
        </Row>

        <div className="shared-chat-room-bottom-input">
          <div className="first-send-input">
            {" "}
            {/* Bottom Input */}
            <Button
              className="extra-chat-stuff-input"
              onClick={() => {
                this.setState({
                  renderAdditionalInput: !this.state.renderAdditionalInput,
                });
              }}
            >
              <Plus />
            </Button>
            <Form
              onSubmit={(e) => this.sendMessage(e)}
              className="main-send-chat-input"
            >
              <input
                value={this.state.newChatMessage}
                autoComplete="off"
                placeholder="Send Message"
                className="text-main-input"
                onChange={this.handleInputChange}
              />
              <Button
                className="submit-new-txt-message-bttn"
                disabled={this.state.newChatMessage.length === 0}
                type="submit"
              >
                <MessageCircle />
              </Button>
            </Form>
          </div>

          {renderAdditionalInput && (
            <div className="extra-bottom-send-input">
              {" "}
              {/* Extra Bottom */}
              <div className="below-chat-extra-wrapper">
                <ul className="tabs-on-chat-extra-list">
                  <li>
                    <Button
                      className="tab-bttn-wrapper"
                      onClick={() => {
                        this.setState({ renderTypeAdditionalInput: "general" });
                      }}
                    >
                      <h5 className="tab-header">General</h5>
                      <div
                        className={
                          renderTypeAdditionalInput === "general" &&
                          "selected-tab"
                        }
                      />
                    </Button>
                  </li>
                  <li>
                    <Button
                      className="tab-bttn-wrapper"
                      onClick={() => {
                        this.setState({
                          renderTypeAdditionalInput: "marketplace",
                        });
                      }}
                    >
                      <h5 className="tab-header">Marketplace</h5>
                      <div
                        className={
                          renderTypeAdditionalInput === "marketplace" &&
                          "selected-tab"
                        }
                      />
                    </Button>
                  </li>
                </ul>

                {renderTypeAdditionalInput === "general" ? (
                  <ul className="extra-items-full-list">
                    <li className="extra-bottom-li-wrapper">
                      <Button
                        className="extra-bottom-icon-wrapper"
                        onClick={() => {
                          if (presendType === null) {
                            this.setState({ presendType: "files" });
                          } else {
                            this.setState({ presendType: null });
                          }
                          this.scrollToBottom();
                        }}
                      >
                        <File className="extra-chat-icon" />
                      </Button>
                      <div className="extra-chat-label">Files</div>
                    </li>
                  </ul>
                ) : (
                  <ul className="extra-items-full-list-marketplace">
                    {marketplaceApps.map((eachApp, index) => {
                      if (eachApp.expanded) {
                        return (
                          <li
                            key={index}
                            className="each-marketplace-expanded-app-wrapper"
                            id={eachApp.id}
                          >
                            <a
                              href={eachApp.url}
                              target="_blank"
                              rel="noreferrer"
                              className="website-wrapper"
                            >
                              <Button
                                className="each-marketplace-app-inner"
                                onClick={() => {
                                  const marketplaceApps = this.state
                                    .marketplaceApps;
                                  marketplaceApps[
                                    index
                                  ].expanded = !marketplaceApps[index].expanded;
                                  this.setState({ marketplaceApps });
                                }}
                              >
                                <img
                                  src={eachApp.img}
                                  alt={eachApp.name}
                                  onClick={() => firebaseAnalytics.logEvent(eachApp.analyticid, {
                                    timestamp: new Date(),
                                  })}
                                  className="chat-marketplace-app-img"
                                />
                              </Button>
                            </a>
                            <p className="go-to-website-marketplace">
                              Go to Website
                            </p>
                            <Button
                              className="send-marketplace-to-chat-bttn"
                              onClick={() => {
                                this.setState(
                                  { newChatMessage: eachApp.url },
                                  () => this.sendMessage(null)
                                );
                              }}
                            >
                              <Send />
                            </Button>
                            <p className="send-partner-marketplace">
                              Send to Partner
                            </p>
                          </li>
                        );
                      } else {
                        return (
                          <li
                            key={index}
                            className="each-marketplace-app-wrapper"
                            id={eachApp.id}
                          >
                            <Button
                              className="each-marketplace-app-inner"
                              onClick={() => {
                                const marketplaceApps = this.state
                                  .marketplaceApps;
                                marketplaceApps[
                                  index
                                ].expanded = !marketplaceApps[index].expanded;
                                this.setState({ marketplaceApps });
                              }}
                            >
                              <img
                                src={eachApp.img}
                                alt={eachApp.name}
                                className="chat-marketplace-app-img"
                              />
                            </Button>
                          </li>
                        );
                      }
                    })}
                  </ul>
                )}
              </div>
            </div>
          )}
        </div>
      </Col>
    );
  }
}

const FilesPresend = (props) => {
  const [loading, triggerLoading] = useState(false);
  const [currentFiles, changeFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  function addFileChat(e) {
    if (errorMessage.length > 0) {
      setErrorMessage("");
    }
    const newFile = e.target.files[0];
    let inputCheck = checkFileChatValid(newFile, currentFiles);
    document.getElementById("file").value = "";
    if (!inputCheck.valid) {
      setErrorMessage(inputCheck.message);
    } else {
      let currentFilesCopy = [...currentFiles];
      currentFilesCopy.push({ name: newFile.name, fileBlob: newFile });
      changeFiles(currentFilesCopy);
    }
  }
  function removeFileFromForm(index) {
    let currentFilesCopy = [...currentFiles];
    setErrorMessage("");
    currentFilesCopy.splice(index, 1);
    changeFiles(currentFilesCopy);
  }
  return (
    <li className="files-presend-wrapper">
      <h5 className="presend-header">Share Files</h5>
      <h6>* Max of {fileConstraints.maxFilesPerChatSend} at a time</h6>

      <div className="files-presend-share-files-input-wrapper">
        <input
          id="file"
          type="file"
          disabled={loading}
          accept={fileConstraints.fileTypes}
          onChange={addFileChat}
          size=""
          className="files-presend-share-files-input"
        />
        <label for="file">Select Files</label>
      </div>

      <p className="presend-error-message">{errorMessage}</p>
      {currentFiles.length === 0 ? (
        <h4 className="main-file-chat-no-files">No Files Selected</h4>
      ) : (
        <ul className="main-file-chat-sends">
          {currentFiles.map((eachFileChat, index) => {
            return (
              <li key={index}>
                <File className="presend-file-icon" />
                <h5 className="file-name">{eachFileChat.name}</h5>
                <Button
                  className="presend-trash-file-bttn-wrapper"
                  disabled={loading}
                  onClick={() => removeFileFromForm(index)}
                >
                  <Trash className="presend-trash-icon" />
                </Button>
              </li>
            );
          })}
        </ul>
      )}

      {loading ? (
        <p className="simple-loading-txt-p">Sending...</p>
      ) : (
        <div className="presend-files-button-wrapper">
          <Button
            className="cancel-presend"
            onClick={() => props.cancelPresend()}
          >
            Cancel
          </Button>
          <Button
            className="confirm-presend"
            disabled={currentFiles.length === 0}
            onClick={() => {
              triggerLoading(true);
              setErrorMessage("");
              props.sendFiles(currentFiles);
            }}
          >
            Share
          </Button>
        </div>
      )}
    </li>
  );
};

const FilesMessage = (props) => {
  return (
    <li className="files-postset-wrapper">
      <ul>
        {props.files.map((eachFileChat, index) => {
          return (
            <div key={index}>
              <File className="postset-file-icon" />
              <p className="postset-file-name">
                <a href={eachFileChat.fileURL} target="_blank" rel="noreferrer">
                  {eachFileChat.name}
                </a>
              </p>
            </div>
          );
        })}
      </ul>
    </li>
  );
};

const PitchdeckPresend = (props) => {
  return (
    <li className="pitchdeck-presend-wrapper">
      <h4>Pitchdeck</h4>
      <p>Request Pitchdeck from {props.other}?</p>
      <div className="presend-pitchdeck-button-wrapper">
        <Button
          className="cancel-presend"
          onClick={() => props.cancelPresend()}
        >
          Cancel
        </Button>
        <Button
          className="confirm-presend"
          onClick={() => props.sendPitchdeckRequest()}
        >
          Request
        </Button>
      </div>
    </li>
  );
};
const PitchdeckOutstandingRequestS = (props) => {
  // If you have SENT a request, and still waiting for response
  return (
    <li className="pitchdeckS-postset-wrapper">
      <div className="pitchdeckS-postset-top">
        <img
          src={props.myUserProfile.profilePicture.fileURL}
          className="pitchdeckS-postset-profile-pic"
          alt=""
        />
        <h5 className="pitchdeckS-person-requesting">
          {props.myUserProfile.name}
        </h5>
      </div>
      <File className="pitchdeckS-postset-file-icon" size={32} />
      <h6 className="pitchdeckS-msg">You Have Requested a Pitchdeck</h6>
    </li>
  );
};
const PitchdeckOutstandingRequestR = (props) => {
  // If you have RECIEVED a request, and still waiting for response
  const [loading, triggerLoading] = useState(false);
  const [currentPitchdeck, changePitchdeck] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  function handleChange(e) {
    if (errorMessage.length > 0) {
      setErrorMessage("");
    }
    const newFile = e.target.files[0];

    let inputCheck = checkFileValid(newFile, []);

    document.getElementById("file").value = "";
    if (!inputCheck.valid) {
      setErrorMessage(inputCheck.message);
    } else {
      changePitchdeck({ name: newFile.name, fileBlob: newFile });
    }
  }
  function sendClicked() {
    triggerLoading(true);
    props.sendPitchdeckResponse(props.pitchdeckRequestInfo, currentPitchdeck);
  }

  // props.sender
  return (
    <li className="pitchdeckR-postset-wrapper">
      <div className="pitchdeckR-postset-top">
        <img
          src={props.otherMember.profilePicture.fileURL}
          className="pitchdeckR-postset-profile-pic"
          alt=""
        />
        <h5 className="pitchdeckR-person-requesting">
          {props.otherMember.name}
        </h5>
      </div>
      <File className="pitchdeckR-postset-file-icon" size={32} />

      <h5 className="pitchdeckR-requested-header">has requested a pitchdeck</h5>

      <div className="pitchdeckR-input-wrapper">
        <input
          id="file"
          type="file"
          disabled={loading}
          accept={fileConstraints.fileTypes}
          onChange={handleChange}
          className="pitchdeckR-input"
        />
        <label for="file">Select Pitchdeck</label>
      </div>

      <p className="error-message-standard">{errorMessage}</p>

      {!!currentPitchdeck && (
        <h4 className="selected-pitchdeck-name">{currentPitchdeck.name}</h4>
      )}

      {loading ? (
        <p className="simple-loading-txt-p">Sending...</p>
      ) : (
        <div className="postset-pitchdeck-button-wrapper">
          <button
            className="cancel-presend"
            onClick={() => props.rejectPitchdeck(props.pitchdeckRequestInfo)}
          >
            Reject
          </button>
          <button
            className="confirm-presend"
            disabled={!currentPitchdeck || loading}
            onClick={() => sendClicked()}
          >
            Send
          </button>
        </div>
      )}
    </li>
  );
};
const PitchdeckAccepted = (props) => {
  // If the user who was recieving end ACCEPTED
  return (
    <li className="pitchdeck-accepted-postset-wrapper">
      <h5 className="pitchdeck-accepted-header">Pitchdeck Details</h5>
      <File className="pitchdeck-file-icon" size={32} />
      <p className="accepted-pitchdeck-details">
        This pitchdeck request was{" "}
        <span className="posh-accepted">ACCEPTED</span>
      </p>
      <p className="accepted-pitchdeck-name">
        <a href={props.pitchdeck.fileURL} target="_blank" rel="noreferrer">
          {props.pitchdeck.name}
        </a>
      </p>
    </li>
  );
};
const PitchdeckRejected = (props) => {
  // If the user who was recieving end REJECTED
  return (
    <li className="pitchdeck-rejected-postset-wrapper">
      <h5 className="pitchdeck-rejected-header">Pitchdeck Details</h5>
      <File className="pitchdeck-file-icon" size={32} />
      <p className="rejected-pitchdeck-details">
        This pitchdeck request was{" "}
        <span className="posh-rejection">REJECTED</span>
      </p>
    </li>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoom);
