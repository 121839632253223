
//General Imports
import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import myFirebase from "../../firebase";
import { Dropdown } from 'react-bootstrap'
import { resetStates } from '../../Redux/actions';
import CoopsightLogo from '../../DesignAssets/coopsight-logo-nav.png';

import { useSelector, useDispatch } from 'react-redux';

// Icons
import {
    Database,
    Search,
    MessageCircle,
    Settings,
    HelpCircle,
    LogOut,
    CreditCard,
} from 'react-feather';

import chatImg from '../../DesignAssets/Subtract-New.png';
import matchImg from '../../DesignAssets/Medium-New.png';
import profileImg from '../../DesignAssets/User-New.png';

// Main Navigation Navigation
const MainNavigation = (props) => {
    return (
        <nav>
            <ul>
                <li><h1>Main Navigation</h1></li>
                <li><NavLink to={"/"}>Landing</NavLink></li>
                <li><NavLink to={"/sign-up"}>Sign Up</NavLink></li>
                <li><NavLink to={"/login"}>Login</NavLink></li>
                <li><NavLink to={"/incorrect-login"}>Incorrect Login Type</NavLink></li>
                <li><NavLink to={"/tier1/matching"}>T1 matching</NavLink></li>
                <li>
                    <button onClick={() => {
                        myFirebase.auth().signOut()
                        console.log("Signing out firebase!")
                    }}>Sign Out</button>
                </li>
            </ul>
        </nav>
    )
}

// Tier1 Navigation
const Tier1Navigation = (props) => {
    return (
        <nav className="t1-nav-main-wrapper">
            <ul>
                <li>
                    <NavLink to={"/tier1/chat"}>
                        <img src={chatImg} alt="" className="t1-nav-bottom-path-img" />
                    </NavLink>
                </li>
                <li>
                    <NavLink to={"/tier1/matching"}>
                        <img src={matchImg} alt="" className="t1-nav-bottom-path-img" />
                    </NavLink>
                </li>
                <li>
                    <NavLink to={"/tier1/profile"}>
                        <img src={profileImg} alt="" className="t1-nav-bottom-path-img" />
                    </NavLink>
                </li>
            </ul>
        </nav>
    )
}

// Tier2 Navigation
const Tier2Navigation = (props) => {
    const store = useSelector(store => store)
    const { myUserLogin, myBalance } = useSelector(state => ({
        myUserLogin: state.myUserLogin,
        myBalance: state.myUserLogin.matches.currentBalance
    }))
    const dispatch = useDispatch()
    async function fullSignOut() {
        
        await myFirebase.auth().signOut()
        props.history.push('/login')
        dispatch(resetStates())
    }

    const [topCurrentBalance, setTopCurrentBalance] = useState(myUserLogin.matches.currentBalance);

    useEffect(() => {
        setTopCurrentBalance(myUserLogin.matches.currentBalance)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [myBalance]);

    const CustomToggleProfile = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            ref={ref}
            className="dropdown-toggle-t2"
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >

            {children}
            &#x25bc;
        </a>
    ));
    /*
    const CustomToggleNotifications = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            ref={ref}
            className="dropdown-toggle-t2"
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
        </a>
    ));
    */
    return (
        <nav className="t2-nav-main-wrapper">
            <ul className="t2-nav-main-left">
                <li>
                    <NavLink to={"/tier2/dashboard"}>
                        <img
                            src={CoopsightLogo}
                            className="nav-top-t2-logo"
                            alt="Coopsight Logo"
                        />
                    </NavLink>
                </li>
            </ul>
            <ul className="t2-nav-main-center">
                <li>
                    <NavLink to="/tier2/database" activeClassName="active-nav">
                        <Database className="nav-icon" size={42} />
                        <h6 className="nav-label">DATABASE</h6>
                        <div className="active-underline"></div>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/tier2/matching" activeClassName="active-nav">
                        <Search className="nav-icon" size={42} />
                        <h6 className="nav-label">MATCHING</h6>
                        <div className="active-underline"></div>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/tier2/chat" activeClassName="active-nav">
                        <MessageCircle className="nav-icon" size={42} />
                        <h6 className="nav-label">CHAT</h6>
                        <div className="active-underline"></div>
                    </NavLink>
                </li>
            </ul>
            <ul className="t2-nav-main-right">
                <li className="current-balance-nav" onClick={() => props.history.push('/tier2/balance')}>{topCurrentBalance}</li>
                {/*
                <li>
                    <Dropdown className="notif-dropdown-nav-t2-wrapper">
                        <Dropdown.Toggle as={CustomToggleNotifications}>
                            <Bell className="nav-icon notifications-bell" size={56} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="notif-dropdown-menu-nav">
                            {store.feed.notifications.length === 0 ? (
                                <Dropdown.Item>No New Notifications! Check Back Later!</Dropdown.Item>) :
                                (<>{store.feed.notifications.map((eachNotification, index) => {
                                    return (
                                        <Dropdown.Item key={index}>{eachNotification.name}</Dropdown.Item>
                                    )
                                })}</>)}
                        </Dropdown.Menu>
                    </Dropdown>
                </li>
                 */}

                <li> {/* Profile Dropdown  NOT NAV BECAUSE OF IMAGE DISPLAY*/}
                    <Dropdown className="full-dropdown-nav-t2-wrapper">
                        <Dropdown.Toggle as={CustomToggleProfile} variant="success" aria-expanded="false">
                            <img
                                src={store.myUserProfile.profilePicture.fileURL}
                                alt=""
                                className="nav-profile-picture"
                            /> {/* This is for displaying the image of the profile picture */}
                        </Dropdown.Toggle>
                        <Dropdown.Menu menuAlign="right" className="t2-dropdown-menu-nav">
                            <Dropdown.Item className="nav-see-profile"><NavLink to={"/tier2/profile"}><img
                                src={store.myUserProfile.profilePicture.fileURL}
                                alt=""
                                className="nav-profile-picture-small"
                            /><p>See Your Profile</p></NavLink></Dropdown.Item>
                            <Dropdown.Item className="nav-drop-generic"><NavLink to={"/tier2/settings"}><Settings className="dropdown-icon" size={20} /><p>Settings</p></NavLink></Dropdown.Item>
                            <Dropdown.Item className="nav-drop-generic"><NavLink to={"/tier2/balance"}><CreditCard className="dropdown-icon" size={20} /><p>Balance</p></NavLink></Dropdown.Item>
                            <Dropdown.Item className="nav-drop-generic"><NavLink to={"/tier2/feedback"}><HelpCircle className="dropdown-icon" size={20} /><p>Help & Feedback</p></NavLink></Dropdown.Item>
                            <Dropdown.Item className="nav-drop-generic" onClick={() => fullSignOut()}><LogOut className="dropdown-icon" size={20} /><p>Log Out</p></Dropdown.Item>
                            <Dropdown.Header className="nav-drop-footer">
                                <ul>
                                    <li>
                                        <a href="https://coopsight.com/terms-and-conditions" target="_blank" rel="noopener noreferrer" samesite="Strict">Terms</a>
                                    </li>
                                    <li>
                                        <a href="https://coopsight.com/privacy-policy" target="_blank" rel="noopener noreferrer" samesite="Strict">Privacy Policy</a>
                                    </li>
                                </ul>

                            </Dropdown.Header>
                        </Dropdown.Menu>
                    </Dropdown>
                </li>
            </ul>
        </nav >
    )
}

// Tier3 Navigation
const Tier3Navigation = (props) => {
    return (
        <nav>
            <ul>
                <li><h1>Tier 3 Navigation</h1></li>
                <li><NavLink to={"/tier3/dashboard"}>Dashboard</NavLink></li>
                <li><NavLink to={"/tier3/database"}>Database</NavLink></li>
                <li><NavLink to={"/tier3/matching"}>Matching</NavLink></li>
                <li><NavLink to={"/tier3/chat"}>Chat</NavLink></li>
                <li>
                    <button onClick={async () => {
                        console.log("Signing out...")
                        try { await myFirebase.auth().signOut(); console.log("Successfully Signed Out!") }
                        catch (error) { console.log("Error Signing Out:", error) }
                    }}>Sign Out</button>
                </li>
            </ul>
        </nav>
    )
}

export {
    MainNavigation,
    Tier1Navigation,
    Tier2Navigation,
    Tier3Navigation,
}
