// General Use
import React from "react";
import { Button } from "react-bootstrap";
import GuideToolTips from "../../Tier1/Components/GuideToolTips.js";

// Uploading Type
const CompanySetupType = (props) => {
    return (
        <div className="guide-company-process-2-wrapper">
            <p className="guide-profile-process-header">
                Select a track:<span className="asterisk-required"> *</span>
                <GuideToolTips
                    description="Select ‘Startup’ if you are a growing company that is looking to match with other peers. Please select the options of Investor or Corporation only if that matches your operation."
                    placement="right"
                />
            </p>
            <div className="button-box">
                <Button
                    className={
                        props.form.type === "startup"
                            ? "company-type-selection-option selected"
                            : "company-type-selection-option"
                    }
                    onClick={() => props.handleInputChange("startup", "type")}
                >
                    Startup
                </Button>
                <Button
                    className={
                        props.form.type === "corporation"
                            ? "company-type-selection-option selected"
                            : "company-type-selection-option"
                    }
                    disabled={true}
                    onClick={() => props.handleInputChange("corporation", "type")}
                >
                    Corporation
                </Button>
                <Button
                    className={
                        props.form.type === "investor"
                            ? "company-type-selection-option selected"
                            : "company-type-selection-option"
                    }
                    disabled={true}
                    onClick={() => props.handleInputChange("investor", "type")}
                >
                    Investor
                </Button>
            </div>
            <div className="profile-buttons">
                <Button className="buttons-guide-back" onClick={() => props.goBack()}>
                    Back
                </Button>
                <Button
                    className="buttons-guide-continue"
                    disabled={!props.form.type}
                    onClick={() => props.goNext("type")}
                >
                    Continue
                </Button>
            </div>
        </div>
    );
};
export default CompanySetupType