/* eslint-disable no-useless-escape */
// General Use Functions

const passwordConstraints = {
    minCharacters: 4,
    uppercaseNeeded: true,
    lowercaseNeeded: true,
    numberNeeded: true,
    specialNeeded: false,       // ! - + _ ? , .
}

export const inputConstraints = {
    minimumSizeBig: 40,
    maxCharacterSizeSmall: 64,
    maxCharacterSizeBig: 10000,
    maxInterests: 5,
}
export const fileConstraints = {
    acceptableFileTypes: [
        ".pdf",
        ".pptx",
        //".docx", // r
        //".jpeg",
        //".jpg",
        //".png",
    ].join(),
    fileRequired: false,
    maxFileSize: 20000000, // 20 MB
    maxFilesPerCompany: 5,

    maxChatFileSize: 8000000, // 8 MB
    maxFilesPerChatSend: 3,

    // Constants

    // pitchdeck size is 20 MB
    // every paying t1 user gets 20 files max (if bought any match package ever)
    // each child node of t2 gets 20 files max

    // chat t1 gets 10 MB per file
    // chat t2 gets 20 MB per file

    // t2 max num of child nodes in child type: 
    // if bought matches at 100, max 15 children total
    // if bought matches of 200, max 40 children total
    // if bought matches of 500, max 100 children
}

// For any generic valid
export function checkValidSmall(value, required = true) {
    if ((required && value.length === 0) || (required && !value.replace(/\s/g, '').length)) {
        return { valid: false, message: "Input must NOT be empty!" }
    } else if (value.length > inputConstraints.maxCharacterSizeSmall) {
        return { valid: false, message: `Input has a max size of ${inputConstraints.maxCharacterSizeSmall} characters!` }
    } else if (value[0] === " ") {
        return { valid: false, message: "The first character must NOT be a space!" }
    }
    return { valid: true, message: "Input is valid!" }
}

export function checkValidBig(value) {
    if (value.length === 0) {
        return { valid: false, message: "Input must NOT be empty!" }
    } else if (value.length < inputConstraints.minimumSizeBig) {
        return { valid: false, message: `Input must be at least ${inputConstraints.minimumSizeBig - value.length} more characters!` }
    } else if (value.length >= inputConstraints.maxCharacterSizeBig) {
        return { valid: false, message: `Input has a max size of ${inputConstraints.maxCharacterSizeBig} characters!` }
    }
    return { valid: true, message: "Input is valid!" }
}

export function checkWebsiteValid(value) {
    try {
        if (value.length === 0) {
            return { valid: true, message: "That is an empty input! " }
        }
        var pattern = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
        if (pattern.test(value)) {
            return { valid: true, message: "That is a valid website!" }
        } else {
            return { valid: false, message: "That is NOT a valid website!" }
        }
    } catch (error) { return { valid: false, message: "Error" } }

}

// FOR GENERAL CONSTRAINTS
export function checkFileValid(file, otherFiles) { // otherFiles is a list of the other files
    if (file === null || file === undefined || file === "") {
        return { valid: false, message: "Your file selection is empty!" };
    }
    if (file.size > fileConstraints.maxFileSize) {
        return { valid: false, message: "This file is over our limit of " + fileConstraints.maxFileSize / 1000000 + "MB" };
    }
    const fileType = '.' + file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length) || file.name;
    if (fileConstraints.acceptableFileTypes.split(',').indexOf(fileType.toLowerCase()) === -1) {
        return { valid: false, message: "This is an unaccepted file type!" };
    }
    const currentNumberOfFiles = otherFiles.length + 1;
    if (fileConstraints.maxFilesPerCompany < currentNumberOfFiles) {
        return { valid: false, message: `You can only have a max of ${fileConstraints.maxFilesPerCompany} files per company!` };
    }
    return { valid: true, message: "" };
}

// FOR CHAT CONSTRAINTS
export function checkFileChatValid(file, otherFiles) { // otherFiles is a list of the other files
    if (file === null || file === undefined || file === "") {
        return { valid: false, message: "Your file selection is empty!" };
    }
    if (file.size > fileConstraints.maxChatFileSize) {
        return { valid: false, message: "This file is over our limit of " + fileConstraints.maxChatFileSize / 1000000 + "MB" };
    }
    const fileType = '.' + file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length) || file.name;
    if (fileConstraints.acceptableFileTypes.split(',').indexOf(fileType.toLowerCase()) === -1) {
        return { valid: false, message: "This is an unaccepted file type!" };
    }
    const currentNumberOfFiles = otherFiles.length + 1;
    if (fileConstraints.maxFilesPerChatSend < currentNumberOfFiles) {
        return { valid: false, message: `You can only have a max of ${fileConstraints.maxFilesPerChatSend} files per company!` };
    }
    return { valid: true, message: "" };
}

// Check Interests max
export function checkInterestsValid(interestList) {
    if (interestList.length > inputConstraints.maxInterests) {
        return { valid: false, message: `You can only have a max of ${inputConstraints.maxInterests} interests!` }
    }
    return { valid: true, message: "" };
}

export function checkPasswordValid(password) {
    if (password.length < passwordConstraints.minCharacters) {
        return { valid: false, message: `Your password must have at least ${passwordConstraints.minCharacters} characters!` }
    }
    if (passwordConstraints.uppercaseNeeded) {
        if (/[A-Z]/.test(password) === false) {
            return { valid: false, message: `Your password must include the following: 1 uppercase, 1 lowercase, and 1 number!` }
        }
    }
    if (passwordConstraints.lowercaseNeeded) {
        if (/[a-z]/.test(password) === false) {
            return { valid: false, message: `Your password must include the following: 1 uppercase, 1 lowercase, and 1 number!` }
        }
    }
    if (passwordConstraints.numberNeeded) {
        if (/\d/.test(password) === false) {
            return { valid: false, message: `Your password must include the following: 1 uppercase, 1 lowercase, and 1 number!` }
        }
    }
    if (passwordConstraints.specialNeeded) {
        if (!/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(password) === false) {
            return { valid: false, message: `Your password must have at least 1 special character!` }
        }
    }

    return { valid: true, message: "" }
}