//General Imports
import React from "react";
import myFirebase from "../../firebase";
import { Redirect } from "react-router-dom";
import { Row, Col, Form, Button } from 'react-bootstrap';

class ForgetPasswordPage extends React.Component {
    constructor() {
        super();
        this.state = {
            resetEmailSent: false,
            submitErrorMessage: "",
            loading: false,
        };
    }

    handleForgetPassword = (event) => {
        event.preventDefault();
        const { email } = event.target.elements;
        this.setState({ loading: true })
        myFirebase.auth().sendPasswordResetEmail(email.value)
            .then(() => {
                if (this.state.submitError) {
                    this.setState({
                        submitErrorMessage: "",
                    })
                }
                this.setState({
                    resetEmailSent: true,
                    loading: true,
                })
            })
            .catch((error) => {
                const message = error.message;
                this.setState({
                    submitErrorMessage: message,
                    loading: false,
                });
                console.log(error);
            });
    };

    render() {
        if (this.state.redirectLogin) {
            return <Redirect to="/login" />
        }
        return (
            <Row className="main-page-wrapper main-forgot-page">
                <Col className="main-inner-page">
                    <Row className="forget-password-head">
                        <p>Forgot Password</p>
                    </Row>
                    <Row className="forgot-page-body">
                        <Col>
                            <Form className="forget-form-wrapper" onSubmit={this.handleForgetPassword}>
                                {this.state.resetEmailSent ? (
                                    <div className="email-successfully-sent">Email has been sent! Please check your email!</div>
                                ) : (
                                        <div className="label-input-wrapper">
                                            <p>Please input your email to receive a recover email</p>
                                            <input
                                                name="email"
                                                type="text"
                                                placeholder="  Email"
                                                onChange={() => { if (this.state.submitErrorMessage.length > 0) this.setState({ submitErrorMessage: "" }) }}
                                                autocomplete="off"
                                            />
                                            <div className="error-message-main">{this.state.submitErrorMessage}</div>
                                            <div className="forgot-button-container">
                                                <Button
                                                    disabled={this.state.loading}
                                                    type="submit"
                                                >{this.state.loading ? 'Loading' : 'Reset Password'}</Button>
                                            </div>
                                        </div>
                                    )}
                            </Form>
                        </Col>
                    </Row>
                </Col>
                <button
                    className="forgot-page-head-left"
                    onClick={() => this.props.history.goBack()}
                >Go Back</button>
            </Row>
        );
    }
}

export default ForgetPasswordPage;
