import React from "react";
import { Info } from 'react-feather'
import { Tooltip } from '@material-ui/core';

const GuideToolTips = (props) => {
    return (
        <Tooltip style={{ marginLeft: '20px' }} title={props.description} placement={props.placement} >
            <Info size={20} color="#713ffe" />
        </Tooltip >
    );
};

export default GuideToolTips;