// General Use
import React, { useState } from "react";
import { Row, Col } from 'react-bootstrap';
import {getCompanyFromChat} from "../../../GeneralUse/FunctionBucket"
import { LoadingSmallIcon } from "../../../Loading/LoadingPageIcon"

const SelectChatFromMatch = (props) => {

    const [isLoading, setLoading] = useState(false)

    async function selectedChatPerson(otherUser) {
        setLoading(true)
        let newChatRoomData = {
            chatCreated: new Date(),
            initiatedByUser: props.userid,
            members: [otherUser.userid, props.userid],
            scoreSnapshot: props.chatRoom.scoreSnapshot,
        }
        await props.chatRoom.selfref.set(newChatRoomData)
        let parsedChatRoomData = {...newChatRoomData}
        otherUser.companyData = await getCompanyFromChat(otherUser.companyDocid)
        parsedChatRoomData.members = [otherUser, ...props.chatRoom.members]
        parsedChatRoomData.selfid = props.chatRoom.selfid
        parsedChatRoomData.selfref = props.chatRoom.selfref
        parsedChatRoomData.messages = {}
        parsedChatRoomData.messages.aggregate = []
        props.updateLocal(parsedChatRoomData)
    }
    return (
        <Col className="select-chat-from-match-wrapper t1-inner-page">
            <Row className="match-select-chat-people-main">
                {isLoading ? (
                    <Col className="loading-small-match-select-people"><LoadingSmallIcon /></Col>
                ) : (
                        <Col>
                            <h6 className="select-who-to-chat-with-from-match-header">Select Who To Chat With</h6>
                            <ul>
                                {props.company.team.admins.map((eachMember, index) => {
                                    return (
                                        <li key={index} disabled={true} onClick={() => {
                                            selectedChatPerson(eachMember)
                                        }}>
                                            <img className="mini-popup-profile-picture"
                                                src={eachMember.profilePicture.fileURL}
                                                alt="" />
                                            <div className="mini-popup-profile-name">{eachMember.name}</div>
                                        </li>
                                    )
                                })}
                            </ul>
                        </Col>
                    )}
            </Row>
        </Col>
    )
}
export default SelectChatFromMatch