//General Imports
import React from "react";
import myFirebase from "../../firebase";
import { Redirect } from "react-router-dom";
import TermsOfUseBottomBar from "../Tier1/Components/TermsOfUseBottomBar"
import { checkPasswordValid } from "../../GeneralUse/CheckValidFunctions"
import ReCAPTCHA from "react-google-recaptcha";
import { Row, Col, Button, Form } from 'react-bootstrap';
import { Eye, EyeOff } from 'react-feather'

//Redux Stuff
import { connect } from 'react-redux';
import {
    changeMyUserLogin,
    changeMyUserProfile,
    changeMyTeam,
    changeMyCompany,
    changeMyChats,
    changeMyEcosystem,
    resetStates
} from '../../Redux/actions';

function mapStateToProps(state) { return { reduxState: state } }

function mapDispatchToProps(dispatch) {
    return {
        reduxDispatch: {
            changeMyUserLogin: value => dispatch(changeMyUserLogin(value)),
            changeMyUserProfile: value => dispatch(changeMyUserProfile(value)),
            changeMyTeam: value => dispatch(changeMyTeam(value)),
            changeMyCompany: value => dispatch(changeMyCompany(value)),
            changeMyChats: value => dispatch(changeMyChats(value)),
            changeMyEcosystem: value => dispatch(changeMyEcosystem(value)),
            resetStates: value => dispatch(resetStates(value)),
        }
    }
}

/*
    Sign Up Page
    - The sign up page that ONLY does firebase basic sign up
    - Custom Claims handled later
*/
class SignUpPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            passwordHidden: true,
            passwordHidden2: true,
            captchaVerified: false,
            submitErrorMessage: "",
        }
    }

    async componentDidMount() {
        await myFirebase.auth().signOut();
        this.props.reduxDispatch.resetStates()
    }

    //Handles and confirms signup
    handleSignUp = async (event) => {
        event.preventDefault();
        const { email, password, passwordConfirm } = event.target.elements;
        const { captchaVerified } = this.state

        if (email.value.length === 0) {
            this.setState({ submitErrorMessage: "You must input an email address!" })
            return
        }

        if (password.value !== passwordConfirm.value) {
            this.setState({ submitErrorMessage: "Passwords are the NOT the same!" })
            return
        }

        let checkPassword1 = checkPasswordValid(password.value)
        if (!checkPassword1.valid) {
            this.setState({ submitErrorMessage: checkPassword1.message })
            return
        }
        let checkPassword2 = checkPasswordValid(passwordConfirm.value)
        if (!checkPassword2.valid) {
            this.setState({ submitErrorMessage: checkPassword2.message })
            return
        }

        if (captchaVerified === false) {
            this.setState({ submitErrorMessage: "You need to complete the captcha!" })
            return
        }

        this.setState({ loading: true })

        try {
            console.log("Signing up user...")
            await myFirebase.auth().createUserWithEmailAndPassword(email.value, password.value)
            console.log("...Successfully signed up user")

            this.props.history.push("/guide/profile-set-up");

        } catch (error) { this.setState({ submitErrorMessage: error.message, loading: false }) }
    }

    //Resets errors messages if any values get changed
    handleAnyChange = (e) => {
        if (this.state.submitErrorMessage.length > 0) {
            this.setState({ submitErrorMessage: "" });
        }
    }

    // Shows First Password
    showPassword = (e) => {
        e.preventDefault();
        if (this.state.passwordHidden) {
            this.setState({ passwordHidden: false });
        } else {
            this.setState({ passwordHidden: true });
        }
    }

    // Shows Second Confirm password
    showPassword2 = (e) => {
        e.preventDefault();
        if (this.state.passwordHidden2) {
            this.setState({ passwordHidden2: false });
        } else {
            this.setState({ passwordHidden2: true });
        }
    }

    // Handles the change in captcha (if completed)
    captchaChange = (response) => {
        if (!!response) {
            this.setState({ captchaVerified: true })
        }
        this.setState({ submitErrorMessage: "" })
    }

    render() {
        const { passwordHidden, passwordHidden2 } = this.state
        if (this.state.redirectLogin) { // If already signed in, just redirect to login
            return <Redirect to="login" />
        }
        return (
            <Row className="main-page-wrapper main-sign-up-page">
                <Col className="main-inner-page">
                    <Row className="sign-up-page-head">
                        <p>Sign Up</p>
                    </Row>
                    <Row className="sign-up-page-body">
                        <Col>
                            <Form
                                noValidate
                                className="sign-up-form-wrapper"
                                onSubmit={this.handleSignUp}
                            >
                                <div className="label-input-wrapper">
                                    <input
                                        className="baseline-input"
                                        name="email"
                                        type="email"
                                        placeholder="Email"
                                        autoComplete="off"
                                        onChange={this.handleAnyChange}
                                    />
                                </div>

                                <div className="label-input-wrapper">
                                    <div className="passwordWrap">
                                        <input
                                            className="baseline-input"
                                            name="password"
                                            type={this.state.passwordHidden ? "password" : "text"}
                                            placeholder="Password"
                                            autoComplete="off"
                                            onChange={this.handleAnyChange}
                                        />
                                        <button className="showPass" onClick={this.showPassword}>
                                            <div className="eye">
                                                {passwordHidden ? <EyeOff /> : <Eye />}
                                            </div>
                                        </button>
                                    </div>
                                </div>
                                <div className="label-input-wrapper">
                                    <div className="passwordWrap">
                                        <input
                                            className="baseline-input"
                                            name="passwordConfirm"
                                            type={this.state.passwordHidden2 ? "password" : "text"}
                                            placeholder="Confirm Password"
                                            autoComplete="off"
                                            onChange={this.handleAnyChange}
                                        />
                                        <button className="showPass" onClick={this.showPassword2}>
                                            <div className="eye">
                                                {passwordHidden2 ? <EyeOff /> : <Eye />}
                                            </div>
                                        </button>
                                    </div>
                                </div>

                                <ReCAPTCHA
                                    className="captcha-sign-up"
                                    sitekey={process.env.REACT_APP_CAPTCHA_KEY}
                                    onChange={response => this.captchaChange(response)}
                                />

                                <div className="error-message-main">{this.state.submitErrorMessage}</div>

                                <div className="sign-me-up-button-container">
                                    <Button
                                        className="sign-me-up-button"
                                        disabled={this.state.loading}
                                        type="submit">{this.state.loading ? 'Loading' : 'Sign Up'}</Button>
                                </div>

                                <div className="resetPassword">
                                    <button
                                        onClick={() => this.props.history.push('/forget-password')}
                                        className="forgotPasswordButton">
                                        <p>Forgot Your Password?</p>
                                    </button>
                                </div>
                            </Form>
                            <TermsOfUseBottomBar />
                        </Col>
                    </Row>
                </Col>
                <button className="sign-up-page-head-right" onClick={() => this.props.history.push('/login')}>Login</button>
            </Row>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpPage)
