import myFirebase from "../firebase";
import { defaultProfilePicture } from "./Variables"
import { createCompanyRefKey } from "./FunctionBucket"
import { addGuideRole } from "../Auth/AddRole"
import { addFilesToDatabase } from "./UploadFirebase"

// Create User Login
export async function createUserLogin(myUser) {
    try {
        // Main Variables 
        const userid = myUser.uid
        const email = myUser.email

        // Initial Upload userLogin
        const dataToAdd = {
            userid: userid,
            accountType: "guide",
            created: new Date(),
            email: email,
        }
        const userLoginRef = await myFirebase.firestore().collection('userLogins').add(dataToAdd)
        dataToAdd.selfref = userLoginRef
        dataToAdd.selfid = userLoginRef.id

        // Adds the userMatches for each user
        const createUserMatches = myFirebase.functions().httpsCallable('createUserMatches')
        await createUserMatches({ userid })

        // Updates the guide accountType on token
        await addGuideRole({ email: email })

        // Returns data
        return dataToAdd

    } catch (error) { console.log(error); return {} }
}

// Creates the New User Profile
export async function createUserProfileFirestore(userid, data) {
    try {
        const dataToAdd = {
            name: data.name,
            jobTitle: data.jobTitle,
            updated: new Date(),
            userid: userid,
            companyDocid: null,
            profilePicture: { fileURL: defaultProfilePicture },
            referralCode: createCompanyRefKey()
        }
        const userProfileRef = await myFirebase.firestore().collection('userProfiles').doc(userid).set(dataToAdd)
        dataToAdd.selfid = userid
        dataToAdd.selfref = userProfileRef
        return dataToAdd
    } catch (err) { console.log("There was an error with creating userProfile: ", err) }
}

// Create Company
export async function createCompanyFirestore(data) {
    console.log(data)
    try {
        // Uploads Basic Data
        const dataToAdd = {
            type: data.type,
            companyName: data.companyName,
            description: data.description,
            interests: [],
            location: data.location,
            website: data.website
        }
        const companyRef = await myFirebase.firestore().collection("tier1Network").add(dataToAdd)
        dataToAdd.selfid = companyRef.id
        dataToAdd.selfref = companyRef

        // Upload Files
        const filesRef = await addFilesToDatabase(companyRef.id, data.files);
        dataToAdd.files = filesRef;
        return dataToAdd
    } catch (err) { console.log("There was an error with creating company: ", err) }
}

// Create Team Document
export async function createTeamDocument(userid, company, userProfile) {
    console.log(userid, company, userProfile)
    try {
        const admins = []
        const adjustedAdmins = []
        const dataToAdd = {
            companyRef: company,
            companyDocid: company.id,
            admins: [],
            members: []
        }
        if (!!userProfile) {
            admins.push(userid)
            adjustedAdmins.push(userProfile)
            dataToAdd.admins = admins
        }
        const teamRef = await myFirebase.firestore().collection('teams').add(dataToAdd)
        dataToAdd.admins = adjustedAdmins
        dataToAdd.selfid = teamRef.id
        dataToAdd.selfref = teamRef
        return dataToAdd
    } catch (err) {
        console.log("Errow with creating team document with company: ", err)
    }
}

// Creates Notifications
export async function createNotificationDocument(userid) {
    const dataToAdd = {
        lastLoggedIn: new Date(),
        lastViewedChat: [],
        userid: userid,
        chatNotificationsEnabled: false,
        emailNotificationsEnabled: true,
        lastEnabledSnooze: null,
    }
    await myFirebase.firestore().collection("notifications").doc(userid).set(dataToAdd);
    return dataToAdd
}

// Creates First Message
// Create a chat with "coopsight" -- currently set to a random user Eshita created
export async function createFirstChatMessage(userid) {
    //userid for the "coopsight" account -- currently set to the "Al the Llama" account :), second is coopsight open
    //const devAcctID = 'T3uklKMfvZWwWNvyEoBW81c0w5z1'
    const devAcctID = "9KyykiGJICYeZrnge44vNkC5ZxG2" 

    let memberList = [userid, devAcctID];
    let dataToAdd = {
        chatCreated: new Date(),
        members: memberList,
        initiatedByUser: userid
    };
    let welcomeMessage = "Hello, Welcome to the Coopsight Open Platform! We look forward to helping guide you toward new strategic partnerships! Feel free to reach out to us here or at info@coopsight.com. You will receive 30 matches each week for free, you can also add more matches in the balance page!"
    let firstText = {
        message: welcomeMessage,
        sender: devAcctID,
        timestampSent: new Date(),
        type: "text",
    }
    await myFirebase.firestore().collection("chats").add(dataToAdd)
        .then(docRef => {
            docRef.collection('texts').add(firstText);
        });
    return
}
