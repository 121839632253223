// General Use
import React from "react";
import Geocode from "react-geocode";

import {
  GoogleMap,
  LoadScript,
  StandaloneSearchBox,
  Marker,
} from "@react-google-maps/api";

import { countriesWithContinents } from "../../../GeneralUse/CountriesWithContinents"

Geocode.setApiKey(process.env.REACT_APP_GOOLE_MAP_API);

const googleScriptLibraries = ["places"]

class LocationMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      autocomplete: null,
      mapZoom: 12,
      markerPosition: {
        lat: 0,
        lng: 0,
      },
      mapPosition: {
        lat: 40.7144,
        lng: -74.006,
      },
    };
  }

  componentDidMount() {
    if (!!this.props.currentLocation) {
      Geocode.fromAddress(this.props.currentLocation).then(geocodeResponse => {
        let selectedPlace = geocodeResponse.results[0]
        let markerPosition = selectedPlace.geometry.location;
        let mapPosition = markerPosition
        this.setState({ markerPosition, mapPosition: mapPosition })
      }).catch(_ => {
        console.log("Error: Geolocation location NOT found!")
        this.setState({ markerPosition: null })
      })
    }
  }

  onLoad = (autocomplete) => {
    this.setState({ autocomplete });
  };

  onPlacesChanged = () => {
    const { autocomplete } = this.state;
    if (autocomplete !== null) {
      let selectedPlace = autocomplete.getPlaces()[0]
      let markerPosition = selectedPlace.geometry.location;
      let mapPosition = markerPosition
      //this.getContinent(selectedPlace.address_components)
      let parsedLocation = selectedPlace.formatted_address
      this.setState({ markerPosition, mapPosition: mapPosition })
      this.props.handleLocationChange(parsedLocation)
    }
  }

  getContinent = (address_components) => {
    console.log(address_components)
    address_components.forEach((eachAddress) => {
      if (JSON.stringify(eachAddress.types) === JSON.stringify([ "country", "political" ])) {
        let continent = countriesWithContinents[eachAddress.short_name]
        console.log(continent)
      }
    })
  }

  render() {
    const { markerPosition, mapPosition, mapZoom } = this.state;

   // console.log("we inmap")
    //console.log(countriesWithContinents)
    return (
      <div className="geo-location-map-wrapper">
        <LoadScript
          googleMapsApiKey={process.env.REACT_APP_GOOLE_MAP_API}
          libraries={googleScriptLibraries}
        >
          <StandaloneSearchBox
            onLoad={this.onLoad}
            className="standalone-search-box"
            onPlacesChanged={this.onPlacesChanged}
          >
            <input
              type="text"
              disabled={!!this.props.isDisabled ? this.props.isDisabled : false}
              className="map-search-box"
              placeholder="Enter your company location..."
              onChange={(e) => this.props.handleLocationChange(e.target.value)}
              value={this.props.currentLocation}

            />
          </StandaloneSearchBox>
          <br />
          <GoogleMap
            mapContainerStyle={{
              height: "25vh",
              width: "100%",
              marginTop: "-12px",
            }}
            zoom={mapZoom}
            center={mapPosition}
            className="google-map-map-itself"
          >
            <Marker onLoad={this.onLoadMarker} position={markerPosition} />
          </GoogleMap>
        </LoadScript>
      </div>
    );
  }
}

export default LocationMap;
