
import firebase from "firebase/app"
import 'firebase/storage'
import 'firebase/database'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/analytics'



const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_PRODUCTION_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_PRODUCTION_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_PRODUCTION_DATABASE,
    projectId: process.env.REACT_APP_FIREBASE_PRODUCTION_PROJECT,
    storageBucket: process.env.REACT_APP_FIREBASE_PRODUCTION_STORAGE,
    messagingSenderId: process.env.REACT_APP_FIREBASE_PRODUCTION_MESSAGING,
    appId: process.env.REACT_APP_FIREBASE_PRODUCTION_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_PRODUCTION_MEASUREMENT,
}
/*
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_STAGING_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_STAGING_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_STAGING_DATABASE,
    projectId: process.env.REACT_APP_FIREBASE_STAGING_PROJECT,
    storageBucket: process.env.REACT_APP_FIREBASE_STAGING_STORAGE,
    messagingSenderId: process.env.REACT_APP_FIREBASE_STAGING_MESSAGING,
    appId: process.env.REACT_APP_FIREBASE_STAGING_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_STAGING_MEASUREMENT,
}
*/

const myFirebase = firebase.initializeApp(firebaseConfig);
export const firebaseAnalytics = firebase.analytics();

export default myFirebase;
