// General Use
import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {createUserProfileFirestore} from "../../GeneralUse/CreateFirebase"

import { LoadingMediumIcon } from "../../Loading/LoadingPageIcon";
import { jobTitlesList } from "../../GeneralUse/Variables"
import { checkValidSmall } from "../../GeneralUse/CheckValidFunctions";
import { firebaseAnalytics } from "../../firebase"
import { changeMyUserProfile } from "../../Redux/actions";
import { capitalizeName } from "../../GeneralUse/FunctionBucket"

// Guide Steps
import ProfileSetupInitial from "./ProfileSetup/ProfileSetupInitial"
import ProfileSetupName from "./ProfileSetup/ProfileSetupName"
import ProfileSetupJobTitle from "./ProfileSetup/ProfileSetupJobTitle"

/*
  Create Profile Page
  * When you want to create your OWN profile and basic info
*/
const ProfileSetupMainPage = (props) => {
  const dispatch = useDispatch();

  // Tracks Analytics
  const [beginProcessTime] = useState(new Date())
  const [hasAccomplishedTask] = useState([
    { name: "initial", executed: false, timestamp: null },
    { name: "name", executed: false, timestamp: null },
    { name: "jobTitle", executed: false, timestamp: null },
    { name: "end", executed: false, timestamp: null },
  ])

  // <----------------------------------------------> //

  // Main Form (To be submitted to firebase)
  const [form, updateForm] = useState({
    profilePicture: { url: null, file: null },
    name: "",
    jobTitle: "",
  })
  const [errorMessage, setErrorMessage] = useState("");

  // Handles General Input
  function handleInputChange(e, key) {
    if (errorMessage.length > 0) { setErrorMessage("") }
    const editedForm = { ...form };
    editedForm[key] = e.target.value;
    updateForm(editedForm);
  }

  // Handle Job Title Input
  const [currentJobTitleValue, changeCurrentJobTitle] = useState(() => {
    let foundObj = jobTitlesList.filter(obj => obj.label === form.jobTitle)
    if (foundObj.length > 0) {
      return foundObj[0]
    } else return { value: form.jobTitle, label: form.jobTitle }
  })
  function handleChangeJobTitle(selection) {
    const editedForm = { ...form };
    editedForm.jobTitle = selection.label
    updateForm(editedForm);
    changeCurrentJobTitle(selection)
  }

  // <----------------------------------------------> //

  // Main Flow and Guide Process
  const [currentPathPoint, updatePathPoint] = useState(0);

  function goBack() { // Going to the PREVIOUS upload step in uploading profile
    if (errorMessage.length > 0) { setErrorMessage("") }
    updatePathPoint((prev) => prev - 1);
  }

  function goNext(key = null) { // Going to the NEXT upload step in uploading profile
    if (errorMessage.length > 0) { setErrorMessage("") }
    let inputCheck = { valid: true, message: "" };
    if (key === "name") {
      inputCheck = checkValidSmall(form[key]);
    } else if (key === "jobTitle") {
      inputCheck = checkValidSmall(form[key], false);
    }
    if (!inputCheck.valid) {
      setErrorMessage(inputCheck.message);
    } else {
      let eventLogName = `setupProfile${capitalizeName(key)}`
      let taskIndex = null
      let taskLogged = hasAccomplishedTask.find((task, index) => { taskIndex = index; return task.name === key })
      if (taskLogged.executed === false) {
        let loggedTimestamp = new Date()
        taskLogged.executed = true
        taskLogged.timestamp = loggedTimestamp
        let lastTime = hasAccomplishedTask[taskIndex - 1] ? hasAccomplishedTask[taskIndex - 1].timestamp : beginProcessTime
        let taskCompletionTime = (lastTime.getTime() - loggedTimestamp.getTime()) / 1000 * -1
        firebaseAnalytics.logEvent(eventLogName, {
          type: "setupProfile",
          user: props.currentUser.uid,
          timestampLogged: loggedTimestamp,
          taskCompletionTime: taskCompletionTime
        })
      }
      updatePathPoint((prev) => prev + 1);
    }

    if (currentPathPoint + 2 === guideItinerary.length) {
      // If this is the last step
      let endTimestamp = new Date()
      let fullCompletionTime = (endTimestamp.getTime() - hasAccomplishedTask[0].timestamp.getTime()) / 1000 * -1
      firebaseAnalytics.logEvent("setupProfileCompletion", {
        type: "setupProfile",
        user: props.currentUser.uid,
        timestampLogged: endTimestamp,
        fullCompletionTime: fullCompletionTime
      })
      finishProfileCreation()
    }
  }

  // Final Submittion
  async function finishProfileCreation() {
    console.log("Creating Profile Creation...");

    // Uploads the main userProfile
    const userProfileRef = await createUserProfileFirestore(props.currentUser.uid, form, null)
    dispatch(changeMyUserProfile(userProfileRef))

    console.log("...Finished Creating Profile: ")
  }

  // <----------------------------------------------> //

  // The Full Guide Steps (in order)
  const guideItinerary = [
    <ProfileSetupInitial
      currentUser={props.currentUser}
      errorMessage={errorMessage}
      goBack={goBack}
      goNext={goNext}
    />,
    <ProfileSetupName
      currentUser={props.currentUser}
      errorMessage={errorMessage}
      handleInputChange={handleInputChange}
      form={form}
      goBack={goBack}
      goNext={goNext}
    />,
    <ProfileSetupJobTitle
      currentUser={props.currentUser}
      errorMessage={errorMessage}
      handleInputChange={handleInputChange}
      form={form}
      currentJobTitleValue={currentJobTitleValue}
      handleChangeJobTitle={handleChangeJobTitle}
      goBack={goBack}
      goNext={goNext}
    />,

    <ProfileSetupLoading history={props.history} />,
  ];

  // Main Return Wrapper
  return (
    <Row className="guide-page create-profile-guide">
      <Col className="guide-inner-page">
        {currentPathPoint !== 0 && ( // Wraps each step with main title and the guide path
          <>
            <Row>
              <div className="guide-page-header">
                <p className="guide-header">Profile Setup</p>
              </div>
            </Row>

            <Row className="guide-wrapper-path-top">
              <ul className="guide-path-full">
                {guideItinerary.map((_, eachPointIndex) => {
                  return (
                    <li
                      key={eachPointIndex}
                      className={
                        eachPointIndex <= currentPathPoint
                          ? "point completed-point"
                          : "point other-point"
                      }
                    >{eachPointIndex + 1}</li>
                  )
                })}
              </ul>
            </Row>
            <div className="center-points-guide-path" />
          </>
        )}

        {/* The Main Body of each step */}
        <Row>{guideItinerary[currentPathPoint]}</Row>
      </Col>
    </Row>
  );
};

// Loading of Profile
const ProfileSetupLoading = (props) => {
  return (
    <div className="loading">
      <p className="guidethrough-head">Setting Up Your Profile...</p>
      <LoadingMediumIcon />
    </div>
  )
}

export default ProfileSetupMainPage;
