// General Use
import React, { useEffect, useState } from "react";
import myFirebase from "../firebase.js";
import { StandardFullPageLoading } from "../Loading/LoadingPageIcon"
import ErrorAuth from "./AuthError"
import { useDispatch } from 'react-redux'
import {
    changeMyUserLogin,
    changeMyUserProfile,
    changeMyTeam,
    changeMyCompany,
    changeMyChats,
    changeMyEcosystem,
    changeStatistics,
    changeMatching,
    changeFeed
} from '../Redux/actions';
import { createUserLogin } from "../GeneralUse/CreateFirebase"

// Main PreLoadFunctions
import loadMyData from "./PreLoadFunctions"

/*
    Authentication
    * Handles the user login and wraps around whole app
*/

export const AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
    const dispatch = useDispatch()

    //Gets the currentUser
    const [currentUser, setCurrentUser] = useState('default', () => { // onAuthStateChanged sets initial to null, but weird loading issue if null
        const localData = localStorage.getItem('userData');
        return localData ? JSON.parse(localData) : [];
    });

    const [authError, setAuthError] = useState(null)
    const [authUserData, setAuthUserData] = useState({ loadingState: false, accountType: null });

    useEffect(() => { // Detects any changes to token (sign ups, logins, or accountType changing)
        myFirebase.auth().onIdTokenChanged(() => {
            try {
                const myUser = myFirebase.auth().currentUser
                if (!!myUser) {
                    setAuthUserData({ loadingState: true, accountType: null }) // Sets initial to check user credentials
                    myFirebase.auth().currentUser.getIdTokenResult().then(result => {
                        if (result.claims.accountType) { // After we have the accountType, need to wait on preloadfunctions
                            var start = performance.now()
                            //console.log("START CLOCK")
                            loadMyData(myUser.uid, result.claims.accountType).then(loadedData => {

                                console.log("...Finished Loading Data")
                                var end = performance.now()
                                //console.log("EXECUTION TIME seconds.")
                                console.log((end - start) / 1000)

                                // Sets the preloads into the redux
                                loadedData.myCompany.files = loadedData.files // For debugging purposes, split up in preload
                                loadedData.myUserLogin.accountType = result.claims.accountType
                                dispatch(changeMyUserLogin(loadedData.myUserLogin))

                                dispatch(changeMyCompany(loadedData.myCompany))
                                dispatch(changeMyTeam(loadedData.myTeam))
                                dispatch(changeMyUserProfile(loadedData.myUserProfile))
                                dispatch(changeMyChats(loadedData.myChats))
                                dispatch(changeMyEcosystem(loadedData.myEcosystem))
                                dispatch(changeStatistics(loadedData.statistics))
                                dispatch(changeMatching(loadedData.matching))
                                dispatch(changeFeed(loadedData.feed))

                                // After all added, can proceed to pages
                                setAuthUserData({ loadingState: false, accountType: result.claims.accountType })
                            }).catch(err => { console.log(err); setAuthError(err) })
                        } else { // If the user just signed up!
                            createUserLogin(myUser).then(() => { myUser.getIdToken(true) })
                        }
                    })
                } else { setAuthUserData({ loadingState: false, accountType: null }) }
            } catch (err) { console.log(err); setAuthUserData({ loadingState: false, accountType: null }) }
            setCurrentUser(myFirebase.auth().currentUser)
        });
    }, [dispatch])

    var { loadingState, accountType } = authUserData;
    const waitingOnType = (!!currentUser && (!accountType)) // If we have the user, and just needs to read the accountType

    if (!!authError) { return <ErrorAuth /> }

    if ((loadingState || waitingOnType) && accountType !== undefined) {
        return <StandardFullPageLoading />
    } else {
        return (
            <AuthContext.Provider
                value={{
                    currentUser,
                    accountType,
                }}
            >{children}</AuthContext.Provider>
        )
    }
}

export default AuthProvider;
