// General Use
import React from 'react'

const MatchScoreBar = (props) => {

    var degree = (-90 - ((1 - 0.01 * props.score) * 180))

    const style = {
        transform: `rotate(${degree}deg)`
    }

    let idName = ""
    if (props.side === "tier2") {
        idName = "match-score-bar-t2-variant"
    } else {
        idName = "match-score-bar-t1-variant"
    }

    return (
        <div className="match-score-bar-wrapper" id={idName}>
            <div className="score-bar-1">
                <div className="sx1"></div>
                <div className="sx2" style={{ ...style }}></div>
            </div>
            <div className="score-bar-2">
                <div className="sx3"></div>
                <div className="sx4" style={{ ...style }}></div>
            </div>
            <h5 className="specific-score-num">{props.score}%</h5>
            <p className="specific-score-label">Compatability</p>
        </div>
    )
}

export default MatchScoreBar