//General Imports
import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux'
import { Row, Col } from 'react-bootstrap';
import { Sliders } from 'react-feather'
import { FiltersT1Popup } from "./Popups"

import { NavLink } from "react-router-dom";

const TopBar = (props) => {
    const { myUserLogin, myBalance } = useSelector(state => ({
        myUserLogin: state.myUserLogin,
        myBalance: state.myUserLogin.matches.currentBalance
    }))
    
    const [showFilterPopup, triggerFilterPopup] = useState(false)
    const [topCurrentBalance, setTopCurrentBalance] = useState(myUserLogin.matches.currentBalance);

    useEffect(() => {
        setTopCurrentBalance(myUserLogin.matches.currentBalance)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [myBalance]);

    return (
        <Row className="top-bar-wrapper">
            <Col className="top-bar">
                <h5 className="top-bar-main-score">
                    <NavLink to={"/tier1/balance"}>{topCurrentBalance ? myUserLogin.matches.currentBalance : 0}</NavLink>
                </h5>
                <h1 className="top-bar-main-title">
                    <a href="https://coopsight.com/" target="blank">Coopsight</a>
                </h1>
                <h5 className="top-bar-main-filter">
                    {props.history.location.pathname === "/tier1/matching" && (
                        <Sliders className="filter-icon-t1" onClick={() => triggerFilterPopup(!showFilterPopup)} />
                    )}
                </h5>
            </Col>
            {showFilterPopup && (<FiltersT1Popup closePopup={() => triggerFilterPopup(false)} />)}
        </Row>

    )
}
export default TopBar
