//General Imports
import React from 'react';

const TermsOfUseBottomBar = (props) => {
    return (
        <div className={!!props.extraTop ? "terms-of-use-bottom-bar-wrapper extra-top-padding-tos" : "terms-of-use-bottom-bar-wrapper" }>
            <ul className="terms-of-use-bottom-list">
                <li className="list-val first">
                    <a href="https://coopsight.com/terms-and-conditions" target="_blank" rel="noopener noreferrer" samesite="Strict">Legal Use</a>
                </li>
                <li className="list-val center-line">
                </li>
                <li className="list-val last">
                    <a href="https://coopsight.com/privacy-policy" target="_blank" rel="noopener noreferrer" samesite="Strict">Privacy Policy</a>
                </li>
            </ul>
        </div>
    )
}
export default TermsOfUseBottomBar