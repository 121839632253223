// General Use
import React, { useEffect, useState } from 'react';
import EnterOpenNetwork from "../../OpenNetwork/EnterOpenNetwork"
import { Row } from 'react-bootstrap';
import { useSelector } from 'react-redux'
/*
    Pro(T1) Matching Page
    * Main wrapper for T1
*/
const Tier1MatchingPage = (props) => {
    const { currentMatchFilters } = useSelector(state => ({
        currentMatchFilters: state.myUserProfile.currentMatchFilters,
    }))
    const [currentFilters, setCurrentFilters] = useState(currentMatchFilters)
    useEffect(() => {
        setCurrentFilters(currentMatchFilters)
    }, [currentMatchFilters]);

    return (
        <Row className="t1-page-matching-wrapper t1-matching-page">
            <EnterOpenNetwork {...props} userSide="tier1" currentMatchFilters={currentFilters} />
        </Row>
    )
}
export default Tier1MatchingPage
