// General Use
import React from "react";
import { Button } from "react-bootstrap";
import GuideToolTips from '../../Tier1/Components/GuideToolTips.js'
import CreatableSelect from 'react-select/creatable';
import { expandedReactStyle, selectThemeDropdown } from "../../Tier1/Components/ReactSelectStyles"
import { jobTitlesList } from "../../../GeneralUse/Variables"

// Uploading Job Title
const ProfileSetupJobTitle = (props) => {
    return (
        <section className="guide-profile-process-3-wrapper">
            <p className="guide-profile-process-header">What's your job title?
                <GuideToolTips
                    description="Choose a title that best describes your occupation. This job title will be viewed by other users once they have successfully matched to your profile."
                    placement="right"
                />
            </p>
            <CreatableSelect
                components={{ IndicatorSeparator: () => null }}
                theme={selectThemeDropdown}
                placeholder="Create or search your title..."
                styles={expandedReactStyle}
                className="job-title-input"
                value={!!props.currentJobTitleValue.value ? props.currentJobTitleValue : ""}
                onChange={props.handleChangeJobTitle}
                options={jobTitlesList}
            />
            <div className="error-message">{props.errorMessage}</div>
            <div className="profile-buttons">
                <Button className="buttons-guide-back" onClick={() => props.goBack()}>
                    Back
                </Button>
                <Button
                    className="buttons-guide-continue"
                    onClick={() => props.goNext("jobTitle")}
                >
                    Continue
                </Button>
            </div>
        </section>
    )
}

export default ProfileSetupJobTitle