// General Imports
import React from "react";
import { addFilesToDatabase } from "../../../GeneralUse/UploadFirebase";
import {
  checkFileValid,
  fileConstraints,
} from "../../../GeneralUse/CheckValidFunctions";

import SyncRequests from "./SyncRequests";

import { Row, Col, Button } from "react-bootstrap";
import { generateKeywords } from "../../../GeneralUse/PrimeFunctions";
import { Check, X, Upload, File } from "react-feather";

//Redux Stuff
import { connect } from "react-redux";
import { changeMyCompany } from "../../../Redux/actions";

import Dropzone from "react-dropzone";

function mapStateToProps(state) {
  return { reduxState: state };
}

function mapDispatchToProps(dispatch) {
  return {
    reduxDispatch: {
      changeMyCompany: (value) => dispatch(changeMyCompany(value)),
    },
  };
}

/*
    Editing Files
    - Displays the current files
    - Able to update each file by removing or adding new ones
*/

class EditFiles extends React.Component {
  constructor(props) {
    super();
    this.state = {
      selectedFilesListOld: [...props.selectedFiles],

      currentFiles: [...props.selectedFiles],
      newFiles: [],
      combinedFiles: [...props.selectedFiles],

      submittingFiles: false,
      errorMessage: "",
    };
  }

  detectChanges = () => {
    const { selectedFilesListOld, newFiles, combinedFiles } = this.state;
    if (newFiles.length > 0) {
      return true;
    }
    return (
      JSON.stringify(selectedFilesListOld) !== JSON.stringify(combinedFiles)
    );
  };

  addFileToForm = (newFile) => {
    const { newFiles, combinedFiles, errorMessage } = this.state;
    if (errorMessage.length > 0) {
      this.setState({ errorMessage: "" });
    }
    // const newFile = e.target.files[0];

    let inputCheck = checkFileValid(newFile, combinedFiles);

    // document.getElementById("file").value = "";
    if (!inputCheck.valid) {
      this.setState({ errorMessage: inputCheck.message });
    } else {
      newFiles.push({ name: newFile.name, fileBlob: newFile });
      combinedFiles.push({ name: newFile.name, fileBlob: newFile });
      this.setState({ newFiles, combinedFiles });
    }
  };

  removeFileFromForm = (fileToRemove) => {
    const { currentFiles, newFiles, combinedFiles } = this.state;
    if (!!fileToRemove.fileBlob) {
      // If new file (not uploaded already)
      newFiles.splice(newFiles.indexOf(fileToRemove), 1);
    } else {
      // If old file (already uploaded)
      currentFiles.splice(currentFiles.indexOf(fileToRemove), 1);
    }
    combinedFiles.splice(combinedFiles.indexOf(fileToRemove), 1);
    this.setState({ currentFiles, newFiles, combinedFiles });
  };

  cancelEdit = () => {
    this.props.triggerEditFiles(false);
  };
  saveEdits = async () => {
    if (this.state.errorMessage.length > 0) {
      return;
    }
    this.setState({ submittingFiles: true });
    const { currentFiles, newFiles } = this.state;

    let fileBinRef = this.props.currentCompany.files.selfref;
    const filesUpdateResponse = await addFilesToDatabase(
      this.props.currentCompany.selfid,
      newFiles,
      { oldFiles: currentFiles, fileBinRef: fileBinRef }
    );

    const currentCompany = { ...this.props.currentCompany };
    const selfid = currentCompany.files.selfid;
    const selfref = currentCompany.files.selfref;
    currentCompany.files = filesUpdateResponse;
    currentCompany.files.selfref = selfid;
    currentCompany.files.selfref = selfref;

    let cleanFiles = filesUpdateResponse.filesList.map((eachFile) => {
      return eachFile.fileURI;
    }); // Cleans the files for preparse before keywords

    // Generating my Keywords
    if (this.props.reduxState.myUserLogin.accountType === "tier1") {
      await generateKeywords(
        currentCompany.selfid,
        "startup",
        "__self__",
        currentCompany.selfref.path,
        cleanFiles,
        currentCompany.industryKeywords.industry
      );
    } else {
      /*
            await generateKeywords(
                currentCompany.selfid,
                form.type,
                "__self__",
                companyReduxValues.selfref.path,
                cleanFiles
            )
            */
      console.log("Not yet configured");
    }

    this.props.reduxDispatch.changeMyCompany(currentCompany);

    this.props.triggerEditFiles(false);
  };

  render() {
    const { combinedFiles, submittingFiles } = this.state;

    if (this.state.showPendingRequests) {
      return (
        <Row className="t1-page">
          <SyncRequests
            goBack={() => this.setState({ showPendingRequests: false })}
          />
        </Row>
      );
    }

    if (this.props.side === "tier1") {
      return (
        <Row className="t1-page-wrapper t1-edit-files-page">
          <Col className="t1-inner-page">
            <Row className="t1ef-top">
              {this.detectChanges() ? (
                <Button
                  className="go-back-bttn"
                  disabled={submittingFiles}
                  onClick={() => this.saveEdits()}
                >
                  <Check />
                </Button>
              ) : (
                <Button
                  className="go-back-bttn"
                  onClick={() => this.cancelEdit()}
                >
                  <X />
                </Button>
              )}
              <h4 className="edit-files-header">Files</h4>
            </Row>
            <Row className="t1ef-full-list">
              <Col>
                <p className="edit-files-desc">
                  Upload files (these will NOT be shared publicly without your
                  permission)
                </p>
                <p className="error-message">{this.state.errorMessage}</p>
                <ul className="current-files-edit-list">
                  {combinedFiles.map((eachFile, index) => {
                    return (
                      <li key={index} className="current-selected-file">
                        <h5 className="file-name">{eachFile.name}</h5>
                        <File className="file-icon-center" />
                        <Button
                          className="remove-file-bttn"
                          disabled={submittingFiles}
                          onClick={() => this.removeFileFromForm(eachFile)}
                        >
                          <X className="remove-icon-file" />
                        </Button>
                      </li>
                    );
                  })}
                  {combinedFiles.length <
                    fileConstraints.maxFilesPerCompany && (
                    <li className="company-file-add-new">
                      <div className="upload-new-company-file-wrapper">
                        {/* <input
                                                    id="file"
                                                    className="upload-new-company-file-input"
                                                    type='file'
                                                    disabled={submittingFiles}
                                                    accept={fileConstraints.fileTypes}
                                                    onChange={this.addFileToForm} /> */}
                        <div className="upload-new-company-file-input">
                          <Dropzone
                            onDrop={(files, rejectedFiles) => {
                              const newFile = files[0];
                              this.addFileToForm(newFile);
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <section>
                                <div {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  {/* <p>Drag 'n' drop some files here</p> */}
                                </div>
                              </section>
                            )}
                          </Dropzone>
                        </div>
                        <label for="file">
                          <Upload />
                        </label>
                      </div>
                    </li>
                  )}
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row className="t1-edit-files-page">
          <Col className="t1-inner-page">
            <Row className="t1ef-top">
              {this.detectChanges() ? (
                <Button
                  className="go-back-bttn"
                  disabled={submittingFiles}
                  onClick={() => this.saveEdits()}
                >
                  <Check />
                </Button>
              ) : (
                <Button
                  className="go-back-bttn"
                  onClick={() => this.cancelEdit()}
                >
                  <X />
                </Button>
              )}
            </Row>
            <Row className="t1ef-full-list">
              <Col>
                <p className="edit-files-desc">
                  Upload files (these will NOT be shared publicly without your
                  permission)
                </p>
                <p className="error-message">{this.state.errorMessage}</p>
                <ul className="current-files-edit-list">
                  {combinedFiles.map((eachFile, index) => {
                    return (
                      <li key={index} className="current-selected-file">
                        <h5 className="file-name">{eachFile.name}</h5>
                        <File className="file-icon-center" />
                        <Button
                          className="remove-file-bttn"
                          disabled={submittingFiles}
                          onClick={() => this.removeFileFromForm(eachFile)}
                        >
                          <X className="remove-icon-file" />
                        </Button>
                      </li>
                    );
                  })}
                  {combinedFiles.length <
                    fileConstraints.maxFilesPerCompany && (
                    <li className="company-file-add-new">
                      <div className="upload-new-company-file-wrapper">
                        {/* <input
                          id="file"
                          className="upload-new-company-file-input"
                          type="file"
                          disabled={submittingFiles}
                          accept={fileConstraints.fileTypes}
                          onChange={this.addFileToForm}
                        /> */}
                        <Dropzone
                          onDrop={(files, rejectedFiles) => {
                            const newFile1 = files[0];
                            this.addFileToForm(newFile1);
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <section>
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                {/* <p>Drag 'n' drop some files here</p> */}
                              </div>
                            </section>
                          )}
                        </Dropzone>
                        <label for="file">
                          <Upload />
                        </label>
                      </div>
                    </li>
                  )}
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      );
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditFiles);
