//General Imports
import React from "react";
import myFirebase from "../../../firebase";
import { Row, Col, Button } from 'react-bootstrap';


//Redux Stuff
import { connect } from "react-redux";

function mapStateToProps(state) { return { reduxState: state } }

/*
    Sync Requests
    - This is for files for t2/t1 handling
    - Allows for files to be shared with permission
*/
class SyncRequests extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            syncRequests: [],
            syncRequestsAccepted: [],
            isUpdating: false,
        };
    }

    componentDidMount() {
        this.getSyncRequests();
    }

    /*
        {
            approved: null,
            toCompany; "docid",
            decisionTime: null
            fromCompany: "docid",
            useridSender: "",
        },
    */

    getSyncRequests = async () => {
        try {
            let syncSnapshots = await myFirebase.firestore().collection('syncRequests').where('toCompany', '==', this.props.reduxState.myCompany.selfid).get()
            if (!syncSnapshots.empty) {
                let syncDataLocal = []
                syncSnapshots.forEach(async eachSyncRequest => {
                    let syncData = eachSyncRequest.data()
                    syncData.selfid = eachSyncRequest.id
                    syncData.selfref = eachSyncRequest.ref
                    syncData.personRequesting = await this.getPersonInfo(syncData.useridSender)
                    syncDataLocal.push(syncData)
                })
                this.setState({ syncRequests: syncDataLocal })
            }
        } catch (err) { console.log(err) }
    }

    getPersonInfo = async (userid) => {
        try {
            let userProfileSnapshot = await myFirebase.firestore().collection('userProfiles').where('userid', '==', userid).get()
            if (!userProfileSnapshot.empty) {
                return userProfileSnapshot.docs[0].data()
            }
        } catch (err) { console.log(err) }
    }

    render() {
        const { syncRequests, syncRequestsAccepted, isUpdating } = this.state
        return (
            <Col>
                <Row>
                    <Col>
                        <Button onClick={() => this.props.goBack()}>Back</Button>
                        <h1>Premier Requests</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h2>Pending Requests</h2>
                        <ul>
                            {syncRequests.map((eachRequest, eachRequestIndex) => {
                                let person = eachRequest.personRequestingInfo
                                return (
                                    <li key={eachRequestIndex}>
                                        <img
                                            src={person.profilePicture.fileURL}
                                            alt=""
                                            className="profile-picture-image"
                                        />
                                        <div>
                                            <h5>{person.name}</h5>
                                            <button onClick={() => this.updateRequest(person.userid, eachRequest, true)} disabled={isUpdating}>accept</button>
                                            <button onClick={() => this.updateRequest(person.userid, eachRequest, false)} disabled={isUpdating}>reject</button>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h2>Accepted Requests</h2>
                        <ul>
                            {syncRequestsAccepted.map((eachRequest, eachRequestIndex) => {
                                let person = eachRequest.personRequestingInfo
                                return (
                                    <li key={eachRequestIndex}>
                                        <img
                                            src={person.profilePicture.fileURL}
                                            alt=""
                                            className="profile-picture-image"
                                        />
                                        <div>
                                            <h5>{person.name}</h5>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </Col>
                </Row>
            </Col>
        );
    }
}
// export default Requests;
export default connect(mapStateToProps)(SyncRequests);