// This file serves to address common errors that come from the software

export const signUpErrors = [

]

const loginErrors = [
    { code: 'auth/wrong-password', message: "The email or password you entered is incorrect!" },
    { code: 'auth/user-not-found', message: "The email or password you entered is incorrect!" },
    { code: 'auth/wrong-password', message: "The email or password you entered is incorrect!" },
    { code: 'auth/user-mismatch', message: "The email or password you entered is incorrect!" },
]

export function handleLoginError(errorObj) {
    let customCoopsightError = loginErrors.find(o => o.code === errorObj.code)
    if (!!customCoopsightError) {
        return customCoopsightError.message
    } else {
        return errorObj.message
    }
}
