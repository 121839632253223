//General Imports
import React from 'react';
import { updateCompany } from "../../../GeneralUse/UploadFirebase"
import { preDefinedInterestsList } from "../../../GeneralUse/Variables"
import { inputConstraints, checkInterestsValid } from "../../../GeneralUse/CheckValidFunctions"
import { Row, Col, Button, Form } from 'react-bootstrap';
import { Check, X } from 'react-feather';

//Redux Stuff
import { connect } from 'react-redux';
import { changeMyChats } from '../../../Redux/actions'

function mapStateToProps(state) { return { reduxState: state } }

function mapDispatchToProps(dispatch) {
    return {
        reduxDispatch: {
            changeMyChats: value => dispatch(changeMyChats(value)),
        }
    }
}

/*
    Editing Interests
    - Displays the current interests
    - Able to deselect or select new interests
*/
class EditInterests extends React.Component {
    constructor(props) {
        super();
        this.state = {
            customInterestInput: "",
            selectedInterestsOld: [...props.selectedInterests],
            selectedInterests: [...props.selectedInterests],
            preDefinedInterestsList: [...preDefinedInterestsList],
            submittingInterests: false,

            errorMessage: "",
        }
    }

    componentDidMount() {
        // Adds the custom inputs
        const { preDefinedInterestsList } = this.state

        this.props.selectedInterests.forEach(eachAlreadySelectedInterest => {
            if (!preDefinedInterestsList.includes(eachAlreadySelectedInterest)) {
                preDefinedInterestsList.push(eachAlreadySelectedInterest)
            }
        })
        this.setState({ preDefinedInterestsList })
    }
    selectInterest = (interest, index) => {
        const { selectedInterests, errorMessage } = this.state
        if (errorMessage.length > 0) { this.setState({ errorMessage: "" }) }
        if (this.isSelected(interest)) {
            // unselect
            selectedInterests.splice(selectedInterests.indexOf(interest), 1)
        } else {
            // select
            let inputCheck = checkInterestsValid(selectedInterests.concat(['']))
            if (!inputCheck.valid) {
                this.setState({ errorMessage: inputCheck.message })
                return
            }
            selectedInterests.push(interest)
        }
        this.setState({ selectedInterests })
    }
    isSelected = (interest) => {
        if (this.state.selectedInterests.includes(interest)) return true
        else return false
    }
    cancelEdit = () => {
        this.props.triggerEditInterests(false)
    }
    saveEdits = async () => {
        this.setState({ submittingInterests: true })
        const interestsUpdateResponse = await updateCompany(this.props.currentCompany, { interests: this.state.selectedInterests })
        this.props.setInterestsFinishMessage(interestsUpdateResponse.message)
        this.props.triggerEditInterests(false)
    }
    detectChanges = () => {
        return JSON.stringify(this.state.selectedInterestsOld) !== JSON.stringify(this.state.selectedInterests);
    }
    addCustomInterest = (e) => {
        e.preventDefault()
        const { preDefinedInterestsList, customInterestInput, selectedInterests, errorMessage } = this.state
        let inputCheck = checkInterestsValid(selectedInterests)
        if (!inputCheck.valid) {
            this.setState({ errorMessage: inputCheck.message })
            return
        }
        if (errorMessage.length > 0) { this.setState({ errorMessage: "" }) }
        preDefinedInterestsList.push(customInterestInput)
        this.selectInterest(customInterestInput, -1)
        this.setState({ preDefinedInterestsList, customInterestInput: "" })
    }

    render() {
        if (this.props.side === "tier1") {
            return (
                <Row className="t1-page-wrapper t1-edit-interests-page">
                    <Col className="t1-inner-page">
                        <Row className="t1ei-top">
                            {this.detectChanges() ?
                                (<Button
                                    className="go-back-bttn"
                                    disabled={this.state.submittingInterests}
                                    onClick={() => this.saveEdits()}><Check /></Button>) :
                                (<Button
                                    className="go-back-bttn"
                                    onClick={() => this.cancelEdit()}><X /></Button>)}
                            <h4 className="edit-interest-header">Areas of Interest</h4>
                        </Row>
                        <Row className="t1ei-full-list">
                            <Col>
                                <p className="edit-interest-desc">
                                    What is your company's areas of interests? (select at most {inputConstraints.maxInterests})
                                </p>
                                <p className="error-message">{this.state.errorMessage}</p>
                                <ul className="edit-interest-full-list-wrapper">
                                    {this.state.preDefinedInterestsList.map((eachInterest, index) => {
                                        return (
                                            <li className="edit-interest-out-wrapper" key={index}>
                                                <Button
                                                    className={
                                                        this.isSelected(eachInterest)
                                                            ? "each-interest selected-interest"
                                                            : "each-interest"
                                                    }
                                                    onClick={() => this.selectInterest(eachInterest, index)}
                                                    disabled={this.state.submittingInterests}>
                                                    {eachInterest}
                                                </Button>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </Col>
                        </Row>
                        <Row className="t1ei-custom">
                            <Col>
                                <Form className="custom-input-wrapper" onSubmit={this.addCustomInterest}>
                                    <input
                                        name="customInterest"
                                        value={this.state.customInterestInput}
                                        onChange={(e) => this.setState({ customInterestInput: e.target.value })}
                                        autoComplete="off"
                                    />
                                    <Button disabled={this.state.customInterestInput.length === 0} type="submit">Add</Button>
                                </Form>
                            </Col>
                        </Row>
                    </Col>
                </Row >
            )
        } else {
            return (
                <Row className="t1-edit-interests-page">
                    <Col className="t1-inner-page">
                        <Row className="t1ei-top">
                            {this.detectChanges() ?
                                (<Button
                                    className="go-back-bttn"
                                    disabled={this.state.submittingInterests}
                                    onClick={() => this.saveEdits()}><Check /></Button>) :
                                (<Button
                                    className="go-back-bttn"
                                    onClick={() => this.cancelEdit()}><X /></Button>)}
                            <h4 className="edit-interest-header">Areas of Interest</h4>
                        </Row>
                        <Row className="t1ei-full-list">
                            <Col>
                                <p className="edit-interest-desc">
                                    What is your company's areas of interests? (select at most {inputConstraints.maxInterests})
                                </p>
                                <p className="error-message">{this.state.errorMessage}</p>
                                <ul className="edit-interest-full-list-wrapper">
                                    {this.state.preDefinedInterestsList.map((eachInterest, index) => {
                                        return (
                                            <li className="edit-interest-out-wrapper" key={index}>
                                                <Button
                                                    className={
                                                        this.isSelected(eachInterest)
                                                            ? "each-interest selected-interest"
                                                            : "each-interest"
                                                    }
                                                    onClick={() => this.selectInterest(eachInterest, index)}
                                                    disabled={this.state.submittingInterests}>
                                                    {eachInterest}
                                                </Button>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </Col>
                        </Row>
                        <Row className="t1ei-custom">
                            <Col>
                                <Form className="custom-input-wrapper" onSubmit={this.addCustomInterest}>
                                    <input
                                        name="customInterest"
                                        value={this.state.customInterestInput}
                                        onChange={(e) => this.setState({ customInterestInput: e.target.value })}
                                        autoComplete="off"
                                    />
                                    <Button disabled={this.state.customInterestInput.length === 0} type="submit">Add</Button>
                                </Form>
                            </Col>
                        </Row>
                    </Col>
                </Row >
            )
        }

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditInterests)

