//General Imports
import React, { useContext, useState } from "react";
import { Redirect } from "react-router";
import myFirebase from "../../firebase.js";
import { handleLoginError } from "../../GeneralUse/ErrorHandling"
import TermsOfUseBottomBar from "../Tier1/Components/TermsOfUseBottomBar"
import { Row, Col, Form, Button } from 'react-bootstrap';
import { AuthContext } from "../../Auth/Authentication"
import { Eye, EyeOff } from 'react-feather'

/*
    Login Page
    - Main login page for signing in from a previous existed account
    - also has custom error handling
*/
const LoginPage = (props) => {
    const { accountType, currentUser } = useContext(AuthContext);

    const [isLoading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [password1Hidden, setPassword1Hidden] = useState(true)

    function handleLogin(event) {
        event.preventDefault();
        setLoading(true)
        const { email, password } = event.target.elements;
        myFirebase.auth().signInWithEmailAndPassword(email.value, password.value)
            .then(() => {
                setLoading(false)
                props.history.push("/tier1/matching");
            })
            .catch((error) => {
                setErrorMessage(handleLoginError(error))
                setLoading(false)
            })
    }

    //Shows Password in input
    function showPassword(e) {
        e.preventDefault();
        if (password1Hidden) {
            setPassword1Hidden(false)
        } else {
            setPassword1Hidden(true)
        }
    }

    //Redirects if logged in already (no need to relogin again)
    if (!accountType && !!currentUser) {
        return <Redirect to={'/guide/profile-set-up'} />
    }
    switch (accountType) {
        case "guide":
            return <Redirect to={'/guide/profile-set-up'} />
        case "tier1":
            return <Redirect to={'/tier1/matching'} />
        case "tier2":
            return <Redirect to={'/tier2/matching'} />
        case "tier3":
            return <Redirect to={'/tier3/matching'} />
        default:
            return (
                <Row className="main-page-wrapper main-login-page">
                    <Col className="main-inner-page">
                        <Row className="login-page-head">
                            <p>Login</p>
                        </Row>
                        <Row className="login-page-body">
                            <Col>
                                <Form className="login-form-wrapper" onSubmit={handleLogin}>
                                    <div className="label-input-wrapper">
                                        <div>
                                            <input
                                                className="baseline-input"
                                                name="email"
                                                type="text"
                                                placeholder="  Email"
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>

                                    <div className="label-input-wrapper">
                                        <div className="passwordWrap">
                                            <input
                                                className="baseline-input"
                                                name="password"
                                                type={password1Hidden ? "password" : "text"}
                                                placeholder="  Password"
                                                autoComplete="off"
                                            />
                                            <button className="showPass" onClick={showPassword}>
                                                <span className="eyeball">
                                                    {password1Hidden ? (
                                                        <div className="eye"><EyeOff /></div>
                                                    ) : (<div className="eye"><Eye /></div>)}
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="error-message-main">{errorMessage}</div>
                                    <div className="log-me-in-button-container">
                                        <Button
                                            className="log-me-in-button"
                                            type="submit"
                                            disabled={isLoading}>
                                            {isLoading ? "Loading" : "Login"}
                                        </Button>
                                    </div>
                                    <div className="resetPassword">
                                        <button onClick={() => props.history.push('/forget-password')}
                                            className="forgotPasswordButton"><p>Forgot Your Password?</p></button>
                                    </div>

                                    <TermsOfUseBottomBar />

                                </Form>
                            </Col>

                        </Row>
                    </Col>
                    <button
                        className="login-page-head-right"
                        onClick={() => props.history.push('/sign-up')}
                    >Sign Up</button>

                </Row>
            )
    }
}

export default LoginPage
