//General Imports
import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "./Authentication";
import { Tier1Navigation } from "../Scenes/Shared/Navigation"
import TopBar from "../Scenes/Tier1/Components/TopBar"
import { Container } from 'react-bootstrap';

// Route Wrapper for Pro(T1) (matching, chat, profile, etc.)
const PrivateRouteTier1 = ({ component: RouteComponent, ...rest }) => {
  const { currentUser, accountType } = useContext(AuthContext);

  //If login is good, allow access or redirect to login
  if (!!currentUser) {
    return (
      <Route
        {...rest}
        render={routeProps =>
          (accountType === "tier1") ? (
            <>
              <TopBar {...routeProps} /> {/* The TOP nav bar of the app with Coopsight name and matches */}
              <Container fluid className="t1-main-container-wrapper">
                <RouteComponent {...routeProps}
                  currentUser={currentUser}
                  accountType={accountType} />
                <Tier1Navigation />
              </Container>
            </>
          ) : (
              <Redirect to={"/incorrect-login"} />
            )
        }
      />
    )
  } else {
    //Bad login
    return (
      <Route
        {...rest}
        render={() => (
          <Redirect to={"/login"} />
        )}
      />
    )
  }
};


export default PrivateRouteTier1
