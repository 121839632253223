// General Use
import React from "react";
import { Button } from "react-bootstrap";

// Uploading Name
const ProfileSetupName = (props) => {
    return (
        <section className="guide-profile-process-2-wrapper">
            <p className="guide-profile-process-header">What's your name?<span className="asterisk-required"> *</span></p>
            <input
                className="name-input"
                value={props.form.name}
                placeholder="Enter your name..."
                autoComplete="off"
                onChange={(e) => props.handleInputChange(e, "name")}
                onKeyPress={e => { if (e.key === "Enter") props.goNext("name") }}
            />
            <div className="error-message-main">{props.errorMessage}</div>
            <div className="profile-buttons">
                <Button className="buttons-guide-back" onClick={() => props.goBack()}>
                    Back
                </Button>
                <Button
                    className="buttons-guide-continue"
                    disabled={!!props.errorMessage || props.form.name.length === 0}
                    onClick={() => props.goNext("name")}
                >
                    Continue
                </Button>
            </div>
        </section>
    );
}
export default ProfileSetupName