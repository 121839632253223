// General Use
import React from "react";
import myFirebase from "../firebase";
import { sendEmailMatch } from "../GeneralUse/FunctionBucket.js";
import { firebaseAnalytics } from "../firebase";
import { generateBackendMatches, populateDeck } from "../GeneralUse/PrimeFunctions";
import { NoMatchesCondensedPopup } from "../Scenes/Tier1/Components/Popups";
import { NoMatchesCard } from "./NoMatchesCard";
import { saveMatchHand, saveMatchParameters } from "../GeneralUse/PrimeFunctions";
import { OpenNetworkT1SingleLayout, OpenNetworkT1ManyLayout, } from "./OpenNetworkLayoutT1";
import { connect } from "react-redux";
import { changeMyChats, changeMyUserLogin, changeMatching } from "../Redux/actions"; // all functions to change
import { saveMatchData } from "./UpdateAnalytics"

import { sendEmailMatchBundle, sendEmailChatBundle } from "../GeneralUse/DevFunctionality"

function mapStateToProps(state) { return { reduxState: state }; } // To READ reduxState values
function mapDispatchToProps(dispatch) {
  // To CHANGE redux values
  return {
    reduxDispatch: {
      changeMyChats: (value) => dispatch(changeMyChats(value)),
      changeMyUserLogin: (value) => dispatch(changeMyUserLogin(value)),
      changeMatching: (value) => dispatch(changeMatching(value)),
    },
  };
}

/*
  Enter Open Network
  * Used for BOTH Tier1(Pro) and Tier2(Premier)
  * Slightly different handling since the match from in T1 is a SINGLE company while
    in T1 there is the potential to have the match from be many
*/

class EnterOpenNetwork extends React.Component {
  constructor(props) {
    super();
    this.state = {
      deck: [...props.reduxState.matching.deck], // Main "deck" of cards
      displayedMatch: { ...props.reduxState.matching.hand }, // current "hand" in deck
      addingToDeck: false, // if already generating more cards to add to deck

      // Handle 'likes'
      likedCompany: null,
      likedScore: null,

      reportTriggered: false, // if the report popup is shown

      noMatchesTriggered:
        props.reduxState.myUserLogin.matches.currentBalance === 0
          ? true
          : false,

      parameters: {
        // for t2, because parameters may change
        generate: {},
        read: {},
        matchingFrom: [], // Either single or many
      },

      currentMatchStartTime: new Date(), // Used for timer
      newFilterMatchesRunning: false, // for if the new filters are selected and generating to take precendent
    };
  }

  componentDidMount() {
    const matchingReduxState = this.props.reduxState.matching;

    // Gets the current parameters (saves if switches between pages)
    if (!!matchingReduxState.parameters) {
      const { parameters } = this.state;

      parameters.generate = matchingReduxState.parameters.generate;
      parameters.read = matchingReduxState.parameters.read;
      parameters.matchingFrom = matchingReduxState.parameters.matchingFrom;
      this.setState({ parameters });
    }
    // Testing
    //
    //
    
    //sendEmailChatBundle()
    //this.runTime()
  }

  runTime = async () => {
    /*
    let res = await sendEmailMatchBundle()
    console.log(res)
    godSpeed(res)
    */
    sendEmailChatBundle()
  }



  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.currentMatchFilters) !==
      JSON.stringify(this.props.currentMatchFilters)
    ) {
      const matchingReduxState = this.props.reduxState.matching;
      const myUserProfileReduxState = this.props.reduxState.myUserProfile;

      let currentHandLabel = matchingReduxState.hand.to_label;
      let currentFilters = myUserProfileReduxState.currentMatchFilters;

      if (!!currentHandLabel && !!currentFilters) {
        let rerenderNeeded = !currentFilters.includes(currentHandLabel);
        if (rerenderNeeded) {
          this.setState(
            { deck: [], newFilterMatchesRunning: true },
            function () { this.generateAndAddCards(); }
          );
        }
      }
    }
  }

  // Goes to next match displayed (if X hit or like successfull)
  goNextMatch = async (status) => {
    console.log("Getting Next Match...");

    saveMatchData(
      this.props.reduxState.myCompany,
      this.state.displayedMatch.companyData,
      this.state.displayedMatch,
      this.props.currentUser.uid,
      status
    )

    let { deck, addingToDeck } = this.state;

    // Redux Values
    const reduxStateMyUserLogin = this.props.reduxState.myUserLogin;
    if (reduxStateMyUserLogin.matches.currentBalance === 0) {
      // If ran out of matches
      // Sets the display, to no longer view the usual cards but have report
      this.setState({ noMatchesTriggered: true });
      return;
    }
    if (deck.length > 0) {
      // As long as we have more cards in the deck, repopulate the current hand
      this.popCardOffDeck();
    }
    if (deck.length <= 3) {
      // When we generate the backend matches. So at this length, begin adding more cards to deck
      if (addingToDeck === true) {
        return;
      }
      this.generateAndAddCards();
    }
  };

  generateAndAddCards = async () => {
    let { deck } = this.state;

    // Redux Values
    const reduxStateMatching = this.props.reduxState.matching;
    const reduxStateMyCompany = this.props.reduxState.myCompany;
    const reduxStateMyUserProfile = this.props.reduxState.myUserProfile;
    // If we are aleady trying to add more matches to the deck (no need to keep generating backend matches)

    this.setState({ addingToDeck: true });

    let localCurrentFilters = [...reduxStateMyUserProfile.currentMatchFilters];
    await generateBackendMatches(
      this.props.currentUser.uid,
      "single",
      [reduxStateMyCompany.selfid],
      [],
      localCurrentFilters,
      [reduxStateMyCompany.selfid]
    );

    if (
      JSON.stringify(localCurrentFilters) !==
      JSON.stringify(reduxStateMyUserProfile.currentMatchFilters)
    ) {
      this.setState({ addingToDeck: false });
      return;
    }

    // Forms a list of the current "cards" on the frontend (so we dont add the same card to the deck more than 1 time!)
    let fullUniqueDeckList = deck.map((eachcard) => {
      return eachcard.selfid;
    });
    fullUniqueDeckList.push(reduxStateMatching.hand.selfid);

    // The matches are in matchCache but now need to get to add to deck
    // note. populateDeck returns the new hand (if needed) and the new deck! :D
    let deckObj = await populateDeck(
      reduxStateMatching.matchMe.selfref,
      reduxStateMyCompany.selfid,
      fullUniqueDeckList,
      [],
      reduxStateMatching.filters
    );

    // Final Parsing Results
    let deckFull = deckObj.deck.concat(deck);
    this.setState({ deck: deckFull, addingToDeck: false });
  };

  // Uses a queue to pop the last card to deck so each popped card is the current displayed card
  popCardOffDeck = async () => {
    let { deck, displayedMatch, parameters, currentMatchStartTime } = this.state;

    // Redux Values
    const reduxStateMatching = this.props.reduxState.matching;
    const reduxStateMyUserLogin = this.props.reduxState.myUserLogin;
    const reduxStateMyCompany = this.props.reduxState.myCompany;

    if (!!displayedMatch) {
      // If we have a current card to actually pop
      let handDeepCopy = { ...reduxStateMatching.hand }; // makes copy of "old" match card
      if (!!handDeepCopy.selfref) {
        try {
          //console.log("Popping: ", reduxStateMatching.hand)

          // Note. updates locally because we can process backend stuff in background and make the matches appear fast!

          // Updates Local (priority to show users quick results) and simple pop item off list and update values
          displayedMatch = deck.pop();
          reduxStateMatching.hand = displayedMatch;
          reduxStateMatching.deck = deck;
          reduxStateMyUserLogin.matches.currentBalance =
            reduxStateMyUserLogin.matches.currentBalance - 1;
          this.props.reduxDispatch.changeMyUserLogin(reduxStateMyUserLogin);
          this.props.reduxDispatch.changeMatching(reduxStateMatching);
          this.setState({
            deck,
            displayedMatch,
            currentMatchStartTime: new Date(),
          });
          console.log("updating card...")

          // Updates Doc with Viewed
          await handDeepCopy.selfref.update({ viewed: true });

          // Updates the currentHand and its parameters
          await saveMatchHand(this.props.currentUser.uid, displayedMatch);
          if (this.props.accountType === "tier2") {
            // Need to save because parameters can change for t2
            await saveMatchParameters(this.props.currentUser.uid, parameters);
          }

          // Updates the Balance
          const decreaseMatchBalance = myFirebase.functions().httpsCallable("decreaseMatchBalance"); // Handles backend for security reasons
          await decreaseMatchBalance({ userid: this.props.currentUser.uid });

          // End Timer (how long spent viewing match card) for stats
          let popTime = new Date();
          let timeViewedCard = popTime - currentMatchStartTime;

          // Analytics
          firebaseAnalytics.logEvent("matchesViewed", {
            matchFromCompanyType: reduxStateMyCompany.type,
            matchToCompanyType: handDeepCopy.to_label,
            viewTime: timeViewedCard,
          });
        } catch (err) {
          console.log("error:", err);
        }
      }
    }
    if (this.props.accountType === "tier2")
      if (!!this.props.changeTopBar()) {
        // Updates the current balance of t2 (t2 has weird issue with updating)
        this.props.changeTopBar();
      }
  };

  // Check to see if we have results to display
  validDisplayedMatch = () => {
    return Object.keys(this.state.displayedMatch).length > 0;
  };

  // Displays the synergy keywords
  getSingleSynergies = () => {
    const { displayedMatch } = this.state;
    let keyIndexes = Object.keys(displayedMatch.child_scores);
    return Object.keys(displayedMatch[keyIndexes[0]]);
  };
  getSingleIndustriesSynergies = (id) => {
    const { displayedMatch } = this.state;
    if (!!displayedMatch.synergies) {
      let keyIndexes = Object.keys(displayedMatch.synergies);
      if (keyIndexes.length === 0) { return []; }
      return Object.values(displayedMatch.synergies[keyIndexes]);
    } else { return []; }
  };

  readyMatchEmailSent = async (userid, toName, toUserid, fromCompanyName) => {
    try {
      // userLogins >>> check userId >>> email
      const userLoginRef = await myFirebase.firestore().collection("userLogins").where("userid", "==", userid).get();
      if (!userLoginRef.empty) {
        const toEmail = userLoginRef.docs[0].data().email;
        try {
          const checkShouldEmail = myFirebase.functions().httpsCallable("checkShouldEmail")
          let shouldSendEmailBoolean = await checkShouldEmail(toUserid)
          console.log(shouldSendEmailBoolean)
          if (shouldSendEmailBoolean) {
            //sendEmailMatch(toName, toEmail, fromCompanyName)
            return
          }
        } catch (error) { console.log(error) }
      }
    } catch (error) { console.log(error) }
  };

  emailMatchedCompany = (toCompanyName) => {
    const fromCompanyName = this.props.reduxState.myCompany.companyName;
    const toCompanyAdmins = toCompanyName.team.admins;
    const toCompanyMembers = toCompanyName.team.members;
    if (toCompanyAdmins.length > 0) {
      toCompanyAdmins.forEach((eachAdmin) => { // Searches through admins for people to send email to
        const userid = eachAdmin.userid;
        const toName = eachAdmin.name; // have been retrieved from the DB in perLoadFunction.js
        this.readyMatchEmailSent(userid, toName, userid, fromCompanyName);
      });
    }
    if (toCompanyMembers.length > 0) {
      toCompanyMembers.forEach((eachMember) => { // Searches through members for people to send email to
        const userid = eachMember.userid;
        const toName = eachMember.name; // have been retrieved from the DB in perLoadFunction.js
        this.readyMatchEmailSent(userid, toName, userid, fromCompanyName);
      });
    }
  };

  // Handles whent the like is pressed (the heart)
  handleLike = async (companyLiked, valueLiked, score) => {
    this.setState({ likedCompany: companyLiked, likedScore: score });
    // send notification email to everyone in the company that is matched to
    //this.emailMatchedCompany(companyLiked);
  };

  // After the selected person in a potential chat is confirmed (creates chat room)
  matchSuccess = async (memberSetChatWith, score, likedCompany) => {
    //console.log("Setting up Match between...", this.props.currentUser.uid, memberSetChatWith.userid)
    //console.log(memberSetChatWith, score, likedCompany)
    this.setState({ likedCompany: likedCompany, likedScore: score });
    this.emailMatchedCompany(likedCompany);

    // If there is NO user attached to the company
    let memberList = [this.props.currentUser.uid];
    let dataToAdd = {
      chatCreated: new Date(),
      members: memberList,
      scoreSnapshot: score,
      waitingOnCompany: likedCompany.selfref,
      initiatedByUser: this.props.currentUser.uid,
    };
    let chatRoomRef = await myFirebase.firestore().collection("chats").add(dataToAdd);
    dataToAdd.selfid = chatRoomRef.id;
    dataToAdd.selfref = chatRoomRef;
    dataToAdd.messages = { aggregate: [] };
    dataToAdd.waitingOnCompany = likedCompany;
    dataToAdd.score = score;

    dataToAdd.members = [this.props.reduxState.myUserProfile];
    this.props.reduxState.myChats.chatRooms.push(dataToAdd);

    /*
    saveMatchData(
      this.props.reduxState.myCompany,
      likedCompany,
      this.state.displayedMatch,
      this.props.currentUser.uid,
      "liked"
    )
    */

    this.goNextMatch("liked"); // Goes next match because this match was confirmed good
  };

  // At later date, should reset the history but too risky to do now
  resetMatchHistory = async () => {
    console.log("Should be reseting match history...");
  };

  // For Logging matches for debugging (not used in production)
  matchDebugger = () => {
    console.log("=================");
    console.log(this.state.deck);
    console.log(this.state.displayedMatch);
    console.log(this.state.displayedMatch.selfid);
    //deck.map(obj => console.log(obj.companyData.companyName))
    console.log("=================");
  };

  render() {
    const {
      deck,
      displayedMatch,
      likedCompany,
      likedScore,
      reportTriggered,
      noMatchesTriggered,
      addingToDeck,
    } = this.state;

    //this.matchDebugger()

    let nextButtonDisabled = addingToDeck && deck.length === 0; // If we ran out of cards in deckand generating more already

    if (this.validDisplayedMatch()) {
      // This catches errors if there is sometime wrong with the card (also for going through all possible cards)
      if (displayedMatch.to_label === "startup") {
        // Display type (startup, investor, venture capital)
        return (
          <>
            {noMatchesTriggered ? ( // Conditional to trigger popup. Note that if this comp is rendered, it shows the popup
              <NoMatchesCard
                {...this.props} // Passes all the props of this parent
              />
            ) : (
              <OpenNetworkT1SingleLayout
                currentUser={this.props.currentUser}
                myCompany={this.props.reduxState.myCompany}
                displayedMatch={displayedMatch}
                likedCompany={likedCompany}
                likedScore={likedScore}
                nextButtonDisabled={nextButtonDisabled}
                getSingleIndustriesSynergies={this.getSingleIndustriesSynergies}
                getSingleSynergies={this.getSingleSynergies}
                handleLike={this.handleLike}
                reportTriggered={reportTriggered}
                changeReportView={(val) =>
                  this.setState({ reportTriggered: val })
                }
                changeTeamView={() => this.setState({ likedCompany: null })}
                goNextMatch={this.goNextMatch}
                matchSuccess={this.matchSuccess}
                redirectToChat={() => this.props.history.push('/tier1/chat')}
              />
            )}
          </>
        );
      } else {
        return (
          <>
            <OpenNetworkT1ManyLayout
              currentUser={this.props.currentUser}
              displayedMatch={displayedMatch}
              likedCompany={likedCompany}
              likedScore={likedScore}
              nextButtonDisabled={nextButtonDisabled}
              reportTriggered={reportTriggered}
              changeReportView={(val) =>
                this.setState({ reportTriggered: val })
              }
              changeTeamView={() => this.setState({ likedCompany: null })}
              getSingleSynergies={this.getSingleSynergies}
              handleLike={this.handleLike}
              goNextMatch={this.goNextMatch}
              matchSuccess={this.matchSuccess}
            />
            {noMatchesTriggered && (
              <NoMatchesCondensedPopup
                {...this.props}
                closePopup={() => this.setState({ noMatchesTriggered: false })}
              />
            )}
          </>
        );
      }
    }
    if (deck.length === 0) {
      // If you went through all the companies in our open network
      this.resetMatchHistory();
      return (
        <div className="matches-went-through-all">
          <h4>You have already gone through everyone in our open network!</h4>
          <p>
            If you would like to reset your match history, please email us at{" "}
            <a href="mailto:george@coopsight.com">george@coopsight.com</a>
          </p>
        </div>
      );
    } else {
      return (
        <div>There was an error with loading matches? Maybe try again?</div>
      );
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnterOpenNetwork);
