//General Imports
import React from "react";
import myFirebase from "../../firebase";
import { Row, Col, Form, Button } from 'react-bootstrap';
import { LoadingFullPageIcon } from "../../Loading/LoadingPageIcon";
import { Eye, EyeOff } from 'react-feather'
import { checkPasswordValid } from "../../GeneralUse/CheckValidFunctions"
import ReCAPTCHA from "react-google-recaptcha";

/*
    Reset Password Page
    - Form to reset the password. Is specified from the firebase custom url w/ token
*/

class ResetPasswordPage extends React.Component {
    constructor() {
        super();
        this.state = {
            resetPasswordFormDisplayed: false,
            invalidActionCode: false,

            actionCode: null,

            passwordHidden: true,
            passwordHidden2: true,

            captchaVerified: false,

            updatedPassword: false,

            submitErrorMessage: "",
        };
    }

    getParameterByName = (name, url) => {
        if (!url) url = window.location.href;
        // eslint-disable-next-line no-useless-escape
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    async componentDidMount() {

        //var mode = this.getParameterByName('mode') // Gets The mode (purpose of the user)

        var actionCode = this.getParameterByName('oobCode') // Get the one-time code from the query parameter.

        try {
            await this.verifyPasswordResetCode(actionCode)
        } catch (_) {
            this.setState({ invalidActionCode: true })
        }
    }

    verifyPasswordResetCode = async (actionCode) => {
        await myFirebase.auth().verifyPasswordResetCode(actionCode)
            .then((_) => {
                // Display a "new password" form with the user's email address
                this.setState({ resetPasswordFormDisplayed: true, actionCode: actionCode })
            })
            .catch((_) => {
                // Invalid url
                this.setState({ invalidActionCode: true })
            })
    }

    handleNewPasswordReset = async (e) => {
        e.preventDefault()
        const { password, passwordConfirm } = e.target.elements;
        const { captchaVerified, actionCode } = this.state

        if (password.value !== passwordConfirm.value) {
            this.setState({ submitErrorMessage: "Passwords are the NOT the same!" })
            return
        }

        let checkPassword1 = checkPasswordValid(password.value)
        if (!checkPassword1.valid) {
            this.setState({ submitErrorMessage: checkPassword1.message })
            return
        }
        let checkPassword2 = checkPasswordValid(passwordConfirm.value)
        if (!checkPassword2.valid) {
            this.setState({ submitErrorMessage: checkPassword2.message })
            return
        }

        if (captchaVerified === false) {
            this.setState({ submitErrorMessage: "You need to complete the captcha!" })
            return
        }


        this.setState({ resetPasswordFormDisplayed: false })
        await myFirebase.auth().confirmPasswordReset(actionCode, password.value)
            .then(_ => {
                this.setState({ updatedPassword: true })
            }).catch(_ => {
                this.setState({ invalidActionCode: true })
            })

    }

    // Shows First Password
    showPassword = (e) => {
        e.preventDefault();
        if (this.state.passwordHidden) { this.setState({ passwordHidden: false }); }
        else { this.setState({ passwordHidden: true }); }
    }

    // Shows Second Confirm password
    showPassword2 = (e) => {
        e.preventDefault();
        if (this.state.passwordHidden2) { this.setState({ passwordHidden2: false }); }
        else { this.setState({ passwordHidden2: true }); }
    }

    // Handles Captcha Changes
    captchaChange = (response) => {
        if (!!response) { this.setState({ captchaVerified: true }) }
        this.setState({ submitErrorMessage: "" })
    }

    render() {
        const {
            resetPasswordFormDisplayed,
            invalidActionCode,
            passwordHidden,
            passwordHidden2,
            submitErrorMessage,
            updatedPassword
        } = this.state

        if (resetPasswordFormDisplayed) {
            return (
                <Row className="main-page-wrapper main-reset-password-page">
                    <Col className="main-inner-page">
                        <Row className="main-head">
                            <p>Confirm Password Reset</p>
                        </Row>
                        <Row className="rest-password-link-page-body">
                            <Col>
                                <Form onSubmit={this.handleNewPasswordReset}>
                                    <div className="label-input-wrapper">
                                        <label className="password-header">New Password</label>
                                        <div className="passwordWrap">
                                            <input
                                                className="baseline-input"
                                                name="password"
                                                type={passwordHidden ? "password" : "text"}
                                                placeholder="  Password"
                                                autoComplete="off"
                                                onChange={() => { submitErrorMessage.length !== 0 && this.setState({ submitErrorMessage: "" }) }}
                                            />
                                            <button className="showPass" onClick={this.showPassword}>
                                                {passwordHidden ? (
                                                    <div className="eye"><EyeOff /></div>
                                                ) : (<div className="eye"><Eye /></div>)}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="label-input-wrapper">
                                        <label className="password-header">Confirm New Password</label>
                                        <div className="passwordWrap">
                                            <input
                                                className="baseline-input"
                                                name="passwordConfirm"
                                                type={passwordHidden2 ? "password" : "text"}
                                                placeholder="  Password"
                                                autoComplete="off"
                                                onChange={() => { submitErrorMessage.length !== 0 && this.setState({ submitErrorMessage: "" }) }}
                                            />
                                            <button className="showPass" onClick={this.showPassword2}>
                                                {passwordHidden2 ? (
                                                    <div className="eye"><EyeOff /></div>
                                                ) : (<div className="eye"><Eye /></div>)}
                                            </button>
                                        </div>
                                    </div>

                                    <ReCAPTCHA
                                        className="captcha-sign-up"
                                        sitekey={process.env.REACT_APP_CAPTCHA_KEY}
                                        onChange={response => this.captchaChange(response)}
                                    />

                                    <div className="error-message-main">{submitErrorMessage}</div>

                                    <Button className="confirm-password-reset-link-bttn" type="submit">Reset Password</Button>
                                </Form>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )
        }
        if (invalidActionCode) {
            return (
                <Row className="main-page-wrapper main-reset-password-page">
                    <Col className="main-inner-page">
                        <Row className="main-head">
                            <p>Invalid Response</p>
                        </Row>
                        <Row className="reset-page-error-body">
                            <Col>
                                <p>This request was denied. This may be a result of the session timing out. Please request another reset link or go back to the login page.</p>
                                <div className="button-wrapper-error-reset">
                                    <Button onClick={() => this.props.history.push('/forget-password')}>Forgot Password</Button>
                                    <Button onClick={() => this.props.history.push('/login')}>Back to Login</Button>
                                </div>

                            </Col>
                        </Row>
                    </Col>
                </Row>
            )
        }
        if (updatedPassword) {
            return (
                <Row className="main-page-wrapper main-reset-password-page">
                    <Col className="main-inner-page">
                        <Row className="main-head">
                            <p>Password Successfully Reset</p>
                        </Row>
                        <Row className="reset-page-error-body">
                            <Col>
                                <p>Congratulations! Your password was successfully updated! Please Return to the login page to return to matching!</p>
                                <div className="button-wrapper-error-reset">
                                    <Button onClick={() => this.props.history.push('/login')}>Back to Login</Button>
                                </div>

                            </Col>
                        </Row>
                    </Col>
                </Row>
            )
        }
        return (
            <LoadingFullPageIcon />
        )
    }
}

export default ResetPasswordPage;
