// General Use
import React from "react"
import './Design/App.scss';
import { BrowserRouter, Switch } from "react-router-dom"
import AuthProvider from "./Auth/Authentication"

// For Payments
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

// Main
import MainRoute from "./Auth/MainRoute" // If not logged in
import SignUpPage from "./Scenes/Main/SignUpPage"
import LoginPage from "./Scenes/Main/LoginPage"
import IncorrectLoginPage from "./Scenes/Main/IncorrectLoginPage"
import ForgetPasswordPage from "./Scenes/Main/ForgetPasswordPage"
import ResetPasswordPage from "./Scenes/Main/ResetPasswordPage"

import PageNotFound from "./Scenes/Main/PageNotFound";

// Set Up / Guide
import GuideRoute from "./Auth/GuideRoute" // If logged in, but not apart of company (not tier user)
import ProfileSetupMainPage from "./Scenes/Guide/ProfileSetupMainPage"
import CompanySearchPage from "./Scenes/Guide/CompanySearchPage"
import CompanySetupMainPage from "./Scenes/Guide/CompanySetupMainPage"
import ProfileCompletePage from "./Scenes/Guide/ProfileSetup/ProfileCompletePage"

// Pro (T1) Pages
import PrivateRouteTier1 from "./Auth/PrivateRouteTier1"
import Tier1MatchingPage from "./Scenes/Tier1/Tier1MatchingPage"
import Tier1ProfilePage from "./Scenes/Tier1/Tier1ProfilePage"
import Tier1SettingsPage from "./Scenes/Tier1/Tier1SettingsPage"
import Tier1BalancePage from "./Scenes/Tier1/Tier1BalancePage"
import Tier1ChatPage from "./Scenes/Tier1/Tier1ChatPage"

// For Payment 
import PrivateRoutePayment from "./Auth/PrivateRoutePayment"
import PaymentPage from "./Scenes/Payment/PaymentPage"

//==============================================//
//       Coopsight Software (Open Network)      //
//                Version 1.0.4                 //
//                                              //
//       Made with <3 by the Coopsight Team     //
//           "Let's bag this baguette"          //
//==============================================//

const App = () => {
  console.warn = console.error = () => { }; // Stops all yellow warning errors
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY_LIVE) // can also switch LIVE -> TEST for stripe testing
  return (
    <AuthProvider>
      <Elements stripe={stripePromise}>
        <BrowserRouter>
          <Switch>
            <MainRoute exact path="/" component={SignUpPage} />
            <MainRoute exact path="/sign-up" component={SignUpPage} />
            <MainRoute exact path="/login" component={LoginPage} />
            <MainRoute exact path="/incorrect-login" component={IncorrectLoginPage} />
            <MainRoute exact path="/forget-password" component={ForgetPasswordPage} />
            <MainRoute path="/reset-password-form" component={ResetPasswordPage} />

            <GuideRoute exact path="/guide/profile-set-up" component={ProfileSetupMainPage} />
            <GuideRoute exact path="/guide/profile-complete" component={ProfileCompletePage} />
            <GuideRoute exact path="/guide/create-company" component={CompanySetupMainPage} />
            <GuideRoute exact path="/guide/company-search" component={CompanySearchPage} />

            <PrivateRouteTier1 exact path="/tier1/matching" component={Tier1MatchingPage} />
            <PrivateRouteTier1 exact path="/tier1/profile" component={Tier1ProfilePage} />
            <PrivateRouteTier1 exact path="/tier1/settings" component={Tier1SettingsPage} />
            <PrivateRouteTier1 exact path="/tier1/balance" component={Tier1BalancePage} />
            <PrivateRouteTier1 exact path="/tier1/chat" component={Tier1ChatPage} />

            <PrivateRoutePayment exact path="/payment/main" component={PaymentPage} />

            <MainRoute exact path="*" component={PageNotFound} />
          </Switch>
        </BrowserRouter>
      </Elements>
    </AuthProvider>
  )
}

export default App;
