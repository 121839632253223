// General Variables to be used
import { capitalizeName } from "./FunctionBucket"

export const defaultProfilePicture = "https://firebasestorage.googleapis.com/v0/b/coopsightstaging.appspot.com/o/default%2Fdpp-corporation-rocketship.png?alt=media&token=5bdb4be5-3f4d-4305-bf83-85c7277bd8f0"

export const t2ChildTypeList = [
    { value: "Accelerators", label: "Accelerators" },
    { value: "Alumni", label: "Alumni" },
    { value: "Attendees", label: "Attendees" },
    { value: "Corporate Partners", label: "Corporate Partners" },
    { value: "Corporations", label: "Corporations" },
    { value: "Dealflow", label: "Dealflow" },
    { value: "Departments", label: "Departments" },
    { value: "Events", label: "Events" },
    { value: "Funds", label: "Funds" },
    { value: "Limited Partners", label: "Limited Partners" },
    { value: "Managing Companies", label: "Managing Companies" },
    { value: "Projects", label: "Projects" },
    { value: "Portfolio Companies", label: "Portfolio Companies" },
    { value: "Sponsors", label: "Sponsors" },
    { value: "Startups", label: "Startups" },
    { value: "Subsidaries", label: "Subsidaries" },
    { value: "Teams", label: "Teams" },
]

export const jobTitlesList = [
    { value: "Business Operations", label: "Business Operation" },
    { value: "Business/Corp Development", label: "Business/Corp Development" },
    { value: "CXO", label: "CXO" },
    { value: "Developer", label: "Developer" },
    { value: "GP/Principal", label: "GP/Principal" },
    { value: "Investment Manager/Analyst", label: "Investment Manager/Analyst" },
    { value: "Marketing/Sales", label: "Marketing/Sales" },
    { value: "Non-Investment Role", label: "Non-Investment Role" },
]

export const preDefinedInterestsList = [
    "Advertising, Content, & Marketing",
    "Autotech",
    "Consumer Goods",
    "Design",
    "Ecommerce",
    "Education",
    "Enterprise Software Solutions",
    "Environment, Energy, Clean Tech",
    "Fintech",
    "Legal & Consultancy",
    "HR & Recruitment",
    "AR",
    "VR",
    "Medtech & Pharma",
    "Social Good",
    "Security",
    "Social Media",
    "Networking",
    "Telecommunications",
    "Travel",
    "Cars & Mobility",
    "Lifestyle & Fashion",
    "AI & Macine Learning",
    "Event Management",
    "Finance",
    "Politics & Government",
    "IOT",
    "Sports, Fitness, & Wellness",
    "Big Data",
    "SaaS",
    "Utilities",
    "Communications",
    "Gaming",
    "Analytics",
    "Retail",
    "Hardware",
    "Mobile Development",
    "Entertainment & Media",
]

// Has a list of percentages that can be used for updating the classNames
// runs on multiples of 2
export const percentStyles = [
    "p0", "p2", "p4", "p6", "p8",
    "p10", "p12", "p14", "p16", "p18",
    "p20", "p22", "p24", "p26", "p28",
    "p30", "p32", "p34", "p36", "p38",
    "p40", "p42", "p44", "p46", "p48",
    "p50", "p52", "p54", "p56", "p58",
    "p60", "p62", "p64", "p66", "p68",
    "p70", "p72", "p74", "p76", "p78",
    "p80", "p82", "p84", "p86", "p88",
    "p90", "p92", "p94", "p96", "p98",
    "p100",
]

// Industry List

/*
export const gridIndustryListRaw = [ // OLD LIST (UPDATED 5/2/21)
    'carsharing',
    'rideshare',
    'last mile mobility',
    'biotech',
    'autonomous mobility',
    'green energy',
    'tax services',
    'travel booking',
    'apartment booking services',
    'travel insurance',
    'health devices',
    'pharmaceutical sales',
    'telemedicine / medicine',
    'diet applications',
    'personal hygiene & skincare',
    'sports pay per view',
    'video services',
    'movie/tv streaming',
    'music streaming apps',
    'video communications',
    'chat applications',
    'team tools',
    'mapping services',
    'e-commerce applications',
    'grocery orders',
    'food delivery',
    'real estate sales',
    'payment applications',
    'deposits / money savings',
    'investments',
    'event tickets',
    'house utilities',
    'electricity',
    'smart home',
    'game streaming',
    'video games',
    'VR & AR',
    'phone communications',
    'marketing applications',
    'hiring apps',
    'cloud applications',
    'educational applications',
    'document management',
    'mail services',
    'voice assistant',
    'cryptocurrency',
    'dating apps',
    'manufacturing',
    '3d-printing',
    'restaurant reservation',
    'parking',
    'wearable devices',
    'sports training',
    'outdoor activities',
    'reviews',
    'pet care',
    'pet food',
    'office space',
    'security surveillance',
    'cybersecurity',
    'compliance',
    'legal services',
    'internet connection',
    'computer hardware',
    'credit scores',
    'hotel services',
    'health insurance',
    'house insurance',
    'car insurance',
    'social media',
    'child care',
    'IoT devices',
    'news',
    'clothing',
    'data analytics applications',
    'file sharing',
    'professional lessons',
    'freight transportation',
    'delivery',
    'home furnishings',
    'financial loans',
    'search',
    'calendar applications',
    'events planning',
    'charity services',
    'emergency services',
    'application development',
    'car maintenance',
    'customer relationship management',
    'website creations',
    'productivity tools',
    'smartphones',
    'fitness applications',
    'agricultural services',
]
*/

export const gridIndustryListRaw = [ // NEW LIST (UPDATED 5/2/21)
    "carsharing",
    "rideshare",
    "last mile mobility",
    "biotech",
    "autonomous mobility",
    "green energy",
    "tax services",
    "travel booking",
    "apartment booking services",
    "travel insurance",
    "health devices",
    "pharmaceutical sales",
    "telemedicine medicine",
    "diet applications",
    "personal hygiene & skincare",
    "sports pay per view",
    "video services",
    "movie tv streaming",
    "music streaming apps",
    "video communications",
    "chat applications",
    "team tools",
    "mapping services",
    "e-commerce applications",
    "grocery orders",
    "food delivery",
    "real estate sales",
    "payment applications",
    "deposits money savings",
    "investments",
    "event tickets",
    "house utilities",
    "electricity",
    "smart home",
    "game streaming",
    "video games",
    "VR & AR",
    "phone communications",
    "marketing applications",
    "hiring apps",
    "cloud applications",
    "educational applications",
    "document management",
    "mail services",
    "voice assistant",
    "cryptocurrency",
    "dating apps",
    "manufacturing",
    "3d-printing",
    "restaurant reservation",
    "parking",
    "wearable devices",
    "sports training",
    "outdoor activities",
    "reviews",
    "pet care",
    "pet food",
    "office space",
    "security surveillance",
    "cybersecurity",
    "compliance",
    "legal services",
    "internet connection",
    "computer hardware",
    "credit scores",
    "hotel services",
    "health insurance",
    "house insurance",
    "car insurance",
    "social media",
    "child care",
    "IoT devices",
    "news",
    "clothing",
    "data analytics applications",
    "file sharing",
    "professional lessons",
    "freight transportation",
    "delivery",
    "home furnishings",
    "financial loans",
    "search",
    "calendar applications",
    "events planning",
    "charity services",
    "emergency services",
    "application development",
    "car maintenance",
    "customer relationship management",
    "website creations",
    "productivity tools",
    "smartphones",
    "fitness applications",
    "agricultural services"
]


const gridIndustryListUnsorted = gridIndustryListRaw.map(eachIndustry => {
    return { label: capitalizeName(eachIndustry), value: eachIndustry }
})
export const gridIndustryList = gridIndustryListUnsorted.sort(function (a, b) { // Sorts list alphabetical
    var textA = a.label.toUpperCase();
    var textB = b.label.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
});