//General Imports
import React from "react";
import myFirebase from "../../../firebase";
import { LoadingSmallIcon } from "../../../Loading/LoadingPageIcon"
import { X, Check } from 'react-feather'
import {
  Button,
} from "react-bootstrap";

//Redux Stuff
import { connect } from "react-redux";

function mapStateToProps(state) { return { reduxState: state } }

/*
  Requests
  - Handles the request for accept/reject each person who wants to join
*/
class Requests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isUpdating: false,
      is_admin: null,
      myCompanyDoc: props.reduxState.myCompany.selfid,
      requester_infos: [],
      admin_requests: [],

      loadingRequests: true,
    };
  }

  async componentDidMount() {
    this.updateAdminStatus();
    let admin_requests = await this.getRequests()
    this.setState({ admin_requests, loadingRequests: false })
  }

  getRequests = async () => {
    let myRequestsSnapshot = await myFirebase.firestore().collection('joinRequests').where('toCompany', '==', this.state.myCompanyDoc).where('deciderid', '==', null).get()
    if (!myRequestsSnapshot.empty) {
      let admin_requests = []
      let requestPromises = []
      myRequestsSnapshot.forEach(async eachRequest => {
        let requestData = eachRequest.data()
        requestData.selfid = eachRequest.id
        requestData.selfref = eachRequest.ref
        requestPromises.push(this.getRequesterInfo(requestData))
        admin_requests.push(requestData)
      })
      return Promise.all(requestPromises).then(() => {
        return admin_requests
      })
    } else return []
  }

  userExists = (username, arr) => { // if user is admin
    return arr.some(function (el) {
      return el.userid === username;
    });
  };

  // after mounting check if the user is admin, update is_admin status
  updateAdminStatus = () => {
    const current_userid = this.props.currentUser.uid;
    const myTeamAdmins = this.props.reduxState.myTeam.admins;
    this.setState({ is_admin: this.userExists(current_userid, myTeamAdmins), })
  }

  // get all requesters' info from all requests
  getRequesterInfo = async (request) => {
    const requestingUserID = request.userid;
    try {
      const doc2Snapshot = await myFirebase.firestore().collection("userProfiles").where("userid", "==", requestingUserID).get();
      const docData2 = doc2Snapshot.docs[0];
      request.personRequestingInfo = docData2.data()
      return request
    } catch (error) {
      console.log("Error with getting companyData:", error);
    }
  };

  // click accept/reject button
  updateRequest = async (requestingUserID, requestingDoc, accept_flag) => {
    this.setState({ isUpdating: true })
    try {
      await requestingDoc.selfref.update({
        approved: accept_flag,
        deciderid: this.props.currentUser.uid,
        decisionTime: new Date(),
      });
      if (accept_flag) {
        let adminListUIDSPre = [...this.props.reduxState.myTeam.admins]
        let adminListUIDSPost = adminListUIDSPre.map(user => user.userid)
        adminListUIDSPost.push(requestingUserID)
        let myTeamSnapshot = await myFirebase.firestore().collection('teams').where('admins', 'array-contains', this.props.currentUser.uid).get()
        if (!myTeamSnapshot.empty) {
          await myTeamSnapshot.docs[0].ref.update({ admins: adminListUIDSPost })
        }
        let myTeamAdmins = this.props.reduxState.myTeam.admins
        requestingDoc.personRequestingInfo.companyDocid = this.state.myCompanyDoc
        myTeamAdmins.push(requestingDoc.personRequestingInfo)
        this.props.updateTeamManagementPage("admin", myTeamAdmins)
      }
      this.setState({
        admin_requests: this.state.admin_requests.filter((obj) => {
          return obj.userid !== requestingUserID;
        }), isUpdating: false
      })
    } catch (error) { console.log("Error with getting doc_to_update onReject:", error); this.setState({ isUpdating: false }) }
  }

  render() {
    const { is_admin, admin_requests, isUpdating, loadingRequests } = this.state

    if (!is_admin) {
      return (
          <h4>You are NOT an admin</h4>
      )
    } else {
      if (loadingRequests) {
        return (
          <div className="loading-requests">
            <LoadingSmallIcon />
          </div>
        )
      }
      if (admin_requests.length === 0) {
        return (
          <div className="no-pending-request">You Have No Pending Join Requests</div>
        )
      }
      return (
        <ul className="my-requests-display-table">
          {admin_requests.map((eachRequest, eachRequestIndex) => {
            let person = eachRequest.personRequestingInfo
            return (
              <li key={eachRequestIndex} className="each-request-member-current-wrapper">
                <div className="each-request-member-current-top">
                  <img
                    src={person.profilePicture.fileURL}
                    alt=""
                    className="profile-picture-image"
                  />
                  <p>{person.name}</p>
                </div>
                <div className="each-request-member-current-bottom">
                  <Button
                    className="request-member-action-bttn"
                    onClick={() => this.updateRequest(person.userid, eachRequest, false)}
                    disabled={isUpdating}
                  >
                    <X className="exit-icon" />
                  </Button>
                  <Button
                    className="request-member-action-bttn"
                    onClick={() => this.updateRequest(person.userid, eachRequest, true)}
                    disabled={isUpdating}
                  >
                    <Check className="check-icon" />
                  </Button>
                </div>
              </li>
            );
          })}
        </ul>
      );
    }

  }
}
// export default Requests;
export default connect(mapStateToProps)(Requests);