// General Use
import myFirebase from "../firebase";
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import emailjs from 'emailjs-com';
import { nanoid } from 'nanoid'

// Gets the userProfile, given a provided userid
export async function getUserProfile(userid) {
    try {
        //console.log("Getting user profile of ", userid)
        const userProfileSnapshot = await myFirebase.firestore().collection('userProfiles').where('userid', '==', userid).get()
        if (!userProfileSnapshot.empty) {
            const userProfile = userProfileSnapshot.docs[0]
            const userData = userProfile.data()
            //console.log(userData.profilePicture.fileURL)
            userData.selfid = userProfile.id
            userData.selfref = userProfile.ref
            userData.companyData = await getCompanyFromChat(userData.companyDocid)
            return userData
        }
        return {}
    } catch (error) { console.log(error); return {} }
}
export async function getCompanyFromChat(companyDocid) {
    let companyDataFull = {}
    try {
        let companyDocRefT1 = await myFirebase.firestore().collection('tier1Network').doc(companyDocid).get()
        if (companyDocRefT1.exists) {
            companyDataFull = companyDocRefT1.data()
            return companyDataFull
        }

        let companyDocRefT2 = await myFirebase.firestore().collection('tier2Network').doc(companyDocid).get()
        if (companyDocRefT2.exists) {
            companyDataFull = companyDocRefT2.data()
            return companyDataFull
        }
        return null
    } catch (error) { console.log(error); return {} }
}

// Converts a timestamp to nice or complicated type ="full", "day", "time"
export function convertTimestamp(timestamp) {
    var startTime = new Date(0);
    startTime.setUTCSeconds(timestamp.seconds);
    let deepTime = moment(startTime, moment.defaultFormat).format('MMMM Do')
    if (deepTime === "Invalid date") {
        return { full: "", day: "", time: "" }
    }
    let momentTime = moment(startTime, moment.defaultFormat).format('h:mm a')
    return {
        full: deepTime + ", " + momentTime,
        day: deepTime,
        time: momentTime
    }
}

// Creates and Returns a UUID
//Generates UUID for key
export function generateUUID() {
    return uuidv4()
}

// Get the Appropiate Name for AccountType (we use t1, t2, t3 for legacy and consistency cus names can always change)
export function getOfficialAccountName(accountType) {
    switch (accountType) {
        case "tier1":
            return "Pro"
        case "tier1Network":
            return "Pro"
        case "tier2":
            return "Premier"
        case "tier2Network":
            return "Premier"
        case "tier3":
            return "Enterprise"
        case "tier3Network":
            return "Enterprise"
        case "guide":
            return "Default"
        default:
            console.log("Error with getting official account name: ", accountType)
            return accountType
    }
}

// Returns how the timestamps in chats are formatted
export function getChatTimestamp() {
    let chatTimestamp = Math.floor((new Date()).getTime() / 1000)
    var startTime = new Date(0)
    startTime.setUTCSeconds(chatTimestamp)
    return startTime
}

// Returns the current month of today
export function getTodayMonth() {
    var check = moment(new Date().getTime());
    return check.format('MMMM Do')
}

// Sends an email to join the network given the parameters
export function sendEmailLink(toName, toEmail, fromCompanyName) {
    const apiEmailUser = process.env.REACT_APP_EMAIL_API
    const template = process.env.REACT_APP_EMAIL_TEMPLATE
    const params = {
        "to_name": toName,
        "to_email": toEmail,
        "from_company_name": fromCompanyName
    }
    return emailjs.send('gmail', template, params, apiEmailUser)
}


export function sendEmailMatch(toName, toEmail, fromCompany) {
    const apiEmailUser = process.env.REACT_APP_EMAIL_API
    const template = process.env.REACT_APP_EMAIL_MATCH_TEMPLATE
    const params = {
        "to_name": toName,
        "to_email": toEmail,
        "from_company_name": fromCompany
    }
    return emailjs.send('gmail', template, params, apiEmailUser)
}

// Score is usually a long decimal, so this cleans it up
export function cleanScore(score) { return Math.round(score * 100) }

// Create the referral code (put here incase want to change in future)
export function createCompanyRefKey() { return nanoid(10) }

// Lowercase is in database for filtering, this func prettifies it
export function capitalizeName(name) {
    if (!name) { return name }
    return name.replace(/\b(\w)/g, s => s.toUpperCase());
}

// Sorts Through Chat Messages Based on Parameter
export function sortChatMessage(chatRooms, filterByValue, myUserid) {
    let chatRoomsCopy = [...chatRooms]

    if (
        filterByValue === "Startups" ||
        filterByValue === "Corporations" ||
        filterByValue === "Investors"
    ) {
        chatRoomsCopy = chatRoomsCopy.filter(eachChatRoom => {
            let otherMember = null
            eachChatRoom.members.forEach((eachMember) => {
                if (eachMember.userid !== myUserid) {
                    otherMember = eachMember
                }
            })
            if (!!otherMember) {
                return otherMember.companyType === filterByValue.toLowerCase().substring(0, filterByValue.length - 1)
            } else {
                return eachChatRoom.waitingOnCompany.type === filterByValue.toLowerCase().substring(0, filterByValue.length - 1)
            }

        })
        return sortChatMessage(chatRoomsCopy, "Recent", myUserid)
    } else if (filterByValue === "Best Score") {
        chatRoomsCopy.sort((a, b) => { return b.score - a.score })
        return chatRoomsCopy
    } else if (filterByValue === "Recent") {
        chatRoomsCopy.sort((a, b) => {
            let lastMessageA = a.messages.aggregate[a.messages.aggregate.length - 1]
            let lastMessageB = b.messages.aggregate[b.messages.aggregate.length - 1]
            if (!lastMessageA) { return !lastMessageB - !lastMessageA }
            if (!lastMessageB) { return !lastMessageB - !lastMessageA }
            return lastMessageB.timestampSent - lastMessageA.timestampSent
        })
        return chatRoomsCopy
    }
}

// Finds Account Type of Userid
export async function getCompanyTypeByDocid(companyDocid) {
    try {
        let teamDocSnapshot = await myFirebase.firestore().collection("teams").where('companyDocid', '==', companyDocid).get()
        if (!teamDocSnapshot.empty) {
            const teamDoc = teamDocSnapshot.docs[0].data()
            let splitPath = teamDoc.companyRef.path.split('/')
            if (splitPath[0] === "tier1Network") {
                return "startup"
            }
            if (splitPath[0] === "tier2Network") {
                let tier2NetworkDoc = await teamDoc.companyRef.get()
                if (!tier2NetworkDoc.empty) {
                    return tier2NetworkDoc.data().type
                }
            }
            return null
        }
    } catch (error) {
        console.log(error)
    }
}

