// General Use
import React from "react";
import { Button } from "react-bootstrap";
import GuideToolTips from "../../Tier1/Components/GuideToolTips.js";
import { File, X, Upload } from "react-feather";
import Dropzone from "react-dropzone";
import { fileConstraints } from "../../../GeneralUse/CheckValidFunctions";

// Uploading Files
const CompanySetupFiles = (props) => {
    if (props.selectedUserType !== "startup") {
        props.goNext();
    }
    return (
        <section className="guide-company-process-6-wrapper">
            <p className="guide-profile-process-header">
                Upload your files (will not be shared publicly)
                <GuideToolTips
                    description="You can upload any file - a marketing presentation, an investor pitch deck, a whitepaper of your product, or promotional material - that covers the operations of your company in detail. None of the files are shared with the Open Platform members, and are solely used to improve our matching algorithm."
                    placement="right"
                />
            </p>
            <div className="error-message-main">{props.errorMessage}</div>
            <ul className="company-files-initial-upload">
                {props.form.files.map((eachFile, index) => {
                    return (
                        <li key={index} className="current-selected-file">
                            <h5 className="file-name">{eachFile.name}</h5>
                            <File className="file-icon-center" />
                            <Button
                                className="remove-file-bttn"
                                onClick={() => props.removeFileFromForm(index)}
                            >
                                <X className="remove-file-icon" />
                            </Button>
                        </li>
                    );
                })}
                {props.form.files.length < fileConstraints.maxFilesPerCompany && (
                    <li className="company-file-add-new">
                        <div className="upload-new-company-file-wrapper">
                            <Dropzone
                                onDrop={(files, rejectedFiles) => {
                                    const newFile = files[0];
                                    props.addFileToForm(newFile);
                                }}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <section>
                                        <div {...getRootProps()}>
                                            <input
                                                className="upload-new-company-file-input"
                                                {...getInputProps()}
                                            />
                                            <label for="file">
                                                <Upload />
                                            </label>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                        </div>
                    </li>
                )}
            </ul>
            <div className="profile-buttons">
                <Button className="buttons-guide-back" onClick={() => props.goBack()}>
                    Back
                </Button>
                <Button
                    className="buttons-guide-continue"
                    disabled={
                        (props.form.files.length === 0 && fileConstraints.fileRequired) ||
                        props.errorMessage.length > 0
                    }
                    onClick={() => props.goNext("file")}
                >
                    Continue
                </Button>
            </div>
        </section>
    );
};
export default CompanySetupFiles