//General Imports
import React, { useState } from 'react';
import CheckoutForm from "./CheckoutForm"

import TermsOfUseBottomBar from "../Tier1/Components/TermsOfUseBottomBar"

import { ArrowLeft } from 'react-feather'

import { useSelector } from 'react-redux'

import {
    Row,
    Col,
    Button,
} from "react-bootstrap"

import { useDispatch } from 'react-redux'
import {
    changeMyUserLogin,
} from '../../Redux/actions';


const PaymentPage = (props) => {
    const dispatch = useDispatch()
    const { myUserLogin } = useSelector(state => ({
        myUserLogin: state.myUserLogin,
    }))

    const [parentLoading, triggerParentLoading] = useState(false)
    const [paymentSuccess, setPaymentSuccess] = useState(false)
    const [selectedPaymentPlan, setselectedPaymentPlan] = useState({ matches: null, price: null, identity: null })

    function setReceiptValue(receipt) {
        myUserLogin.matches.currentBalance = receipt.value
        dispatch(changeMyUserLogin(myUserLogin))
    }
    if (props.accountType === "tier1") {
        if (paymentSuccess) {
            return (
                <Row className="t1-payment-page">
                    <Col className="t1-inner-page">
                        <Row className="t1py-top-go-back">
                            <Col>
                                <Button className="payment-go-back-bttn" onClick={() => props.history.goBack()}><ArrowLeft /></Button>
                            </Col>
                        </Row>
                        <Row className="payment-successful-message">
                            <Col>
                                <h4>Payment Successful!</h4>
                                <p className="payment-success-message">
                                    Thank you for your purchase of {selectedPaymentPlan.matches} matches! The Coopsight team appreciates your continued support!
                                </p>
                                <p className="payment-success-email-support">
                                    Trouble with payment? Email us at <a href="mailto:george@coopsight.com">george@coopsight.com</a>
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )
        } else {
            return (
                <Row className="t1-payment-page">
                    <Col className="t1-inner-page">
                        <Row className="t1py-top-go-back">
                            <Col>
                                <Button className="payment-go-back-bttn" onClick={() => props.history.goBack()}><ArrowLeft /></Button>
                            </Col>
                        </Row>

                        <Row className="t1py-selected-plan">
                            {selectedPaymentPlan.matches === null ? (
                                <Col>
                                    <h4 className="select-payment-plan-none">Please Select a Payment Plan</h4>
                                </Col>
                            ) : (
                                    <Col>
                                        <h2 className="payment-main-selection-matches">{selectedPaymentPlan.matches}</h2>
                                        <h6>Matches</h6>
                                        <h4 className="payment-main-selection-price">${selectedPaymentPlan.price}</h4>
                                    </Col>
                                )}
                        </Row>

                        <Row className="t1py-select-plan">
                            <Col>
                                <Button
                                    disabled={parentLoading}
                                    onClick={() => setselectedPaymentPlan({ matches: 20, price: 17, identity: 1 })}
                                    className={selectedPaymentPlan.identity === 1 ? "plan-button-selected plan-button-selection" : "plan-button-selection"}>
                                    $17 for 20 matches</Button>
                                <Button
                                    disabled={parentLoading}
                                    onClick={() => setselectedPaymentPlan({ matches: 50, price: 25, identity: 2 })}
                                    className={selectedPaymentPlan.identity === 2 ? "plan-button-selected plan-button-selection" : "plan-button-selection"}>
                                    $25 for 50 matches</Button>
                                <Button
                                    disabled={parentLoading}
                                    onClick={() => setselectedPaymentPlan({ matches: 100, price: 34, identity: 3 })}
                                    className={selectedPaymentPlan.identity === 3 ? "plan-button-selected plan-button-selection" : "plan-button-selection"}>
                                    $34 for 100 matches</Button>
                            </Col>
                        </Row>

                        <Row className="t1py-check-out-form">
                            <CheckoutForm {...props} selectedPaymentPlan={selectedPaymentPlan} triggerParentLoading={triggerParentLoading} setPaymentSuccess={setPaymentSuccess} setReceiptValue={setReceiptValue} />
                        </Row>
                        <TermsOfUseBottomBar />

                    </Col>
                </Row>
            )
        }
    } else if (props.accountType === "tier2") {
        if (paymentSuccess) {
            return (
                <Row>
                    <Col>
                        <h1>Payment has been succcessful!</h1>
                    </Col>
                </Row>
            )
        } else {
            return (
                <Row className="payment-main-wrapper">
                    <Col>
                        <Row>
                            <Col><button className="payment-go-back-bttn" onClick={() => props.history.goBack()}><ArrowLeft />Go Back</button></Col>
                        </Row>
                        <Row>
                            <Col> {/* Main Left Side */}
                                <div className="payment-options-t2-wrapper">
                                    <div className="payment-options-t2-top">
                                        {selectedPaymentPlan.matches === null ? (
                                            <div>
                                                <h4 className="select-payment-plan-none">Please Select a Payment Plan</h4>
                                            </div>
                                        ) : (
                                                <div>
                                                    <h2 className="payment-main-selection-matches">{selectedPaymentPlan.matches}</h2>
                                                    <h6>Matches</h6>
                                                    <h4 className="payment-main-selection-price">${selectedPaymentPlan.price}</h4>
                                                </div>
                                            )}
                                    </div>
                                    <div className="payment-options-t2-bottom">
                                        <button disabled={selectedPaymentPlan.identity === 4} onClick={() => setselectedPaymentPlan({ matches: 100, price: 90, identity: 4 })}>$90 for 100 matches</button>
                                        <button disabled={selectedPaymentPlan.identity === 5} onClick={() => setselectedPaymentPlan({ matches: 200, price: 120, identity: 5 })}>$120 for 200 matches</button>
                                        <button disabled={selectedPaymentPlan.identity === 6} onClick={() => setselectedPaymentPlan({ matches: 500, price: 175, identity: 6 })}>$175 for 500 matches</button>
                                    </div>
                                </div>
                            </Col>
                        </Row><Row>
                            <Col> {/* Main Right Side (Checkout) */}
                                <CheckoutForm {...props} triggerParentLoading={triggerParentLoading} selectedPaymentPlan={selectedPaymentPlan} setPaymentSuccess={setPaymentSuccess} />
                            </Col>
                        </Row>
                        <TermsOfUseBottomBar />
                    </Col>
                </Row>
            )
        }
    } else {
        return (
            <Row>
                <Col></Col>
            </Row>
        )
    }
}
export default PaymentPage
