// General Use
import React from 'react'
import { Row, Col } from 'react-bootstrap';
import timelessPicture from "../DesignAssets/Time.png"

// When the match to is a single company
export const NoMatchesCard = (props) => {
    return (
        <div id="con-no-matches-remaining">
            <div className='card_inner'>
                <img src={timelessPicture} alt="" className="nm-image" />
                <div className="nm-wrapper">
                    <h1 className="yikes">UH OH!</h1>
                    <h5 className="ran-out-matches-sub-title">You have ran out of matches</h5>
                    <p className="dont-worry-zero-matches">But don't worry! You will get another 5 matches at the start of every week! If you want to match more now, buy more matches below!</p>
                    <button className="to-payments-from-non-matches" onClick={() => { props.history.push('/payment/main') }}>Continue to Payments</button>
                </div>
            </div>
        </div>
    )
}