//General Imports
import React from 'react';
import { Col, Button, Badge } from 'react-bootstrap';
import { convertTimestamp, getTodayMonth, capitalizeName } from "../../../GeneralUse/FunctionBucket"
import { firebaseAnalytics } from "../../../firebase";

/*
    Chat Messages
    - Displays the chat messages for each person in the t1 (the list of people)
*/
const ChatMessages = (props) => {
    function renderLastMessage(lastMessage) {
        if (!lastMessage) {
            return (<p className="lst-msg-pre-list">Say Hello!</p>)
        }

        let today = getTodayMonth()
        let lastMsgTimestampFull = convertTimestamp(lastMessage.timestampSent)
        let timestampDisplayed = today === lastMsgTimestampFull.day ? lastMsgTimestampFull.time : lastMsgTimestampFull.day

        switch (lastMessage.type) {
            case "text":
                return (
                    <p className="lst-msg-is-exist">
                        <span className="timestamp-sent-mini-last-msg">{timestampDisplayed}: </span>{lastMessage.message}
                    </p>
                )
            case "file":
                return (
                    <p className="lst-msg-is-exist">
                        <span className="timestamp-sent-mini-last-msg">{timestampDisplayed}: </span>Files
                    </p>
                )
            case "pitchdeck":
                return (
                    <p className="lst-msg-is-exist">
                        <span className="timestamp-sent-mini-last-msg">{timestampDisplayed}: </span>Pitchdeck
                    </p>
                )
            default:
                return (
                    <p className="lst-msg-pre-list">Say Hello!</p>
                )
        }
    }

    function displayNotificationCount(count) {
        if (count === 0) {
            return <></>;
        } else if (count < 100) {
            return count;
        } else if (count > 100) {
            return '99+';
        } else {
            return <></>
        }
    }

    return (
        <Col className="chat-messages-t1-full">
            <ul className="full-chat-list-t1-full">
                {props.chatRooms.map((eachChannel, index) => {
                    const otherMembers = eachChannel.members.filter(eachMember => eachMember.userid !== props.userid)
                    const lastMessage = eachChannel.messages.aggregate[eachChannel.messages.aggregate.length - 1]

                    if (otherMembers.length === 0) {
                        if (eachChannel.waitingOnCompany.team.admins.length === 0) {
                            return (
                                <li className="t1-chat-full-person" key={index}>
                                    <Button onClick={() => props.selectChatRoom(eachChannel)} className="t1-chat-inner-person-bttn-no">
                                        <div className="clst1-middle-chat">
                                            <h4>{capitalizeName(eachChannel.waitingOnCompany.companyName)}</h4>
                                            <p className="last-chat-msg-wrapper-mini">
                                                <span>This company is pending users...</span>
                                            </p>
                                        </div>
                                        <div className="cls-end-chat">{eachChannel.scoreSnapshot}</div>
                                    </Button>
                                </li>
                            )
                        }
                        if (typeof eachChannel.waitingOnCompany.team.admins[0] === 'object') {
                            return (
                                <li className="t1-chat-full-person" key={index}>
                                    <Button onClick={() => props.selectChatRoom(eachChannel)} className="t1-chat-inner-person-bttn-no">
                                        <div className="clst1-middle-chat">
                                            <h4>{capitalizeName(eachChannel.waitingOnCompany.companyName)}</h4>
                                            <p className="last-chat-msg-wrapper-mini">
                                                <span>Please select a user to start chatting with...</span>
                                            </p>
                                        </div>
                                        <div className="cls-end-chat">{eachChannel.scoreSnapshot}</div>
                                    </Button>
                                </li>
                            )
                        } else {
                            firebaseAnalytics.logEvent("noUserToCompanyChat", {
                                chatRoom: eachChannel,
                                chatRoomid: eachChannel.selfid
                            });
                            return (<div></div>)
                        }
                    }

                    return (
                        <li className="t1-chat-full-person" key={index}>
                            <Button onClick={() => props.selectChatRoom(eachChannel)} className="t1-chat-inner-person-bttn">
                                <div className="cls-start-chat">
                                    <img
                                        src={otherMembers[0].profilePicture.fileURL}
                                        alt=""
                                        className="chat-t1-profile-picture-img"
                                    />
                                    <Badge pill variant="danger" className='notification-badge'>
                                        {displayNotificationCount(eachChannel.unreadMessagesCount)}
                                    </Badge>
                                </div>
                                <div className="clst1-middle-chat">
                                    <h4>{!!otherMembers[0].companyData && capitalizeName(otherMembers[0].companyData.companyName)}</h4>
                                    <div className="last-chat-msg-wrapper-mini">{renderLastMessage(lastMessage)}</div>
                                </div>
                                <div className="cls-end-chat">{eachChannel.scoreSnapshot}</div>
                            </Button>
                        </li>
                    )
                })}
            </ul>
        </Col>
    )
}

export default ChatMessages
