/*
    All Redux Actions
    Actions bascially just wrap the value with a type then sends to reducers
*/

export const resetStates = () => {
    return {
        type: "RESET",
    }
}
export const changeMyUserLogin = (value) => {
    return {
        type: "CHANGE_USER_LOGIN",
        payload: value,
    }
}
export const changeMyUserProfile = (value) => {
    return {
        type: "CHANGE_USER_PROFILE",
        payload: value,
    }
}
export const changeMyTeam = (value) => {
    return {
        type: "CHANGE_TEAM",
        payload: value,
    }
}
export const changeMyCompany = (value) => {
    return {
        type: "CHANGE_COMPANY",
        payload: value,
    }
}
export const changeMyChats = (value) => {
    return {
        type: "CHANGE_CHATS",
        payload: value,
    }
}
export const changeMyEcosystem = (value) => {
    return {
        type: "CHANGE_ECOSYSTEM",
        payload: value,
    }
}
export const changeStatistics = (value) => {
    return {
        type: "CHANGE_STATISTICS",
        payload: value,
    }
}
export const changeMatching = (value) => {
    return {
        type: "CHANGE_MATCHING",
        payload: value,
    }
}
export const changeFeed = (value) => {
    return {
        type: "CHANGE_FEED",
        payload: value,
    }
}