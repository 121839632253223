// General Use
import React, { useState } from "react";
import { checkFileValid, checkValidSmall, checkValidBig, fileConstraints } from "../../GeneralUse/CheckValidFunctions";
import { updateProfileWithCompany } from "../../GeneralUse/UploadFirebase";
import {
  createCompanyFirestore,
  createTeamDocument,
  createNotificationDocument,
  createFirstChatMessage
} from "../../GeneralUse/CreateFirebase"
import { LoadingMediumIcon } from "../../Loading/LoadingPageIcon";
import { generateBackendMatches, generateKeywords, } from "../../GeneralUse/PrimeFunctions";
import { addTier1Role, addTier2Role, addTier3Role } from "../../Auth/AddRole";
import myFirebase from "../../firebase";
import { firebaseAnalytics } from "../../firebase";
import { Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { changeMyUserProfile, changeMyTeam, changeMyCompany, } from "../../Redux/actions";
import { devAcctID } from "../../GeneralUse/DevFunctionality.js";
import { capitalizeName } from "../../GeneralUse/FunctionBucket"

// Guide Steps
import CompanySetupInitial from "./CompanySetup/CompanySetupInitial"
import CompanySetupType from "./CompanySetup/CompanySetupType"
import CompanySetupName from "./CompanySetup/CompanySetupName"
import CompanySetupDescription from "./CompanySetup/CompanySetupDescription"
import CompanySetupLocation from "./CompanySetup/CompanySetupLocation"
import CompanySetupWebsite from "./CompanySetup/CompanySetupWebsite"
import CompanySetupFiles from "./CompanySetup/CompanySetupFiles"
import CompanySetupIndustry from "./CompanySetup/CompanySetupIndustry"

import Tutorial from "./Tutorial.js";

/*
  Create Company Page 
  * During the guide process, when you select the companies
*/
const CompanySetupMainPage = (props) => {
  const dispatch = useDispatch();

  // Tracks Analytics
  const [beginProcessTime] = useState(new Date())
  const [hasAccomplishedTask] = useState([
    { name: "initial", executed: false, timestamp: null },
    { name: "type", executed: false, timestamp: null },
    { name: "companyName", executed: false, timestamp: null },
    { name: "description", executed: false, timestamp: null },
    { name: "location", executed: false, timestamp: null },
    { name: "website", executed: false, timestamp: null },
    { name: "file", executed: false, timestamp: null },
    { name: "industry", executed: false, timestamp: null },
    { name: "end", executed: false, timestamp: null },
  ])

  // <----------------------------------------------> //

  // Main Form (To be submitted to firebase)
  const [form, updateForm] = useState({
    type: "",
    companyName: "",
    description: "",
    location: "",
    website: "",
    files: [],
  });
  const [errorMessage, setErrorMessage] = useState("");

  // Handles General Input
  function handleInputChange(e, key) {
    if (errorMessage.length > 0) {
      setErrorMessage("");
    } // Always clears the current errorMessage
    const editedForm = { ...form };
    if (key === "type") editedForm[key] = e;
    else editedForm[key] = e.target.value;
    updateForm(editedForm);
  }

  // Handle Location Change
  const [savedLocationFull, changeSavedLocationFull] = useState(null);
  function handleLocationChange(savedLocationFull) {
    const editedForm = { ...form };
    editedForm.location = savedLocationFull;
    changeSavedLocationFull(savedLocationFull);
    updateForm(editedForm);
  }

  // Handling Files
  function addFileToForm(newFile) {
    console.log("reached addFileToForm function");

    if (errorMessage.length > 0) { setErrorMessage("") }

    let inputCheck = checkFileValid(newFile, form.files);

    if (!inputCheck.valid) {
      setErrorMessage(inputCheck.message);
    } else {
      const editedForm = { ...form };
      editedForm.files.push({ name: newFile.name, fileBlob: newFile });
      updateForm(editedForm);
    }
  }

  function removeFileFromForm(fileIndex) {
    if (errorMessage.length > 0) { setErrorMessage("") }
    const editedForm = { ...form };
    editedForm.files.splice(fileIndex, 1);
    updateForm(editedForm);
  }

  // Handling Industry
  const [currentIndustry, setIndustry] = useState(null);

  // <----------------------------------------------> //

  // Main Flow and Guide Process
  const [currentPathPoint, updatePathPoint] = useState(0);

  function goBack(isInterest = false) {
    // Going to the PREVIOUS upload step in creating the company
    if (errorMessage.length > 0) setErrorMessage("");
    if (isInterest) { updatePathPoint((prev) => prev - 2) }
    else { updatePathPoint((prev) => prev - 1) }
  }

  function goNext(key = null) {
    // Going to the NEXT upload step in creating the company
    if (errorMessage.length > 0) setErrorMessage("");
    let inputCheck = { valid: true, message: "" };
    if (key === "companyName") {
      inputCheck = checkValidSmall(form[key]);
    } else if (key === "description") {
      inputCheck = checkValidBig(form[key]);
    } else if (key === "website") {
      //inputCheck = checkWebsiteValid(form[key]);
      inputCheck = { valid: true, message: "" } // currently, just disable any website checks locally
    } else if (key === "file") {
      if (form.files.length === 0) {
        if (fileConstraints.fileRequired) {
          inputCheck = {
            valid: false,
            message: "You must attach at least 1 file to your account!",
          };
        } else if (form.website.length === 0) {
          inputCheck = {
            valid: false,
            message:
              "You must attach at least 1 file to your account OR include a valid website!",
          };
        }
      }
    } else if (key === "industry") {
      if (currentIndustry === null) {
        inputCheck = { valid: false, message: "You must select an industry!" };
      }
    }

    if (!inputCheck.valid) {
      setErrorMessage(inputCheck.message);
    } else {
      let eventLogName = `setupCompany${capitalizeName(key)}`
      let taskIndex = null;
      let taskLogged = hasAccomplishedTask.find((task, index) => { taskIndex = index; return task.name === key; });
      if (taskLogged.executed === false) {
        let loggedTimestamp = new Date();
        taskLogged.executed = true;
        taskLogged.timestamp = loggedTimestamp;
        let lastTime = hasAccomplishedTask[taskIndex - 1] ? hasAccomplishedTask[taskIndex - 1].timestamp : beginProcessTime;
        let taskCompletionTime = ((lastTime.getTime() - loggedTimestamp.getTime()) / 1000) * -1;
        firebaseAnalytics.logEvent(eventLogName, {
          type: "setupCompany",
          user: props.currentUser.uid,
          timestampLogged: loggedTimestamp,
          taskCompletionTime: taskCompletionTime,
        });
      }
      updatePathPoint((prev) => prev + 1);
    }

    if (currentPathPoint + 2 === guideItinerary.length) {
      // If this is the last one
      let endTimestamp = new Date();
      let fullCompletionTime =
        ((endTimestamp.getTime() - hasAccomplishedTask[0].timestamp.getTime()) / 1000) * -1;
      firebaseAnalytics.logEvent("setupCompanyCompletion", {
        type: "setupCompany",
        user: props.currentUser.uid,
        timestampLogged: endTimestamp,
        fullCompletionTime: fullCompletionTime,
      });
      setStartTutorial(true);
      finishCompanyCreation();
    }
  }
  // Final Submittion of Form
  async function finishCompanyCreation() {
    console.log("Creating Company...");

    // Checks if it is a dev account
    const checkDevAccount = myFirebase.functions().httpsCallable("checkDevAccount");
    let isUnclaimedUpload = await checkDevAccount({ userid: props.currentUser.uid, });

    if (isUnclaimedUpload.data.isDevAccount) { console.log("...UPLOADING DEV ACCOUNT..."); }

    // Updating the User Role (attaches to custom claim)
    let accountTypeLocal = null;
    if (isUnclaimedUpload.data.isDevAccount === false) {
      // no need to attach role to userid
      accountTypeLocal = "tier1";
      await addTier1Role({ email: props.currentUser.email });
    }

    // Uploads Company
    const companyReduxValues = await createCompanyFirestore(form); // Efficiency Note. Can make the following 3 functions run at same time because only depend on input form companyUploaded
    dispatch(changeMyCompany(companyReduxValues));

    let cleanFiles = companyReduxValues.files.filesList.map((eachFile) => {
      return eachFile.fileURI;
    }); // Cleans the files for preparse before keywords

    // Updates User Profile
    let userProfileReduxValues = null;
    if (isUnclaimedUpload.data.isDevAccount === false) {
      await createNotificationDocument(props.currentUser.uid) // Creates Notifs
      await createFirstChatMessage(props.currentUser.uid) // First Chat With Coopsight
      userProfileReduxValues = await updateProfileWithCompany(props.currentUser.uid, companyReduxValues.selfid);
      dispatch(changeMyUserProfile(userProfileReduxValues));
    }

    // Uploads the Team
    const teamReduxValues = await createTeamDocument(props.currentUser.uid, companyReduxValues.selfref, userProfileReduxValues);
    dispatch(changeMyTeam(teamReduxValues));

    // Generating my Keywords
    await generateKeywords(
      companyReduxValues.selfid,
      form.type,
      "__self__",
      companyReduxValues.selfref.path,
      cleanFiles,
      [currentIndustry.value],
      form.website
    )
    setKeywordPromise(true)

    // Generating the Matching
    if (isUnclaimedUpload.data.isDevAccount === false) {
      await generateBackendMatches(
        props.currentUser.uid,
        "single",
        [companyReduxValues.selfid],
        [],
        ["startup"],
        [companyReduxValues.selfid]
      )
      setMatchPromise(true)
      firebaseAnalytics.setUserProperties({ // Logs Analytics
        accountType: accountTypeLocal,
        companyType: form.type,
      });
    }
    console.log("...Finished Creating Company: ");
  }
  // The Full Guide Steps (in order)
  const guideItinerary = [
    <CompanySetupInitial
      errorMessage={errorMessage}
      goBack={goBack}
      goNext={goNext}
    />,
    <CompanySetupType
      form={form}
      handleInputChange={handleInputChange}
      errorMessage={errorMessage}
      goBack={goBack}
      goNext={goNext}
    />,
    <CompanySetupName
      form={form}
      handleInputChange={handleInputChange}
      errorMessage={errorMessage}
      goBack={goBack}
      goNext={goNext}
    />,
    <CompanySetupDescription
      form={form}
      handleInputChange={handleInputChange}
      errorMessage={errorMessage}
      goBack={goBack}
      goNext={goNext}
    />,
    <CompanySetupLocation
      form={form}
      currentLocationFull={savedLocationFull}
      handleLocationChange={handleLocationChange}
      errorMessage={errorMessage}
      goBack={goBack}
      goNext={goNext}
    />,
    <CompanySetupWebsite
      form={form}
      handleInputChange={handleInputChange}
      errorMessage={errorMessage}
      goBack={goBack}
      goNext={goNext}
    />,
    <CompanySetupFiles
      form={form}
      updateForm={updateForm}
      selectedUserType={form.type}
      addFileToForm={addFileToForm}
      errorMessage={errorMessage}
      removeFileFromForm={removeFileFromForm}
      goBack={goBack}
      goNext={goNext}
    />,
    <CompanySetupIndustry
      currentIndustry={currentIndustry}
      setIndustry={setIndustry}
      errorMessage={errorMessage}
      goBack={goBack}
      goNext={goNext}
    />,
    <CompanySetupLoading history={props.history} />,
  ];

  // Tutorial Logic
  const [startTutorial, setStartTutorial] = useState(false)
  const [matchPromise, setMatchPromise] = useState(false)
  const [keywordPromise, setKeywordPromise] = useState(false);

  // Main Return Wrapper
  if (startTutorial) {
    return <Tutorial matchPromise={matchPromise} keywordPromise={keywordPromise} />
  } else {
    return (
      <Row className="guide-page create-company-guide">
        <Col className="guide-inner-page">
          {currentPathPoint !== 0 && ( // Wraps each step with main title and the guide path
            <>
              <Row>
                <div className="guide-page-header">
                  <p className="guide-header">Company Setup</p>
                </div>
              </Row>
              <Row className="guide-wrapper-path-top">
                <ul className="guide-path-full">
                  {guideItinerary.map((_, eachPointIndex) => {
                    return (
                      <li
                        key={eachPointIndex}
                        className={
                          eachPointIndex <= currentPathPoint
                            ? "point completed-point"
                            : "point other-point"
                        }
                      >
                        {eachPointIndex + 1}
                      </li>
                    );
                  })}
                </ul>
              </Row>
              <div className="center-points-guide-path" />
            </>
          )}

          {/* The Main Body of each step */}
          <Row>{guideItinerary[currentPathPoint]}</Row>
        </Col>
      </Row>
    );
  }
};

// Main Guide Steps (in order and correspond to above list)

// Uploading Website

// Final Loading of company
const CompanySetupLoading = (props) => {
};

export default CompanySetupMainPage;
