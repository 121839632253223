// General Use
import React, { useState } from "react";
import EditInterests from "./Components/EditInterests";
import EditFiles from "./Components/EditFiles";
import {
  checkValidSmall,
  checkValidBig,
  checkWebsiteValid,
} from "../../GeneralUse/CheckValidFunctions";
import CreatableSelect from "react-select/creatable";
import { generateKeywords } from "../../GeneralUse/PrimeFunctions";
import TeamManagement from "./Components/TeamManagement";
import { getOfficialAccountName } from "../../GeneralUse/FunctionBucket";
import {
  updateUserProfile,
  updateCompany,
} from "../../GeneralUse/UploadFirebase";
import { jobTitlesList, gridIndustryList } from "../../GeneralUse/Variables";
import { Settings } from "react-feather";
import LocationMap from "./Components/LocationMap";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { Row, Col, Button, Form } from "react-bootstrap";
import { changeMyCompany } from "../../Redux/actions";
import Dropzone from "react-dropzone";

/*
  Pro (T1) Page
  * Profile Page to show my company and user profile information
  * Lots editing based on create profile and create company pages
*/
const Tier1ProfilePage = (props) => {
  const dispatch = useDispatch();
  const { myUserLogin, myUserProfile, myCompany } = useSelector((state) => ({
    myUserLogin: state.myUserLogin,
    myUserProfile: state.myUserProfile,
    myCompany: state.myCompany,
  }));

  // For detecting if keyword gneration is now needed
  const hardSetDescription = myCompany.description;

  // About Me
  const [aboutMe, changeAboutMe] = useState({
    name: myUserProfile.name,
    jobTitle: myUserProfile.jobTitle,
    profilePicture: { url: myUserProfile.profilePicture.fileURL, file: null },
  });
  function handleChangeAboutMe2(newFile) { // For Dropzone
    setAboutMeFinishMessage("");
    const aboutMeCopy = { ...aboutMe };
    const image = newFile
      if (!!image) {
        aboutMeCopy["profilePicture"] = {
          url: URL.createObjectURL(image),
          file: image,
        };
      }
    changeAboutMe(aboutMeCopy);
  }
  function handleChangeAboutMe(e) {
    e.preventDefault();
    setAboutMeFinishMessage("");
    const aboutMeCopy = { ...aboutMe };
    if (e.target.name === "profilePicture") {
      const image = e.target.files[0];
      if (!!image) {
        aboutMeCopy[e.target.name] = {
          url: URL.createObjectURL(image),
          file: image,
        };
      }
    } else {
      aboutMeCopy[e.target.name] = e.target.value;
    }
    changeAboutMe(aboutMeCopy);
  }
  function handleChangeJobTitle(selection) {
    setAboutMeFinishMessage("");
    const aboutMeCopy = { ...aboutMe };
    aboutMeCopy.jobTitle = selection.label;
    changeAboutMe(aboutMeCopy);
    changeCurrentJobTitle(selection);
  }
  const [currentJobTitleValue, changeCurrentJobTitle] = useState(() => {
    let foundObj = jobTitlesList.filter(
      (obj) => obj.label === aboutMe.jobTitle
    );
    if (foundObj.length > 0) {
      return foundObj[0];
    } else return { value: aboutMe.jobTitle, label: aboutMe.jobTitle };
  });

  const [loadingAboutMe, setLoadingAboutMe] = useState(false);
  const [aboutMeFinishMessage, setAboutMeFinishMessage] = useState("");
  const [messageIsError, setMessageError] = useState(false);
  async function submitChangesAboutMe(e) {
    e.preventDefault();
    let inputCheck = { valid: true, message: "" };
    inputCheck = checkValidSmall(aboutMe.name); // Name
    if (inputCheck.valid === false) {
      setAboutMeFinishMessage(inputCheck.message);
      setMessageError(true);
      return;
    }

    setLoadingAboutMe(true);
    let updatedProfileResponse = await updateUserProfile(
      myUserProfile,
      aboutMe
    );
    changeAboutMe({
      name: myUserProfile.name,
      jobTitle: myUserProfile.jobTitle,
      profilePicture: { url: myUserProfile.profilePicture.fileURL, file: null },
    });
    setMessageError(false);
    setAboutMeFinishMessage(updatedProfileResponse.message);
    setLoadingAboutMe(false);
  }

  // Company Info
  const [companyInfo, changeCompanyInfo] = useState({
    companyName: myCompany.companyName,
    description: myCompany.description,
    location: myCompany.location,
    website: myCompany.website,
  });
  function handleChangeCompanyInfo(e) {
    e.preventDefault();
    setCompanyInfoFinishMessage("");
    const companyInfoCopy = { ...companyInfo };
    companyInfoCopy[e.target.name] = e.target.value;
    changeCompanyInfo(companyInfoCopy);
  }
  function handleChangeLocation(newLocation) {
    setCompanyInfoFinishMessage("");
    const companyInfoCopy = { ...companyInfo };
    companyInfoCopy.location = newLocation;
    changeCompanyInfo(companyInfoCopy);
  }
  const [currentIndustryFull, setCurrentIndustryFull] = useState(() => {
    if (!myCompany.industryKeywords) {
      return null;
    }
    let gridFullFound = gridIndustryList.find(
      (obj) => obj.value === myCompany.industryKeywords.industry[0]
    );
    return gridFullFound;
  });
  function handleChangeIndustry(newIndustry) {
    setCompanyInfoFinishMessage("");
    setCurrentIndustryFull(newIndustry);
  }
  const [loadingCompanyInfo, setLoadingCompanyInfo] = useState(false);
  const [companyInfoFinishMessage, setCompanyInfoFinishMessage] = useState("");
  const [messageIsError2, setMessageError2] = useState(false);
  async function submitChangesCompanyInfo(e) {
    e.preventDefault();
    let inputCheck = { valid: true, message: "" };
    inputCheck = checkValidSmall(companyInfo.companyName); // Name
    if (inputCheck.valid === false) {
      setCompanyInfoFinishMessage(inputCheck.message);
      setMessageError2(true);
      return;
    }
    inputCheck = checkValidBig(companyInfo.description);
    if (inputCheck.valid === false) {
      setCompanyInfoFinishMessage(inputCheck.message);
      setMessageError2(true);
      return;
    }
    setLoadingCompanyInfo(true);
    let myCompanyNoIndustry = { ...myCompany };
    delete myCompanyNoIndustry.industryKeywords;
    let updatedCompanyResponse = await updateCompany(
      myCompanyNoIndustry,
      companyInfo
    );

    if (updatedCompanyResponse.success === false) {
      setCompanyInfoFinishMessage(updatedCompanyResponse.message);
      setLoadingCompanyInfo(false);
      setMessageError2(true);
    }

    let industryChanged = false;
    let isNewIndustry = false;
    if (!!myCompany.industryKeywords) {
      if (
        currentIndustryFull.value !== myCompany.industryKeywords.industry[0]
      ) {
        industryChanged = true;
      }
    } else if (!!currentIndustryFull) {
      industryChanged = true;
      isNewIndustry = true;
    }

    if (
      companyInfo.description !== myCompany.description ||
      companyInfo.website !== myCompany.website ||
      industryChanged
    ) {
      await generateKeywords(
        myCompany.selfid,
        "startup",
        "__self__",
        myCompany.selfref.path,
        null,
        [currentIndustryFull.value],
        companyInfo.website
      );
    } else {
      console.log("no keyword genration needed");
    }

    let industryKeywords = null;
    if (isNewIndustry) {
      industryKeywords = { industry: [currentIndustryFull.value] };
    } else {
      industryKeywords = myCompany.industryKeywords;
      industryKeywords.industry = [currentIndustryFull.value];
    }

    changeCompanyInfo({
      companyName: companyInfo.companyName,
      description: companyInfo.description,
      location: companyInfo.location,
      website: companyInfo.website,
    });
    dispatch(
      changeMyCompany({
        ...myCompany,
        companyName: companyInfo.companyName,
        description: companyInfo.description,
        location: companyInfo.location,
        website: companyInfo.website,
        industryKeywords: { ...industryKeywords },
      })
    );
    setCompanyInfoFinishMessage(updatedCompanyResponse.message);
    setLoadingCompanyInfo(false);
    setMessageError2(false);
  }

  // Detects if any changes have happened
  function checkChangesHappened(type) {
    let changesHappened = false;
    if (type === "aboutMe") {
      // Checks all the aboutMe before and after values
      if (
        aboutMe.name !== myUserProfile.name ||
        aboutMe.jobTitle !== myUserProfile.jobTitle ||
        aboutMe.profilePicture.file !== null
      ) {
        changesHappened = true;
      }
    } else if (type === "companyInfo") {
      // Checks all the companyInfo before and after values
      if (
        companyInfo.companyName !== myCompany.companyName ||
        companyInfo.description !== myCompany.description ||
        companyInfo.location !== myCompany.location ||
        companyInfo.website !== myCompany.website
      ) {
        changesHappened = true;
        return changesHappened;
      }
      if (!!myCompany.industryKeywords && !!currentIndustryFull) {
        if (
          currentIndustryFull.value !== myCompany.industryKeywords.industry[0]
        ) {
          changesHappened = true;
        }
      } else if (currentIndustryFull)
        if (!!currentIndustryFull && !myCompany.industryKeywords) {
          changesHappened = true;
        }
    }
    return changesHappened;
  }

  // Editing Interests
  const [editInterests, triggerEditInterests] = useState(false);
  const [interestsFinishMessage, setInterestsFinishMessage] = useState("");

  // Editing Files
  const [editFiles, triggerEditFiles] = useState(false);
  const [filesFinishMessage, setFilesFinishMessage] = useState("");

  // Team Management
  const [viewPeople, triggerViewPeople] = useState(false);

  // Main Render Wrapper depending on specific
  if (editInterests) {
    return (
      <EditInterests
        side="tier1"
        currentCompany={myCompany}
        selectedInterests={myCompany.interests}
        triggerEditInterests={triggerEditInterests}
        setInterestsFinishMessage={setInterestsFinishMessage}
      />
    );
  }
  if (editFiles) {
    return (
      <EditFiles
        side="tier1"
        currentCompany={myCompany}
        selectedFiles={myCompany.files.filesList}
        triggerEditFiles={triggerEditFiles}
        setFilesFinishMessage={setFilesFinishMessage}
      />
    );
  }
  if (viewPeople) {
    return (
      <Col>
        <TeamManagement
          side="tier1"
          goBack={() => triggerViewPeople(false)}
          {...props}
        />
      </Col>
    );
  }

  const themeChildType = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#ddd5fb",
      primary: "#713ffe",
      primary50: "#c4acf8",
      neutral0: "#f5f7fa",
      neutral80: "black",
    },
    borderRadius: 8,
  });

  const themeSelectIndustry = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#ddd5fb",
      primary: "#713ffe",
      primary50: "#c4acf8",
      //neutral0: '#f5f7fa',
      neutral0: "#f5f7fa",
      neutral80: "black",
    },
    borderRadius: 10,
    outline: "none",
  });

  return (
    <Row className="t1-page-wrapper t1-profile-page">
      <Col className="t1-inner-page">
        {/* Top Profile */}
        <Row className="t1p-top-matches">
          <Col>
            <h1 className="t1-header">Profile</h1>
            <Button
              className="t1-settings-go"
              onClick={() => props.history.push("/tier1/settings")}
            >
              <Settings />
            </Button>
            <div className="get-more-match">
              <h5 className="get-more-matches-header">Get More Matches</h5>
              <Button onClick={() => props.history.push("/tier1/balance")}>
                Continue
              </Button>
            </div>
          </Col>
        </Row>

        {/* About Me */}
        <Row className="t1p-about-me">
          <Col>
            <Form onSubmit={submitChangesAboutMe}>
              <h4 className="about-me-header">About Me</h4>

              <div className="img-input">
                <img
                  src={aboutMe.profilePicture.url}
                  alt=""
                  className="profile-picture-image-small"
                />
                <div className="profile-picture-file-main-wrapper">
                  {/* <input
                    name="profilePicture"
                    type="file"
                    onChange={handleChangeAboutMe}
                    accept="image/*"
                    className="profile-picture-file-input-button"
                    size="60"
                    id="file"
                  /> */}
                  <Dropzone
                    onDrop={(files, rejectedFiles) => {
                      const newFile = files[0];
                      handleChangeAboutMe2(newFile);
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps()}>
                          <input className="profile-picture-file-input-button" {...getInputProps()} />
                          <label htmlFor="file">Change Profile Picture</label>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                  {/* <label htmlFor="file">Change Profile Picture</label> */}
                </div>
              </div>
              <div className="account-type">
                <label className="profile-simple-label">Account Type</label>
                <input
                  value={getOfficialAccountName(myUserLogin.accountType)}
                  className="about-me-input"
                  disabled={true}
                />
              </div>
              <div className="name-input">
                <label className="profile-simple-label">Name</label>
                <input
                  name="name"
                  placeholder="Enter your name..."
                  value={aboutMe.name}
                  className="about-me-input"
                  onChange={handleChangeAboutMe}
                  disabled={loadingAboutMe}
                  autoComplete="off"
                />
              </div>
              <div className="job-title">
                <label className="profile-simple-label">Job Title</label>
                <CreatableSelect
                  components={{ IndicatorSeparator: () => null }}
                  value={
                    !!currentJobTitleValue.value ? currentJobTitleValue : null
                  }
                  onChange={handleChangeJobTitle}
                  options={jobTitlesList}
                  theme={themeChildType}
                  placeholder="Create or search your title..."
                  className="job-title-selection"
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      boxShadow: "none",
                      border: "none",
                      padding: "4px 0px",
                      fontSize: 16,
                      fontFamily: "Poppins",
                      height: "3em",
                    }),
                    valueContainer: (provided, state) => ({
                      ...provided,
                      margin: "0",
                      padding: "0px 8px",
                      color: "white",
                    }),
                    multiValue: (provided) => ({
                      ...provided,
                      color: "#713ffe;",
                      background: "#ddd5fb",
                    }),
                    option: (base, state) => ({
                      ...base,
                      fontSize: 14,
                      fontFamily: "Poppins",
                      padding: "4px 12px",
                      cursor: "pointer",
                    }),
                  }}
                />
              </div>
              <Button
                className="save-info-button"
                disabled={!checkChangesHappened("aboutMe") || loadingAboutMe}
                type="submit"
              >
                {loadingAboutMe ? "Loading..." : "Save About Me"}
              </Button>
              <p
                className={messageIsError ? "error-message" : "success-message"}
              >
                {aboutMeFinishMessage}
              </p>
            </Form>
          </Col>
        </Row>

        {/* Company Info */}
        <Row className="t1p-company-information">
          <Col>
            <Form onSubmit={submitChangesCompanyInfo}>
              <p className="company-info-header">Company Information</p>
              <div className="company-name">
                <label className="profile-simple-label">Company Name</label>
                <input
                  name="companyName"
                  placeholder="Enter your company name..."
                  value={companyInfo.companyName}
                  className="about-me-input"
                  onChange={handleChangeCompanyInfo}
                  disabled={loadingCompanyInfo}
                  autoComplete="off"
                />
              </div>
              <div className="company-description">
                <label className="profile-simple-label">Description</label>
                <textarea
                  name="description"
                  placeholder="Enter your company description..."
                  value={companyInfo.description}
                  className="about-me-input input-desc"
                  onChange={handleChangeCompanyInfo}
                  disabled={loadingCompanyInfo}
                  autoComplete="off"
                />
              </div>
              <div>
                <label className="profile-simple-label">Industry</label>
                <Select
                  components={{ IndicatorSeparator: () => null }}
                  theme={themeSelectIndustry}
                  isDisabled={loadingCompanyInfo}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      boxShadow: "none",
                      border: "none",
                      padding: "4px 8px",
                      fontSize: 16,
                      fontFamily: "Poppins",
                      height: "3em",
                    }),
                    valueContainer: (provided, state) => ({
                      ...provided,
                      margin: "0",
                      padding: "0",
                      color: "white",
                    }),
                    menuList: (provided, state) => ({
                      ...provided,
                      maxHeight: "184px",
                    }),
                    multiValue: (provided) => ({
                      ...provided,
                      color: "#713ffe;",
                      background: "#ddd5fb",
                    }),
                    option: (base, state) => ({
                      ...base,
                      fontSize: 14,
                      fontFamily: "Poppins",
                      padding: "4px 12px",
                      cursor: "pointer",
                    }),
                  }}
                  className="job-title-input"
                  value={currentIndustryFull}
                  onChange={(obj) => handleChangeIndustry(obj)}
                  options={gridIndustryList}
                />
              </div>
              <div className="company-name">
                <label className="profile-simple-label">Website</label>
                <input
                  name="website"
                  value={companyInfo.website}
                  placeholder="Enter your company website..."
                  className="about-me-input"
                  onChange={handleChangeCompanyInfo}
                  disabled={loadingCompanyInfo}
                  autoComplete="off"
                />
              </div>
              <div className="company-location">
                <label className="profile-simple-label">Location</label>
                <LocationMap
                  currentLocation={companyInfo.location}
                  isDisabled={loadingCompanyInfo}
                  handleLocationChange={(locationInput) =>
                    handleChangeLocation(locationInput)
                  }
                />
              </div>

              <Button
                className="save-info-button"
                disabled={
                  !checkChangesHappened("companyInfo") || loadingCompanyInfo
                }
                type="submit"
              >
                {loadingCompanyInfo ? "Loading..." : "Save Company Information"}
              </Button>
              <p
                className={
                  messageIsError2 ? "error-message" : "success-message"
                }
              >
                {companyInfoFinishMessage}
              </p>
            </Form>
          </Col>
        </Row>

        {/* For Editing More Larger Stuff & Team Management */}
        <Row className="t1p-company-more-information">
          <Col>
            <div className="more-info-bulk">
              <label className="profile-simple-label">Files</label>
              <Button onClick={() => triggerEditFiles(true)}>
                Edit Company Files
              </Button>
              <p className="success-message">{filesFinishMessage}</p>
            </div>
            <div className="more-info-bulk">
              <label className="profile-simple-label">Interests</label>
              <Button onClick={() => triggerEditInterests(true)}>
                Edit Areas of Interests
              </Button>
              <p className="success-message">{interestsFinishMessage}</p>
            </div>
            <div className="more-info-bulk">
              <label className="profile-simple-label">
                Linked Accounts in Your Company
              </label>
              <Button onClick={() => triggerViewPeople(true)}>
                View Your Team
              </Button>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default Tier1ProfilePage;
