// General Use
import React from "react";
import { Button } from "react-bootstrap";
import GuideToolTips from "../../Tier1/Components/GuideToolTips.js";

const CompanySetupWebsite = (props) => {
    return (
        <div className="guide-company-process-3-wrapper">
            <p className="guide-profile-process-header">
                Do you have a company website?
                <GuideToolTips
                    description="This website will be used to match your company with compatible Open Platform members. Make sure to add a website with a large text description of your company, operations and products. If your website contains only images or little text, make sure to upload a file in the next step."
                    placement="right"
                />
            </p>
            <input
                type="url"
                value={props.form.website}
                placeholder="https://example.com"
                pattern="https://.*"
                autoComplete="off"
                onChange={(e) => props.handleInputChange(e, "website")}
            />
            <div className="error-message-main">{props.errorMessage}</div>
            <div className="profile-buttons">
                <Button className="buttons-guide-back" onClick={() => props.goBack()}>
                    Back
                </Button>
                <Button
                    className="buttons-guide-continue"
                    onClick={() => props.goNext("website")}
                >
                    Continue
                </Button>
            </div>
        </div>
    );
};

export default CompanySetupWebsite