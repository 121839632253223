import React, { useEffect, useState } from "react";
import myFirebase from "../../firebase";

import { Button, Carousel } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";

import Keyword_Label from "./tutorialSvgFiles/Keyword_Label.svg";
import Matching_score from "./tutorialSvgFiles/Matching_score.svg";
import Like_Button from "./tutorialSvgFiles/Like_Button.svg";
import Partner_tools from "./tutorialSvgFiles/Partner_tools.svg";
import Chat from "./tutorialSvgFiles/Chat.svg";
import Chat_tools from "./tutorialSvgFiles/Chat_tools.svg";

import TB_Keywords from "./tutorialSvgFiles/TB_Keywords.svg"
import TB_Score from "./tutorialSvgFiles/TB_Score.svg"
import TB_Buttons from "./tutorialSvgFiles/TB_Buttons.svg"
import TB_Tools from "./tutorialSvgFiles/TB_Tools.svg"
import TB_Go_Chat from "./tutorialSvgFiles/TB_Go_Chat.svg"
import TB_Marketplace from "./tutorialSvgFiles/TB_Marketplace.svg"

import useWindowDimensions from "../Shared/useWindowDimensions"

const Tutorial = (props) => {
  const { width } = useWindowDimensions()

  const [promiseProgress, setPromiseProgress] = useState(1);
  const [promiseProgressFlag, setPromiseProgressFlag] = useState(true);
  const [promiseProgressDestination, setPromiseProgressDestination] = useState(1);

  const [finishedLoadingComplete, setfinishedLoadingComplete] = useState(false);

  const moveToTier1 = () => {
    // Resets the id because we need to get the user WITH the custom claim of accountType
    myFirebase.auth().currentUser.getIdToken(true); // Issue is that this resets the component
  };

  const handleSelect = (selectedIndex, e) => {
    setActiveIndex(selectedIndex);
  };

  const [activeIndex, setActiveIndex] = useState(0)
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex(activeIndex > 4 ? 0 : activeIndex + 1)
    }, 5000);
    return () => clearInterval(interval);
  }, [activeIndex]);

  useEffect(() => {
    if (promiseProgressFlag) {
      setPromiseProgressFlag(false);
      console.log("Stage 0 finished...");
    } else {
      setPromiseProgressDestination(100);
      // setPromiseProgress(49);
    }
    if (props.matchPromise) {
      setPromiseProgress(100);
      console.log("Stage 1 finished...")
    }
    if (props.matchPromise && props.keywordPromise) {
      setfinishedLoadingComplete(true);
      setPromiseProgressDestination(100);
      // setPromiseProgress(100);
      console.log("Stage 2 finished...")
    }
    if (promiseProgress < promiseProgressDestination) {
      if (promiseProgress < 90) {
        setPromiseProgress(promiseProgress + 0.01);
      }
    }
  }, [props.matchPromise, props.keywordPromise, promiseProgress, promiseProgressDestination, promiseProgressFlag]);

  // 3 - 5 seconds shift
  if (width >= 600) {
    return (
      <div className="tutorial-main-wrapper">
        <section className='tutorial-top-wrapper'>
          <div className="tutorial_progress_bar">
            <div className="progress-bar-hide-top" />
            <ProgressBar now={promiseProgress} animated={!finishedLoadingComplete} />
            <div className="progress-bar-hide-bottom" />
          </div>
        </section>

        <section class="my_carousel bigger-svgs">
          <Carousel controls={false} activeIndex={activeIndex} onSelect={handleSelect}>
            <Carousel.Item>
              <div class="slide_container">
                <div class="general_back match-big-back">
                  <img class="slide_image" src={TB_Keywords} alt="keywords-slide" />
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div class="slide_container">
                <div class="general_back match-big-back">
                  <img class="slide_image" src={TB_Score} alt="score-slide" />
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div class="slide_container">
                <div class="general_back match-big-back">
                  <img class="slide_image" src={TB_Buttons} alt="buttons-slide" />
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div class="slide_container">
                <div class="general_back partner-big-back">
                  <img class="slide_image" src={TB_Tools} alt="tools-slide" />
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div class="slide_container">
                <div class="general_back partner-big-back">
                  <img class="slide_image" src={TB_Go_Chat} alt="go-chat-slide" />
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div class="slide_container">
                <div class="general_back chat-big-back">
                  <img class="slide_image" src={TB_Marketplace} alt="marketplace-slide" />
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </section>

        <section className={finishedLoadingComplete ? "tutorial-bottomp-curating-finished" : "tutorial-bottomp-curating-finished"}>
          {finishedLoadingComplete ? (
            <Button id="curating-matches-button" onClick={() => moveToTier1()}>
              Continue to Your Matches
            </Button>
          ) : (
            <p id="curating-matches-progress" disabled>Curating Your Matches...</p>
          )}
        </section>


      </div>
    )
  } else {
    return (
      <div className="tutorial-main-wrapper">

        <section className='tutorial-top-wrapper'>
          <div className="tutorial_progress_bar">
            <div className="progress-bar-hide-top" />
            <ProgressBar now={promiseProgress} animated={!finishedLoadingComplete} />
            <div className="progress-bar-hide-bottom" />
          </div>
        </section>

        <section class="my_carousel smaller-svgs">
          <Carousel controls={false} activeIndex={activeIndex} onSelect={handleSelect}>
            <Carousel.Item>
              <div class="slide_container">
                <div class="general_back match_back">
                  <img class="slide_image" src={Keyword_Label} alt="keyword-slide" />
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div class="slide_container">
                <div class="general_back match_back">
                  <img class="slide_image" src={Matching_score} alt="score-slide" />
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div class="slide_container">
                <div class="general_back match_back">
                  <img class="slide_image" src={Like_Button} alt="like-slide" />
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div class="slide_container">
                <div class="general_back partner_back">
                  <img class="slide_image" src={Partner_tools} alt="partner-slide" />
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div class="slide_container">
                <div class="general_back partner_back">
                  <img class="slide_image" src={Chat} alt="chat-slide" />
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div class="slide_container">
                <div class="general_back chat_back">
                  <img class="slide_image" src={Chat_tools} alt="chat-tools-slide" />
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </section>

        <section className={finishedLoadingComplete ? "tutorial-bottomp-curating-finished" : "tutorial-bottomp-curating-finished"}>
          {finishedLoadingComplete ? (
            <Button id="curating-matches-button" onClick={() => moveToTier1()}>
              Continue to Your Matches
            </Button>
          ) : (
            <p id="curating-matches-progress" disabled>Curating Your Matches...</p>
          )}
        </section>


      </div>
    );
  }
};

export default Tutorial

/* Bootstrap
 <Carousel controls={false} activeIndex={index} onSelect={handleSelect}>
    <Carousel.Item>
    <div style={{backgroundImage: `url(${Blurry_background})`, backgroundRepeat: 'no-repeat'}}>
                        <img src={Keyword_Label}/>
                    </div>
    </Carousel.Item>
    <Carousel.Item>
    <div style={{backgroundImage: `url(${Blurry_background})`, backgroundRepeat: 'no-repeat'}}>
                        <img src={Matching_score}/>
                        </div>
    </Carousel.Item>
    <Carousel.Item>
    <div style={{backgroundImage: `url(${Blurry_background})`, backgroundRepeat: 'no-repeat'}}>
                        <img src={Like_Button}/>
                </div>
    </Carousel.Item>
  </Carousel>


*/

/*
 <CarouselProvider
            naturalSlideHeight={200}
            naturalSlideWidth={100}
            totalSlides={6}
            infinite={true}
            isPlaying={true}
            interval={5000}
            visibleSlides={1}
            style={{width: '375px', height: '812px'}}
            >
                <DotGroup/>
            <Slider>
                <Slide index={0}>
                    <div style={{backgroundImage: `url(${Blurry_background})`, backgroundRepeat: 'no-repeat'}}>
                        <img src={Keyword_Label}/>
                    </div>
                </Slide>
                <Slide index={1}>
                <div style={{backgroundImage: `url(${Blurry_background})`, backgroundRepeat: 'no-repeat'}}>
                        <img src={Matching_score}/>
                        </div>
                </Slide>

                <Slide index={2}>
                <div style={{backgroundImage: `url(${Blurry_background})`, backgroundRepeat: 'no-repeat'}}>
                        <img src={Like_Button}/>
                </div>
                </Slide>
                <Slide index={3}>
                <div style={{ backgroundImage: `url(${BG_Partner_tools})`, backgroundRepeat: 'no-repeat'}}>
                        <img src={Partner_tools}/>
                </div>
                </Slide>
                <Slide index={4}>
                <div style={{ backgroundImage: `url(${BG_Partner_tools})`, backgroundRepeat: 'no-repeat'}}>
                        <img src={Chat}/>
                </div>
                </Slide>
                <Slide index={5}>
                <div style={{ backgroundImage: `url(${BG_chat_tools})`, backgroundRepeat: 'no-repeat'}}>
                        <img src={Chat_tools}/>
                </div>
                </Slide>

            </Slider>

            {finishedLoadingComplete ?  <Button onClick={() => moveToTier1()}>Continue to Your Matches</Button> : <Button disabled>Curating Your Matches</Button>}

        </CarouselProvider>

*/

/* Pure React
<div id="carousel-example-generic" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
            <li data-target="#carousel-example-generic" data-slide-to="0" class="active" id="carousel-indicator-li-id1"></li>
        <li data-target="#carousel-example-generic" data-slide-to="1" id="carousel-indicator-li-id2"></li>
        <li data-target="#carousel-example-generic" data-slide-to="2" id="carousel-indicator-li-id3"></li>
            </ol>
                <div class="carousel-inner" role="listbox">
                    <div class="carousel-item active">
                    <div style={{backgroundImage: `url(${Blurry_background})`, backgroundRepeat: 'no-repeat'}}>
                        <img src={Keyword_Label}/>
                    </div>
                    <div class="carousel-item">
                    <div style={{backgroundImage: `url(${Blurry_background})`, backgroundRepeat: 'no-repeat'}}>
                        <img src={Matching_score}/>
                    </div>
                    </div>
                    <div class="carousel-item">
                    <div style={{backgroundImage: `url(${Blurry_background})`, backgroundRepeat: 'no-repeat'}}>
                        <img src={Like_Button}/>
                     </div>
                    </div>
                </div>
            </div>
            <a class="left carousel-control" href="#carousel-example-generic" role="button" data-slide="prev">
            <span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="right carousel-control" href="#carousel-example-generic" role="button" data-slide="next">
            <span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>

*/
