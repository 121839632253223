/**
 * Prime Functions
 * These functions are for the main keywords and matching process
 * Should be handled VERY delicately
 * 
 * 
 * 
 * Note For Database parameters: (Remember to change depending on dev)
 * - open
 * - staging
 * 
 * Later change to .envdev for staging
 */

import myFirebase from "../firebase";
import axios from 'axios';
import { firebaseAnalytics } from "../firebase"
import { getContinent, continentList } from "../GeneralUse/CountriesWithContinents"
import { capitalizeName } from "../GeneralUse/FunctionBucket";

// Generates the Keywords (Backend Google Cloud Function)
export async function generateKeywords(docid, label, parent, path, cleanFiles, industries, website = "") {
    console.log("Generating Keywords...")

    //staging // open
    const paramFormat = {
        "database": "open",
        "docid": docid,
        "label": label,
        "parent": parent,
        "path": path,

        "industry": industries,
        "website": website,
    }
    if (!!cleanFiles) {
        paramFormat["pdfs"] = cleanFiles
    }
    console.log(paramFormat)
    const url = process.env.REACT_APP_KEYWORDS_ENDPOINT
    try {
        return axios.post(url, null, { data: paramFormat }).then(response => {
            firebaseAnalytics.logEvent('keywords_function_triggered');
            console.log("...Finished Keywords")
            //console.log("Finished Backend Keyword Generation: ", response)
            //console.log(response);
        })
    }
    catch (err) { console.log(err) }
}

// Generates the Match (Backend Google Cloud Function)
export async function generateBackendMatches(userid, from_type, from_value, from_group, to_type, ignore = []) {
    console.log("Generating Matching...")

    // staging // open
    const paramFormat = {
        "database": "open",
        "userid": userid,
        "from_type": from_type,
        "from_value": from_value,
        "from_group": from_group,
        "to_type": to_type,
        "ignore": ignore
    }

    console.log(paramFormat);
    const url = process.env.REACT_APP_MATCHING_ENDPOINT
    try {
        return axios.post(url, null, { data: paramFormat }).then(response => {
            firebaseAnalytics.logEvent('matches_function_triggered')
            console.log("...Finished Matching")
            //console.log("Finished Backend Matches: ", response)
            //console.log(response);
            //console.log()
        })
    }
    catch (err) { console.log(err) }
}

//
//
// Helper Functions
//
//

export async function populateDeck(parentMatchSnapshotRef, matchCacheSubcollection, currentDeck = [], myEcosystem = [], filters = {}, fromCompanyIndustry) {
    let limit = 4 // How many to grab (top 4 in this case / 20, top 4/36...)
    console.log("Populating Deck...")
    //console.log({ parentMatchSnapshotRef: parentMatchSnapshotRef, matchCacheSubcollection: matchCacheSubcollection, currentDeck: currentDeck, myEcosystem: myEcosystem, filters: filters })

    let matchesDeck = []
    let matchesHand = {}

    let myMatchesSnapshot = await parentMatchSnapshotRef.collection(matchCacheSubcollection).where('viewed', '==', false).limit(20).get()
    if (!myMatchesSnapshot.empty) {
        let companyPromises = []
        console.log(myMatchesSnapshot)
        myMatchesSnapshot.forEach(async eachMatchHistory => {
            let matchHistoryData = eachMatchHistory.data()
            if (matchHistoryData.viewed === false &&
                !currentDeck.includes(eachMatchHistory.id) &&
                !!matchHistoryData.score /*filters.includes(matchHistoryData.to_label) */
            ) {
                matchHistoryData.selfid = eachMatchHistory.id
                matchHistoryData.selfref = eachMatchHistory.ref

                if (matchHistoryData.match_type === "one2one") { // the ONLY active one currently! OTHERS WILL GET CLEANED
                    companyPromises.push(getCardCompanyInfo(eachMatchHistory.id, "tier1Network", matchHistoryData, matchCacheSubcollection))
                                        // Maybe add the article function here? You may also need to push its promise to the companyPromises like above
                }

                if (matchHistoryData.match_type === "one2many") {
                    companyPromises.push(getCardCompanyInfo(eachMatchHistory.id, "tier2Network", matchHistoryData, matchCacheSubcollection))
                    companyPromises.push(getChildrenCardCompanyInfo(matchHistoryData.to_parent, matchHistoryData))
                }

                if (matchHistoryData.match_type === "many2one") {
                    if (matchHistoryData.to_label === "startup") {
                        companyPromises.push(getCardCompanyInfo(eachMatchHistory.id, "tier1Network", matchHistoryData, matchCacheSubcollection))
                    } else {
                        companyPromises.push(getCardCompanyInfo(eachMatchHistory.id, "tier2Network", matchHistoryData, matchCacheSubcollection))
                    }

                    let childrenids = Object.keys(matchHistoryData.child_scores)
                    childrenids.forEach(eachDocChildid => {
                        let foundChildCompany = getMatchingFromCompany(myEcosystem, eachDocChildid)
                        if (!!matchHistoryData.childrenData) {
                            matchHistoryData.childrenData.push(foundChildCompany)
                        } else {
                            matchHistoryData.childrenData = [foundChildCompany]
                        }
                    })
                }
                matchesDeck.push(matchHistoryData)
            }
        })


        // Final Return and Sorting
        return Promise.all(companyPromises).then(async () => {

            let startCardLength = matchesDeck.length
            //console.log(matchesDeck)
            matchesDeck = matchesDeck.filter(eachCard => {
                return !!eachCard.companyData // Dont show if company does NOT exist
            })
            matchesDeck = matchesDeck.filter(eachCard => {
                /*
                console.log(filters.continents,eachCard.companyData.continent )
                console.log(filters.continents.includes(eachCard.companyData.continent))
                */

                if (JSON.stringify([...filters.continents].sort()) === JSON.stringify([...continentList].sort())) return eachCard
                if (filters.continents.includes(eachCard.companyData.continent)) return eachCard
            })

            let finalCardLength = matchesDeck.length
            //console.log(Math.round(finalCardLength / startCardLength * 100) + "% of cards grabbed good!")

            //console.log(matchesDeck)

            // Gets the Matching Hand
            let currentHandselfid = null
            let parentDoc = await parentMatchSnapshotRef.get()
            if (!parentDoc.empty) {
                const parentData = parentDoc.data()
                if (!!parentData.currentHand) {
                    let splitHandPath = parentData.currentHand.path.split('/')
                    currentHandselfid = splitHandPath[splitHandPath.length - 1]
                }
            }
            let currentHandDoc = null
            matchesDeck = matchesDeck.filter(function (el) {
                if (el.selfid !== currentHandselfid) { return true } else { currentHandDoc = el; return false }
            })

            matchesDeck = [...matchesDeck].sort(function (a, b) { return b.score - a.score }).slice(0, limit + 1); // Where limit is used to get top
            matchesDeck = [...matchesDeck].sort((a, b) => (a.timestamp > b.timestamp) ? 1 : -1)
            if (currentDeck.length === 0 && !currentHandDoc) {
                matchesHand = matchesDeck.length > 0 ? matchesDeck.pop() : {}
            } else {
                matchesHand = currentHandDoc
            }
            console.log("...finished populating deck!")
            return { deck: matchesDeck, hand: matchesHand }
        })
    } else console.log("is empty subcollection?")
    return { deck: [], hand: {} }
}
///

// This grabs a company's info that you are matching to
async function getCardCompanyInfo(companyDocid, collectionToSearch, matchCard, fromCompanyId) {
    try {
        let st1DocResponse = await myFirebase.firestore().collection(collectionToSearch).doc(companyDocid).get()
        if (st1DocResponse.exists) {
            let companyData = st1DocResponse.data()
            companyData.companyName = capitalizeName(st1DocResponse.data().companyName)
            companyData.selfid = st1DocResponse.id
            companyData.selfref = st1DocResponse.ref
            companyData.team = await getCompanyTeam(st1DocResponse.id) // gets the team of the company
            companyData.continent = await getContinent(companyData.location) // gets the continent (for later filtering)
            companyData.industries = await getCompanyIndustries(st1DocResponse.id) // gets the industry in a LIST

            let fromIndustry = await getCompanyIndustries(fromCompanyId) //gets the from company's industry
            let newsData = await getNewsMatch(companyData.industries, fromIndustry) //gets the news cache for the matching

            matchCard.companyData = companyData // sets the companyData to the match card obj datas
            matchCard.newsData = newsData // sets newsData to list of related cross-industry articles, if exists
        }
    } catch (error) { console.log(error) }
}

/* Returns the news cache entries for the industries specified in the params 
 * 
 * Note that currently each company has 1 industry, so this function will only
 * pull off the first industry on the list and assign the news articles according
 * to this matching -- this will need to be changed if more industries can be assigned
 * per company
 * 
 * uncomment the console.logs to determine which industries do not have a news cache pairing or
 * to see if cards have undefined industries
*/
async function getNewsMatch(company1IndustryList, company2IndustryList) {
    try{
        let company1Industry = company1IndustryList[0]
        let company2Industry = company2IndustryList[0]

        //adjust company1 to be alphabetically first
        if(company1Industry > company2Industry){
            let tempIndustry = company1Industry
            company1Industry = company2Industry
            company2Industry = tempIndustry
        }

        if(!!company1Industry && !!company2Industry){ //if the industries are defined
            return await myFirebase.firestore()
            .collection("newsCache")
            .doc(company1Industry)
            .collection(company2Industry).get().then((doc) => { //grab news articles alphabetically from database
                if (!doc.empty) { //check the second industry exists in database
                    let result = []
                    doc.forEach((article) => {
                        result.push(article.data()) // add article objects to the result
                    })
                    return result;
                } else { //otherwise return an empty array
                    // console.log("returning empty for " + company1Industry + " and " + company2Industry + "\n")
                    return []
                }
            })
        } else { //otherwise return an empty array
            // console.log("returning empty for " + company1Industry + " and " + company2Industry + "\n")
            return []
        }
    }catch (error) { 
        return [] //return an empty array
    }
}

async function getChildrenCardCompanyInfo(parentDocid, matchCard) {
    try {
        let processedChildrenScores = Object.keys(matchCard.child_scores)
        let returnList = []
        let parentDocumentSnapshot = await myFirebase.firestore().collection('tier2Network').doc(parentDocid).get()
        if (!parentDocumentSnapshot.empty) {
            let childrenPromises = []
            let parentData = parentDocumentSnapshot.data()
            parentData.selfid = parentDocumentSnapshot.id
            parentData.childTypes.forEach(each => {
                let childPromise = myFirebase.firestore().collection('tier2Network').doc(parentDocid).collection(each.typeid).get()
                    .then(childSnapshot => {
                        if (!childSnapshot.empty) {
                            childSnapshot.docs.forEach(eachChild => {
                                let childData = eachChild.data()
                                childData.selfid = eachChild.id
                                if (processedChildrenScores.includes(childData.selfid)) { returnList.push(childData) }
                            })
                        }
                    })
                childrenPromises.push(childPromise)
            })
            return Promise.all(childrenPromises).then(() => { matchCard.children = returnList })
        }
    } catch (error) { return [] }
}

function getMatchingFromCompany(myEcosystem, docidSearch) {
    let foundCompany = {}
    myEcosystem.forEach(eachChildSet => {
        eachChildSet.children.forEach(eachChild => {
            if (eachChild.selfid === docidSearch) {
                foundCompany = eachChild
            }
        })
    })
    return foundCompany
}
export async function getCompanyIndustries(compdocid) {
    try {
        let industryRef = await myFirebase.firestore().collection("industries").where('docid', '==', compdocid).get()
        if (!industryRef.empty) {
            let compIndustryData = industryRef.docs[0].data()
            return compIndustryData.industry
        } else {
            return []
        }
    } catch(error) {
        console.log(error)
        return []
    }
}
export async function getCompanyTeam(compdocid) { // also used for chats
    try {
        let teamPromises = []
        let compTeamDoc = {}
        let teamsSnapshot = await myFirebase.firestore().collection('teams').where('companyDocid', '==', compdocid).get()
        if (!teamsSnapshot.empty) {
            compTeamDoc = teamsSnapshot.docs[0].data()
            compTeamDoc.members.forEach((eachMemberUserid, index) => {
                let memberProfilePromise = myFirebase.firestore().collection('userProfiles').where('userid', '==', eachMemberUserid).get()
                    .then(memberProfileSnapshot => {
                        if (!memberProfileSnapshot.empty) {
                            compTeamDoc.members[index] = memberProfileSnapshot.docs[0].data()
                        }
                    })
                teamPromises.push(memberProfilePromise)
            })
            compTeamDoc.admins.forEach((eachAdminUserid, index) => {
                let adminProfilePromise = myFirebase.firestore().collection('userProfiles').where('userid', '==', eachAdminUserid).get()
                    .then(adminProfileSnapshot => {
                        if (!adminProfileSnapshot.empty) {
                            compTeamDoc.admins[index] = adminProfileSnapshot.docs[0].data()
                        }
                    })
                teamPromises.push(adminProfilePromise)
            })
        }
        return Promise.all(teamPromises).then(() => { return compTeamDoc })
    } catch (err) { console.log(err); return {} }
}

export async function saveMatchHand(userid, currentHand) {
    try {
        await myFirebase.firestore().collection('matchCache').doc(userid).update({ currentHand: currentHand.selfref })
    } catch (error) { console.log(error) }
}

export async function saveMatchParameters(userid, currentParameters) {
    try {
        await myFirebase.firestore().collection('matchCache').doc(userid).update({
            currentParameters: {
                generate: currentParameters.generate,
            }
        })
    } catch (error) { console.log(error) }
}