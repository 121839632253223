/*
    All Redux Reducers
*/
import {combineReducers} from 'redux';

const initialStates = {
    myUserLogin: {},
    myUserProfile: {},
    myTeam: {},
    myCompany: {},
    myChats: {},
    myEcosystem: [],
    statistics: {},
    matching: {},
    feed: {},
}

/*

myUserLogin: {},
    myUserProfile: {},
    myTeam: {},
    myCompany: {},
    myChats: {
        chatRooms: [],
        requests: [],
    },
    myEcosystem: [],
    statistics: {},
    matching: {
        matchMe: {},
        deck: [],
        hand: {},
    },

*/

const userLoginReducer = (state = initialStates.myUserLogin, action) => {
    switch(action.type) {
        case "CHANGE_USER_LOGIN":
            return action.payload;
        case "RESET":
            return initialStates.myUserLogin;
        default:
            return state;
    }
}
const userProfileReducer = (state = initialStates.myUserProfile, action) => {
    switch(action.type) {
        case "CHANGE_USER_PROFILE":
            return action.payload;
        case "RESET":
            return initialStates.myUserProfile;
        default:
            return state;
    }
}
const teamReducer = (state = initialStates.myTeam, action) => {
    switch(action.type) {
        case "CHANGE_TEAM":
            return action.payload;
        case "RESET":
            return initialStates.myTeam;
        default:
            return state;
    }
}
const companyReducer = (state = initialStates.myCompany, action) => {
    switch(action.type) {
        case "CHANGE_COMPANY":
            return action.payload;
        case "RESET":
            return initialStates.myCompany;
        default:
            return state;
    }
}
const chatsReducer = (state = initialStates.myChats, action) => {
    switch(action.type) {
        case "CHANGE_CHATS":
            return action.payload;
        case "RESET":
            return initialStates.myChats;
        default:
            return state;
    }
}
const ecosystemReducer = (state = initialStates.myEcosystem, action) => {
    switch(action.type) {
        case "CHANGE_ECOSYSTEM":
            return action.payload;
        case "RESET":
            return initialStates.myEcosystem;
        default:
            return state;
    }
}
const statisticsReducer = (state = initialStates.statistics, action) => {
    switch(action.type) {
        case "CHANGE_STATISTICS":
            return action.payload;
        case "RESET":
            return initialStates.statistics;
        default:
            return state;
    }
}
const matchingReducer = (state = initialStates.matching, action) => {
    switch(action.type) {
        case "CHANGE_MATCHING":
            return action.payload;
        case "RESET":
            return initialStates.matching;
        default:
            return state;
    }
}
const feedReducer = (state = initialStates.feed, action) => {
    switch(action.type) {
        case "CHANGE_FEED":
            return action.payload;
        case "RESET":
            return initialStates.feed;
        default:
            return state;
    }
}
const allReducers = combineReducers({
    myUserLogin: userLoginReducer,
    myUserProfile: userProfileReducer,
    myTeam: teamReducer,
    myCompany: companyReducer,
    myChats: chatsReducer,
    myEcosystem: ecosystemReducer,
    statistics: statisticsReducer,
    matching: matchingReducer,
    feed: feedReducer,
})
export default allReducers;
