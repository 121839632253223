//General Imports
import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "./Authentication";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";

// Route Wrapper for Guide (self upload person, upload company, search for company, etc.)
const GuideRoute = ({ component: RouteComponent, ...rest }) => {
  const { currentUser, accountType } = useContext(AuthContext);

  const { myUserProfile } = useSelector((state) => ({
    myUserProfile: state.myUserProfile, // Makes component run twice
  }))


  function renderAppropiateResponse(routeProps, currentUser, accountType) {
    switch (accountType) { // If the user is an accountType, just redirect them (shouldnt have to read company or self person)
      case "tier1":
        return <Redirect to={"/tier1/matching"} />;
      case "tier2":
        return <Redirect to={"/tier2/dashboard"} />;
      case "tier3":
        return <Redirect to={"/tier3/dashboard"} />;
      default:
        if (
          Object.keys(myUserProfile).length > 0 &&
          routeProps.history.location.pathname === "/guide/profile-set-up"
        ) {
          return <Redirect to={"/guide/profile-complete"} />;
        }
        return (
          <Container fluid>
            <RouteComponent
              {...routeProps}
              currentUser={currentUser}
              accountType={accountType}
            />
          </Container>
        );
    }
  }

  //If login is good, allow access or redirect to login
  if (!!currentUser) {
    return (
      <Route
        {...rest}
        render={(routeProps) => {
          return renderAppropiateResponse(routeProps, currentUser, accountType);
        }}
      />
    );
  } else {
    //Bad login
    return <Route {...rest} render={() => <Redirect to={"/login"} />} />;
  }
};

export default GuideRoute;
