// General Use
import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import png from "../../../DesignAssets/hook.png";

/*
  Profile Complete Page
  * After the profile is uploaded, this page is displayed to then ask the user where they want to go
  * Reason this is separate page completely is because we want the user to be directed here specifically if they have
  a user profile but not attached to a company
*/
const ProfileCompletePage = (props) => {
  return (
    <Row className="profile-complete-page guide-page">
      <Col className="guide-inner-page">
        <Row className="pc-guide-row">
          <div className="complete-img">
            <img src={png} alt="" />
          </div>
          <p className="p1">Congratulations!</p>
          <p className="p2">Your profile has been sucessfully filled out :)</p>
          <div className="button-container">
            <Button onClick={() => props.history.push("/guide/company-search")}>
              Search For My Company
          </Button>
            <Button onClick={() => props.history.push("/guide/create-company")}>
              Create My Company
          </Button>
          </div>
        </Row>
      </Col>
    </Row>
  );
};
export default ProfileCompletePage;
