// General Use
import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { ArrowLeft } from 'react-feather'
import ChangeLoginInfoSettings from "../Shared/ChangeLoginInfoSettings"
import NotificationSettings from "../Tier1/Components/NotificationSettings"
/*
    Pro(T1) Settings Page
    * Serves to go branch out to balance page
    * Also has the filters currently
*/
const Tier1SettingsPage = (props) => {

    const [changingLoginInfo, setChangingLoginInfo] = useState(false)

    return ( // Main Process wrapper
        <Row className="t1-page-wrapper t1-settings-page">
            <Col className="t1-inner-page">
                <Row className="t1s-top-matches">
                    <Col>
                        <Button className="go-back-bttn" onClick={() => props.history.goBack()}><ArrowLeft /></Button>
                        <h1 className="t1-header">Settings</h1>
                        <div className="get-more-match">
                            <h3 className="get-more-matches-header">Get More Matches</h3>
                            <Button onClick={() => props.history.push("/tier1/balance")}>Continue</Button>
                        </div>
                    </Col>
                </Row>
                {changingLoginInfo === false && (
                    <Row className="shared-settings-notifications">
                        <NotificationSettings />
                    </Row>
                )}
                <Row className="shared-settings-new-login">
                    <ChangeLoginInfoSettings {...props} setChangingLoginInfo={setChangingLoginInfo} />
                </Row>
                
            </Col>
        </Row>
    )
}

export default Tier1SettingsPage
