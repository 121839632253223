//General Imports
import React from "react";
import { Row, Col } from 'react-bootstrap';
import Page_Not_Found_Img from '../../DesignAssets/404_page_not_found_1.png';

/*
    Page Not Found
    - The 404 custom Coopsight page if the url does not exist in Coopsight
*/
const PageNotFound = (props) => {
    return (
        <Row className="main-page-wrapper page-not-found-main-page">
            <Col className="main-inner-page">
                <img className="page-not-found-main-img" src={Page_Not_Found_Img} alt="" />
                <p className="page-not-found-msg-header">The Page You are Requesting to View Does Not Exist!</p>
                <button
                    className="redirect-from-page-not-found"
                    onClick={() => { props.history.push('/') }}>Redirect Me</button>
            </Col>
        </Row>
    )
}

export default PageNotFound
