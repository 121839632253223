
export const selectThemeDropdown = theme => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary25: "#ddd5fb",
        primary: "#713ffe",
        primary50: '#c4acf8',
        //neutral0: '#f5f7fa',
        neutral0: '#f5f7fa',
        neutral80: 'black',
    },
    borderRadius: 2,
    outline: "none",
})

export const standardReactStyle = {
    control: (provided, state) => ({
        ...provided,
        boxShadow: "none",
        border: "none",
        padding: "4px 0px",
        fontSize: 16,
        fontFamily: "Poppins",
        height: "3em",
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        margin: '0',
        padding: '0px 8px',
        color: 'white'
    }),
    multiValue: (provided) => ({
        ...provided,
        color: '#713ffe;',
        background: '#ddd5fb',
    }),
    option: (base, state) => ({
        ...base,
        fontSize: 14,
        fontFamily: "Poppins",
        padding: "4px 12px",
        cursor: "pointer",
    })
}
export const expandedReactStyle = {
    control: (provided, state) => ({
        ...provided,
        boxShadow: "none",
        border: "none",
        padding: "4px 0px",
        fontSize: 16,
        fontFamily: "Poppins",
        height: "3em",
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        margin: '0',
        
        padding: '0',
    }),
    menuList: (provided, state) => ({
        ...provided,
        
        maxHeight: "184px",
    }),
    multiValue: (provided) => ({
        ...provided,
        color: '#713ffe;',
        background: '#ddd5fb',
    }),
    option: (base, state) => ({
        ...base,
        fontSize: 14,
        fontFamily: "Poppins",
        padding: "4px 12px",
        cursor: "pointer",
    })
}
