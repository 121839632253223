// General Use
import React from "react";
import { Button } from "react-bootstrap";

// Uploading Name
const CompanySetupName = (props) => {
    return (
        <section className="guide-company-process-3-wrapper">
            <p className="guide-profile-process-header">
                What is your company name?<span className="asterisk-required"> *</span>
            </p>
            <input
                value={props.form.companyName}
                placeholder="Enter your company name..."
                autoComplete="off"
                onChange={(e) => props.handleInputChange(e, "companyName")}
                onKeyPress={(e) => {
                    if (e.key === "Enter") props.goNext("companyName");
                }}
            />
            <div className="profile-error error-message">{props.errorMessage}</div>
            <div className="profile-buttons">
                <Button className="buttons-guide-back" onClick={() => props.goBack()}>
                    Back
          </Button>
                <Button
                    className="buttons-guide-continue"
                    disabled={
                        props.errorMessage.length > 0 || props.form.companyName.length === 0
                    }
                    onClick={() => props.goNext("companyName")}
                >
                    Continue
          </Button>
            </div>
        </section>
    );
};
export default CompanySetupName