// General Use
import React from "react";
import { Button } from "react-bootstrap";
import GuideToolTips from "../../Tier1/Components/GuideToolTips.js";
import Select from "react-select";
import { expandedReactStyle, selectThemeDropdown } from "../../Tier1/Components/ReactSelectStyles"
import { gridIndustryList } from "../../../GeneralUse/Variables";

// Uploading Industry
const CompanySetupIndustry = (props) => {
    return (
        <section className="guide-profile-process-3-wrapper">
            <p className="guide-profile-process-header">
                What industry best defines you?
                <GuideToolTips
                    description="Please select an industry that fits your business best. This information will not be visible to other open platform users, but it does let us offer extra match insights for you."
                    placement="right"
                />
            </p>
            <Select
                components={{ IndicatorSeparator: () => null }}
                theme={selectThemeDropdown}
                styles={expandedReactStyle}
                className="job-title-input"
                value={props.currentIndustry}
                onChange={(obj) => props.setIndustry(obj)}
                options={gridIndustryList.sort((a, b) => a.label < b.label)}
            />
            <div className="error-message">{props.errorMessage}</div>
            <div className="profile-buttons">
                <Button className="buttons-guide-back" onClick={() => props.goBack()}>
                    Back
                </Button>
                <Button
                    className="buttons-guide-continue"
                    disabled={props.currentIndustry === null}
                    onClick={() => props.goNext("industry")}
                >
                    Finish
                </Button>
            </div>
        </section>
    );
};
export default CompanySetupIndustry