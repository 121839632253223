// General Use
import React from "react";
import { Button } from "react-bootstrap";
import GuideToolTips from "../../Tier1/Components/GuideToolTips.js";

// Uploading Description
const CompanySetupDescription = (props) => {
    return (
        <section className="guide-company-process-4-wrapper">
            <p className="guide-profile-process-header">
                Provide a company description:<span className="asterisk-required"> *</span>
                <GuideToolTips
                    description="Make sure to provide a full company description, concisely expressing your product and business, since this will be the main piece of information seen by companies matching to you."
                    placement="right"
                />
            </p>
            <textarea
                value={props.form.description}
                placeholder="Enter your company description..."
                autoComplete="off"
                onChange={(e) => props.handleInputChange(e, "description")}
            />
            <div className="error-message-main">{props.errorMessage}</div>
            <div className="profile-buttons">
                <Button className="buttons-guide-back" onClick={() => props.goBack()}>
                    Back
                </Button>
                <Button
                    className="buttons-guide-continue"
                    disabled={
                        props.errorMessage.length > 0 || props.form.description.length === 0
                    }
                    onClick={() => props.goNext("description")}
                >
                    Continue
                </Button>
            </div>
        </section>
    );
};

export default CompanySetupDescription