// General Use
import React from "react";
import { Row, Col, Container } from 'react-bootstrap';
import CoopsightLogo from '../DesignAssets/coopsight-logo-nav.png';

const ErrorAuth = (_) => {
    return (
        <Container fluid className="auth-error-main-page">
            <Row className="outside-error-wrapper">
                <Col className="internal-error-wrapper">
                    <img className="error-img-logo" src={CoopsightLogo} alt="" />
                    <div className="bottom-error-border">
                        <h4 className="cl-header">Connection Lost</h4>
                        <p className="cl-intro">There was an issue connecting connecting to our servers. Please follow these steps to potentially fix the problem:</p>
                        <ul>
                            <li>
                                <span className="num-list-num">1.) </span><span className="num-list-para">Try refresh your page</span>
                            </li>
                            <li>
                                <span className="num-list-num">2.) </span><span className="num-list-para">Switch your browser</span>
                            </li>
                            <li>
                                <span className="num-list-num">3.) </span><span className="num-list-para">If you have a VPN enabled, try disabling it</span>
                            </li>
                            <li>
                                <span className="num-list-num">4.) </span><span className="num-list-para">If none of the previous steps work, please email us <a href="mailto:george@coopsight.com">george@coopsight.com</a></span>
                            </li>
                        </ul>
                        <p className="cl-outro">You may also try to connect later!</p>
                    </div>
                </Col>
            </Row>

        </Container>
    )
}
export default ErrorAuth



