//General Imports
import React, { useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux'

import { Square, CheckSquare } from "react-feather"
import myFirebase from "../../firebase"
import {
    changeMyUserLogin,
} from '../../Redux/actions';
/*

Plan of Action for referrals


*input code
- check the userProfileCollection to see if code exists
- if code exists:
    - adds matches to reciever and sender
- else return code not exist
*/

const Tier1BalancePage = (props) => {
    const dispatch = useDispatch()

    const { myUserLogin, myUserProfile } = useSelector(state => ({
        myUserLogin: state.myUserLogin,
        myUserProfile: state.myUserProfile,
    }))

    const [checkingCode, setCheckingCode] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
    const [codeValue, changeCodeValue] = useState("")
    async function handleSubmitCode(e) {
        e.preventDefault()
        if (codeValue.length === 0) {
            setErrorMessage("Add a user's referral code!")
            return
        }
        setCheckingCode(true)
        setErrorMessage("")
        setSuccessMessage("")
        try {
            const validateReferralCode = myFirebase.functions().httpsCallable('validateReferralCode')
            let response = await validateReferralCode({ userid: props.currentUser.uid, accountType: myUserLogin.accountType, code: codeValue })
            if (response.data.success) {
                myUserLogin.matches = response.data.newValues
                dispatch(changeMyUserLogin(myUserLogin))
                setSuccessMessage(response.data.message)
            } else {
                setErrorMessage(response.data.message)
            }
        } catch (error) { console.log(error) }
        setCheckingCode(false)
    }

    // eslint-disable-next-line no-unused-vars
    const [totalCodesUsed, setTotalCodesUsed] = useState(() => {
        let totalList = []
        if (!!myUserLogin.matches) {
            if (!!myUserLogin.matches.codesUsedt1) {
                totalList = totalList.concat(myUserLogin.matches.codesUsedt1)
            }
            if (!!myUserLogin.matches.codesUsedt2) {
                totalList = totalList.concat(myUserLogin.matches.codesUsedt2)
            }
        }
        return totalList
    })

    return (
        <Row className="t1-page-wrapper balance-page-t1">
            <Col className="t1-inner-page">
                <Row className="t1b-top-balance">
                    <Col>
                        <h1 className="t1-header">Balance</h1>
                        <div className="balance-tab-full">
                            <div className="left-side-t1f">
                                <h5 className="big-header-t1f">Matches Left</h5>
                                <div className="big-header-score-t1f">{myUserLogin.matches.currentBalance}</div>
                            </div>
                            <div className="right-side-t1f">
                                <h5 className="big-header-t1f">Matches Redeemed</h5>
                                <div className="big-header-score-t1f">{myUserLogin.matches.matchesRedeemed}</div>
                            </div>
                            <div className="bottom-t1f-balance">
                                <h5 className="small-header-score-t1f">Get More Matches</h5>
                                <Button onClick={() => props.history.push('/payment/main')}>Continue</Button>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="t1b-unique-code">
                    <Col>
                        <h5 className="unique-code-header">Your Unique Code</h5>
                        <div className="unique-code-inner">
                            <h6>{myUserProfile.name}'s Code</h6>
                            <p>Use the code below to get more matches with the promotions down below</p>
                            <div className="my-referral-code-wrapper">
                                <h5 className="my-referral-code">{myUserProfile.referralCode}</h5>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="t1b-earn-more-matches">
                    <Col>
                        <h5 className="more-matches-header">Ways to Earn More Matches</h5>
                        <div className="direct-recommendation-wrapper">
                            <h4 className="recommendation-title">Direct Recommendations</h4>
                            <p className="more-matches-description">Provide your code to other Pro users to receive an extra 5 matches per referred user using code <span className="code-used-in-text">{myUserProfile.referralCode}</span></p>
                            <div className="more-matches-extra">
                                <h6 className="more-matches-finished">{totalCodesUsed.length !== 5 ? <Square /> : <CheckSquare />}</h6>
                                <h6 className="matches-extra-number">+5</h6>
                                <h6 className="uses-left"><span className="left-emp">{5 - totalCodesUsed.length}</span> left</h6>
                            </div>
                        </div>
                        <div className="more-matches-generic-wrapper">
                            <h4 className="recommendation-title">Social Presence & Testimonial</h4>
                            <p className="more-matches-description">
                                Make or share a post on <a href="https://medium.com/@gc2350" target="blank">Medium</a>, <a href="https://www.linkedin.com/company/coopsight/" target="blank">Linkedin</a>, <a href="https://www.instagram.com/coopsight_official/?hl=en" target="blank">Instagram</a>, or <a href="https://twitter.com/coopsight" target="blank">Twitter</a> about your Coopsight experience and tag @Coopsight to receive 3 extra matches
                                </p>
                            <div className="more-matches-extra">
                                <h6 className="more-matches-finished"><Square /></h6>
                            </div>
                        </div>
                        <div className="more-matches-generic-wrapper">
                            <h4 className="recommendation-title">Direct Premier Recommendation</h4>
                            <p className="more-matches-description">Provide your code to Premier users (Corporate and VC guys you match with) to receive 10 extra matches per referred user using code {myUserProfile.referralCode}</p>
                            <div className="more-matches-extra">
                                <h6 className="more-matches-finished"><Square /></h6>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="t1b-enter-code">
                    <Col>
                        <div className="code-body">
                            <h6 className="code-header">Redemption Code</h6>
                            <p>Keep an eye out for Coopsight News for Promos</p>
                            <Form className="code-form" disabled={checkingCode} onSubmit={handleSubmitCode}>
                                <input
                                    name="code"
                                    placeholder="Enter your friend's referral code..."
                                    autoComplete="off"
                                    value={codeValue}
                                    disabled={checkingCode}
                                    onChange={(e) => { setErrorMessage(""); setSuccessMessage(""); changeCodeValue(e.target.value) }}
                                />
                                <p className="error-message">{errorMessage}</p>
                                <p className="success-message">{successMessage}</p>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
export default Tier1BalancePage;