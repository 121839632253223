// General Use
import React, { useState } from 'react';
import { Col, Button } from 'react-bootstrap';
import Switch from "react-switch";
import { useSelector } from 'react-redux';
import { convertTimestamp } from "../../../GeneralUse/FunctionBucket"
import { Tooltip } from '@material-ui/core';
import { Info } from 'react-feather'
import GuideToolTips from "./GuideToolTips";

// Must Also add to the notifications initial upload!
const NotificationSettings = () => {

    const feed = useSelector((state) => state.feed);
    const userEmail = useSelector((state) => state.myUserLogin.email)

    // Chat Notifications
    const [chatNotifsChecked, setChatNotifsChecked] = useState(() => {
        if (!!feed.notifications) {
            return feed.notifications.chatNotificationsEnabled
        }
        return false
    })
    async function handleChatSwitchChange(e) {
        setChatNotifsChecked(!chatNotifsChecked)
        await feed.notifications.selfref.update({ chatNotificationsEnabled: e })
    }

    // Match Notifications
    const [matchNotifsChecked, setMatchNotifsChecked] = useState(() => {
        if (!!feed.notifications) {
            return feed.notifications.emailNotificationsEnabled
        }
        return false
    })
    async function handleMatchSwitchChange(e) {
        setMatchNotifsChecked(!matchNotifsChecked)
        await feed.notifications.selfref.update({ emailNotificationsEnabled: e })
    }

    // Snooze Time
    const [lastEnabledSnooze, setSnooze] = useState(() => {
        if (!!feed.notifications) {
            if (!!feed.notifications.lastEnabledSnooze) {
                return convertTimestamp(feed.notifications.lastEnabledSnooze).full
            }
        }
        return false
    })
    async function snoozeClicked() {
        let snoozeTime = new Date()
        setSnooze("Just Now")
        await feed.notifications.selfref.update({ lastEnabledSnooze: snoozeTime })
    }

    // Main Rendering
    return (
        <Col className="col-wrapper">
            <h5 className="t1-subheading">Notifications <GuideToolTips
                description={`All emails and notifications from Coopsight will be sent to your login email of ${userEmail}.`}
                placement="right" /></h5>
            <div className="notifications-enabled-disabled">
                <div className="chat-notifications-wrapper">
                    <h6 className="notifications-header">Email Me New Chats</h6>
                    <Switch
                        className="notifications-switch"
                        onChange={handleChatSwitchChange}
                        checked={chatNotifsChecked}
                        disabled={true}
                        onColor="#c4acf8"
                        onHandleColor="#713ffe"
                        handleDiameter={30}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 4px rgba(0, 0, 0, 0.1)"
                        height={20}
                        width={48}
                    />
                </div>
                <div className="match-notifications-wrapper">
                    <h6 className="notifications-header">Email Me New Matches</h6>
                    <Switch
                        className="notifications-switch"
                        onChange={handleMatchSwitchChange}
                        checked={matchNotifsChecked}
                        onColor="#c4acf8"
                        onHandleColor="#713ffe"
                        handleDiameter={30}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 4px rgba(0, 0, 0, 0.1)"
                        height={20}
                        width={48}
                    />
                </div>
            </div>

        </Col>
    )
}
export default NotificationSettings
