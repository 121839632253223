//General Imports
import React, { useState } from 'react';

import Requests from "./Requests"
import { PopupSendEmail } from "./Popups"
import { ArrowLeft } from 'react-feather'

import {
    Row,
    Col,
    Button
} from "react-bootstrap";

// Redux Stuff
import { useSelector } from 'react-redux'

/*
    Team Management Component
    - View the entire team in your profile
*/
const TeamManagement = (props) => {
    const { myTeam } = useSelector(state => ({ myTeam: state.myTeam }))

    const [localMyAdmins, updateLocalMyAdmins] = useState(myTeam.admins)
    function updateTeamManagementPage(memberType, newList) {
        if (memberType === "admin") {
            updateLocalMyAdmins(newList)
        }
    }

    // For the Popup
    const [showPopup, triggerPopup] = useState(false)

    if (props.side === "tier1") {
        return (
            <Row className="t1-page-wrapper t1-team-management-page">
                <Col className="t1-inner-page">
                    <Row className="t1tm-top">
                        <Button className="go-back-bttn" onClick={() => props.goBack()}><ArrowLeft /></Button>
                        <h4 className="team-management-header">Team Members</h4>
                    </Row>

                    <Row className="t1tm-pending-requests">
                        <Col>
                            <h4 className="team-management-smaller-header">Pending Requests</h4>
                            <Requests
                                {...props}
                                updateTeamManagementPage={updateTeamManagementPage}
                            />
                        </Col>
                    </Row>

                    <Row className="t1tm-invite">
                        <Col>
                            <h4 className="team-management-smaller-header">Send an Invite Link</h4>
                            {showPopup && (<PopupSendEmail closePopup={() => triggerPopup(!showPopup)} />)}
                            <div className="send-email-bttn-wrapper">
                                <Button className="send-email-bttn" onClick={() => triggerPopup(!showPopup)}>Send Email Invite</Button>
                            </div>
                        </Col>
                    </Row>

                    <Row className="t1tm-approved-members">
                        <Col>
                            <h4 className="team-management-smaller-header">Approved Members</h4>
                            <ul className="my-team-display-table">
                                {localMyAdmins.map((eachAdmin, adminIndex) => {
                                    return (
                                        <li key={adminIndex} className="each-member-current-wrapper">
                                            <div className="each-member-current">
                                                <img
                                                    src={eachAdmin.profilePicture.fileURL}
                                                    alt=""
                                                    className="profile-picture-image"
                                                />
                                                <p>{eachAdmin.name}</p>
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                        </Col>
                    </Row>

                </Col>
            </Row>
        )
    } else {
        return (
            <Row className="t1-team-management-page">
                <Col className="t1-inner-page">
                    <Row className="t1tm-top">
                        <Button className="go-back-bttn" onClick={() => props.goBack()}><ArrowLeft /></Button>
                        <h4 className="team-management-header">Team Members</h4>
                    </Row>

                    <Row className="t1tm-pending-requests">
                        <Col>
                            <h4 className="team-management-smaller-header">Pending Requests</h4>
                            <Requests
                                {...props}
                                updateTeamManagementPage={updateTeamManagementPage}
                            />
                        </Col>
                    </Row>

                    <Row className="t1tm-invite">
                        <Col>
                            <h4 className="team-management-smaller-header">Send an Invite Link</h4>
                            {showPopup && (<PopupSendEmail closePopup={() => triggerPopup(!showPopup)} />)}
                            <div className="send-email-bttn-wrapper">
                                <Button className="send-email-bttn" onClick={() => triggerPopup(!showPopup)}>Send Email Invite</Button>
                            </div>
                        </Col>
                    </Row>

                    <Row className="t1tm-approved-members">
                        <Col>
                            <h4 className="team-management-smaller-header">Approved Members</h4>
                            <ul className="my-team-display-table">
                                {localMyAdmins.map((eachAdmin, adminIndex) => {
                                    return (
                                        <li key={adminIndex} className="each-member-current-wrapper">
                                            <div className="each-member-current">
                                                <img
                                                    src={eachAdmin.profilePicture.fileURL}
                                                    alt=""
                                                    className="profile-picture-image"
                                                />
                                                <p>{eachAdmin.name}</p>
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                        </Col>
                    </Row>

                </Col>
            </Row>
        )
    }


}
export default TeamManagement
