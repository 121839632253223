// General Use
import React, { useState, useEffect } from 'react'
import { Row, Col, Button } from 'react-bootstrap';
import { cleanScore, capitalizeName } from "../GeneralUse/FunctionBucket"
import { ReportMatchPopup, SelectChatWithT1Popup, } from "../Scenes/Tier1/Components/Popups"
import { Heart, X } from "react-feather"
import MatchScoreBar from "./MatchScoreBar"

// When the match to is a single company
export const OpenNetworkT1SingleLayout = (props) => {
    const { displayedMatch, likedCompany, likedScore, reportTriggered } = props

    return (
        <Col className="t1-inner-page t1-matching-main-single">
            <Row className="t1mm-matching-top-wrapper"> {/* Main Purple Top */}
                <Col className="t1-matching-top">
                    <Row className="match-label">{capitalizeName(displayedMatch.to_label)}</Row>
                    <Row className="match-score-main">
                        <Col>
                            <h2>{capitalizeName(displayedMatch.companyData.companyName)}</h2>
                            <MatchScoreBar score={cleanScore(displayedMatch.score)} />
                        </Col>
                    </Row>
                    <Row className="match-interests">
                        {!!displayedMatch.companyData.interests && (
                            <Col>
                                <ul className="match-list">
                                    {displayedMatch.companyData.interests.map((eachInterest, index) => {
                                        return (
                                            <li key={index} className="each-interest">{eachInterest}</li>
                                        )
                                    })}
                                </ul>
                            </Col>
                        )}
                    </Row>

                    {props.getSingleIndustriesSynergies().length !== 0 && (
                        <Row className="match-synergy">
                            <Col>
                                <h4>Industry Keywords</h4>
                                <ul className="synergy-list">
                                    {props.getSingleIndustriesSynergies().map((eachSynergy, index) => {
                                        return (
                                            <li key={index} className="each-synergy">{eachSynergy}</li>
                                        )
                                    })}
                                </ul>
                            </Col>
                        </Row>
                    )}

                    <Row className="match-synergy">
                        <Col>
                            <h4>Synergy Keywords</h4>
                            <ul className="synergy-list">
                                {props.getSingleSynergies().map((eachSynergy, index) => {
                                    return (
                                        <li key={index} className="each-synergy">{eachSynergy}</li>
                                    )
                                })}
                            </ul>
                        </Col>
                    </Row>
                </Col>
                <button
                    className="next-match-fix"
                    disabled={props.nextButtonDisabled}
                    onClick={() => props.goNextMatch("passed")}>
                    {props.nextButtonDisabled ? "Loading..." : <X size={36} />}
                </button>
                <button
                    className="like-match-attribute-main"
                    onClick={() => props.matchSuccess(null, cleanScore(displayedMatch.score), displayedMatch.companyData)}>
                    <Heart className="match-heart-icon-main" size="28" />
                </button>
            </Row>

            <Row className="t1mm-matching-body-wrapper"> {/* Minor Bottom */}
                <Col className="t1-matching-body">
                    <Row className="match-description">
                        <Col>
                            <h4>Description</h4>
                            <div>{displayedMatch.companyData.description}</div>
                        </Col>
                    </Row>
                    {!!displayedMatch.companyData.location && (
                        <Row className="match-location">
                            <Col>
                                <h4>Location</h4>
                                <div>{displayedMatch.companyData.location}</div>
                            </Col>
                        </Row>
                    )}
                    {!!displayedMatch.companyData.website && (
                        <Row className="match-website">
                            <Col>
                                <h4>Website</h4>
                                <div><a href={displayedMatch.companyData.website} target="_blank">{displayedMatch.companyData.website}</a></div>
                            </Col>
                        </Row>
                    )}
                    <Row className="report-match-wrapper">
                        <Button className="report-match-button" onClick={() => props.changeReportView(true)}>Report</Button>
                    </Row>
                </Col>
            </Row>

            {!!likedCompany &&
                <SelectChatWithT1Popup
                    likedCompany={likedCompany}
                    team={likedCompany.team}
                    score={likedScore}
                    matchSuccess={props.matchSuccess}
                    redirectToChat={props.redirectToChat}
                    closePopup={props.changeTeamView}
                />
            }
            {!!reportTriggered &&
                <ReportMatchPopup
                    userid={props.currentUser.uid}
                    myCompany={props.myCompany}
                    suspectCompany={displayedMatch.companyData}
                    closePopup={() => props.changeReportView(false)}
                />
            }
        </Col>
    )
}

// When the match to is either an investor or corporation
export const OpenNetworkT1ManyLayout = (props) => {
    const { displayedMatch, reportTriggered } = props

    function getScoreFromData(id) { return cleanScore(displayedMatch.child_scores[id]) }

    function getSynergyFromData(id) {
        if (!!displayedMatch[id]) { return Object.keys(displayedMatch[id]) }
        else return []
    }

    function prettyFunctValues() {
        let pretty = []
        displayedMatch.children.forEach(eachChildData => {
            pretty.push({
                ...eachChildData,
                score: getScoreFromData(eachChildData.selfid),
                synergyKeywords: getSynergyFromData(eachChildData.selfid),
            })
        })
        return pretty.sort((a, b) => { return b.score - a.score })
    }

    const [prettifyList, setPrettifyList] = useState(() => {
        return prettyFunctValues()
    })

    useEffect(() => {
        setPrettifyList(prettyFunctValues(displayedMatch.children))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayedMatch])

    return (
        <Col className="t1-inner-page t1-matching-main-many">
            <Row className="t1mm-matching-top-wrapper"> {/* Main Purple Top */}
                <Col className="t1-full-parent">
                    <Row className="match-label">{capitalizeName(displayedMatch.to_label)}</Row>
                    <Row className="match-score-main">
                        <Col>
                            <h2>{capitalizeName(displayedMatch.companyData.companyName)}</h2>
                            <div className="like-match-attribute"><Heart className="match-heart-icon" onClick={() => props.handleLike(displayedMatch.companyData, "name", cleanScore(displayedMatch.score))} /></div>
                            <MatchScoreBar score={cleanScore(displayedMatch.score)} />
                        </Col>
                    </Row>
                    <Row className="match-interests">
                        <Col>
                            <ul className="match-list">
                                {displayedMatch.companyData.interests.map((eachInterest, index) => {
                                    return (
                                        <li key={index} className="each-interest">{eachInterest}</li>
                                    )
                                })}
                            </ul>
                        </Col>
                    </Row>
                </Col>
                <button
                    className="next-match-fix"
                    disabled={props.nextButtonDisabled}
                    onClick={() => props.goNextMatch("passed")}>
                    {props.nextButtonDisabled ? "Loading..." : <X size={36} />}
                </button>
            </Row>
            <Row className="t1-full-bottom-switch">
                <ul className="t1-full-children-bottom-match">
                    {prettifyList.map((eachChildUnder, index) => {
                        return (
                            <li className="t1-full-each-matching-main" key={index}>
                                {/* */}
                                <Col className="t1-matching-main-single">
                                    <Row className="t1mm-matching-top-wrapper"> {/* Main Purple Top */}
                                        <Col className="t1-matching-top">
                                            <Row className="match-label">Startup</Row>
                                            <Row className="match-score-main">
                                                <Col>
                                                    <h2>{capitalizeName(eachChildUnder.companyName)}</h2>
                                                    <MatchScoreBar score={eachChildUnder.score} />
                                                </Col>
                                            </Row>
                                            <Row className="match-interests">
                                                <Col>
                                                    <ul className="match-list">
                                                        {eachChildUnder.interests.map((eachInterest, index) => {
                                                            return (
                                                                <li key={index} className="each-interest">{eachInterest}</li>
                                                            )
                                                        })}
                                                    </ul>
                                                </Col>
                                            </Row>
                                            <Row className="match-synergy">
                                                <Col>
                                                    <h4>Synergy Keywords</h4>
                                                    <ul className="synergy-list">
                                                        {props.getSingleSynergies().map((eachSynergy, index) => {
                                                            return (
                                                                <li key={index} className="each-synergy">{eachSynergy}</li>
                                                            )
                                                        })}
                                                    </ul>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                    <Row className="t1mm-matching-body-wrapper"> {/* Minor Bottom */}
                                        <Col className="t1-matching-body">
                                            <Row className="match-description">
                                                <Col>
                                                    <h4>Description</h4>
                                                    <div>{eachChildUnder.description}</div>
                                                </Col>
                                            </Row>
                                            {!!eachChildUnder.location && (
                                                <Row className="match-location">
                                                    <Col>
                                                        <h4>Location</h4>
                                                        <div>{eachChildUnder.location}</div>
                                                    </Col>
                                                </Row>
                                            )}
                                            <Row className="report-match-wrapper">
                                                <Button className="report-match-button" onClick={() => props.changeReportView(true)}>Report</Button>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>


                            </li>
                        )
                    })}
                </ul>
                {
                    !!props.likedCompany &&
                    <SelectChatWithT1Popup
                        likedCompany={props.likedCompany}
                        team={props.likedCompany.team}
                        score={props.likedScore}
                        matchSuccess={props.matchSuccess}
                        closePopup={props.changeTeamView}
                    />
                }
                {!!reportTriggered && <ReportMatchPopup userid={props.currentUser} closePopup={() => props.changeReportView(false)} />}
            </Row>
        </Col>
    )
}
