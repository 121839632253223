//General Imports
import React from "react";
import { Row, Col, Button } from 'react-bootstrap';

/*
    Incorrect Login Page
    - Triggers if you are signed into a specific account and try to access a different login
*/
const IncorrectLoginPage = (props) => {
    return (
        <Row className="main-page-wrapper incorrect-login-page-wrapper">
            <Col className=".main-incorrect-login-inner-page">
                <div className="error-box-wrapper">
                    <h1>Incorrect Login</h1>
                    <p>It appears you attempted to access a page that you did not have access viewing.</p>
                    <p>Please click on the button below to return to the landing page.</p>
                    <Button
                        className="incorrect-return-page-bttn"
                        onClick={() => { props.history.push('/') }}>Redirect Me</Button>
                </div>
            </Col>
        </Row>

    )
}

export default IncorrectLoginPage
