// General Use
import React from "react";
import myFirebase from "../../firebase";
import { capitalizeName } from "../../GeneralUse/FunctionBucket"
import { Row, Col, Button } from "react-bootstrap";
import { checkCompanyClaimed } from "../../GeneralUse/UploadFirebase"
import AsyncSelect from 'react-select/async';
import { sendVerificationEmail } from "../../GeneralUse/DevFunctionality"

import { connect } from 'react-redux';

/*
  Company Search Page
  - Searches for the companies to request to join
*/


function mapStateToProps(state) { return { reduxState: state } }

class CompanySearchPage extends React.Component {
  constructor(props) {
    super();
    this.state = {
      groupedOptions: [], // Because initial values depend on a database fetch, can initalize at empty for now
      selectedCompany: null,
      selectedCompanyData: null,

      addingRequest: false,
      requestSent: false,
      requestData: {},

      claimCompanyPopupTriggered: false,

    };
  }

  loadCompanyDocData = async (selectedCompany) => {
    try {
      let companyIsClaimed = await checkCompanyClaimed(selectedCompany.selfid)

      this.setState({
        selectedCompanyData: {
          selfref: selectedCompany.selfref,
          selfid: selectedCompany.selfid,
          companyName: selectedCompany.companyName,
          description: selectedCompany.description,
          location: selectedCompany.location,
          interests: selectedCompany.interests,
          type: selectedCompany.type,
          isClaimed: companyIsClaimed,
        },
      });
    } catch (error) {
      console.log("Error with getting companyData:", error);
    }
  };

  updateSelectionChange = (value) => {
    this.setState({
      selectedCompany: value.value,
    });
    this.loadCompanyDocData(value.value);
  };

  clearCompanyName = () => {
    this.setState({
      selectedCompany: null,
      selectedCompanyData: null,
    });
  };

  writeToFirestore = async () => {
    this.setState({ addingRequest: true })
    let requestInfo = {
      approved: false,
      deciderid: null,
      decisionTime: null, // This was the small bug that was not included initially, i added for when request is completed
      sent: new Date(),
      toCompany: this.state.selectedCompanyData.docid,
      userid: this.props.currentUser.uid,
    };
    await myFirebase.firestore().collection("joinRequests").add(requestInfo);
    requestInfo.requestType = "claimed"
    this.setState({ addingRequest: false, requestSent: true, requestData: requestInfo })
  };

  getSearchResults = async (companyNameInput) => {
    if (companyNameInput.length <= 1) { return new Promise() }
    // Must switch to Algoria
    let totalList = []
    let promises = []
    let t1Promise = this.getCollectionResults("tier1Network", companyNameInput, totalList)
    let t2Promise = this.getCollectionResults("tier2Network", companyNameInput, totalList)
    promises.push(t1Promise)
    promises.push(t2Promise)
    return Promise.all(promises).then(() => { return totalList })
  }
  getCollectionResults = (collectionName, companyNameInput, totalList) => {
    return myFirebase.firestore().collection(collectionName)
      .orderBy('companyName')
      .startAt(companyNameInput)
      .endAt(companyNameInput + "\uf8ff")
      .limit(5)
      .get().then(resSnapshots => {
        if (!resSnapshots.empty) {
          resSnapshots.forEach(eachRes => {
            let docData = eachRes.data()
            docData.selfid = eachRes.id
            docData.selfref = eachRes.ref
            docData.companyName = capitalizeName(docData.companyName)
            docData.collection = collectionName
            totalList.push({ label: capitalizeName(docData.companyName), value: docData })
          })
        }
      })
  }
  handleUnclaimed = async () => {
    await sendVerificationEmail({}, {})
  }

  handleMyCompany = async () => {
    const { selectedCompanyData } = this.state
    if (selectedCompanyData.isClaimed === false) {
      let myUserProfile = this.props.reduxState.myUserProfile
      this.setState({ addingRequest: true })
      await sendVerificationEmail(
        {
          name: myUserProfile.name,
          title: myUserProfile.jobTitle,
          email: this.props.currentUser.email,
          id: this.props.currentUser.uid,
        },
        {
          name: capitalizeName(selectedCompanyData.companyName),
          type:  capitalizeName(selectedCompanyData.type),
          description: selectedCompanyData.description,
          id: selectedCompanyData.selfid,
        }
      )
      selectedCompanyData.requestType = "unclaimed"
      this.setState({ addingRequest: false, requestSent: true, requestData: selectedCompanyData })
    } else {
      console.log("nope")
      this.setState({ addingRequest: false, requestSent: true, requestData: selectedCompanyData })
    }
    console.log("uwuw")
  }

  render() {
    const { selectedCompanyData, addingRequest, requestSent, requestData } = this.state
    const themeChildType = theme => ({
      ...theme,
      colors: {
        ...theme.colors,
        primary25: "#ddd5fb",
        primary: "#713ffe",
        primary50: '#c4acf8',
        //neutral0: '#f5f7fa',
        neutral0: '#f5f7fa',
        neutral80: 'black',
      },
      borderRadius: 10,
      outline: "none",
    })
    if (requestSent) {
      if (requestData.requestType === "claimed") {
        return (
          <Row className="company-search-page">
            <Col className="guide-inner-page">
              <Row className="csg-top-row">
                <Col>
                  <h4 className="company-setup-main-title">Company Setup</h4>
                </Col>
              </Row>
              <Row className="csg-request-sent">
                <Col>
                  <p className='request-sent-details'>
                    You have successfully requested to join {selectedCompanyData.companyName}. Please wait for a user at {selectedCompanyData.companyName} to accept your request.
                  </p>
                  <p className="request-sent-details-2">
                    Feel free to send an email to your current team to remind them to accept your request! While waiting, please check out our website <a href="https://coopsight.com/" target="blank">here</a> to learn more about what Coopsight offers!
                  </p>
                  <p className="request-sent-details-3">
                    Once you have been accepted, you will automatically be connected to the company on our Coopsight Web App.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        )
      } else {
        return (
          <Row className="company-search-page">
            <Col className="guide-inner-page">
              <Row className="csg-top-row">
                <Col>
                  <h4 className="company-setup-main-title">Company Setup</h4>
                </Col>
              </Row>
              <Row className="csg-request-sent">
                <Col>
                  <p className='request-sent-details'>
                    You have successfully requested to join {selectedCompanyData.companyName}. Please wait for a Coopsight representative to reach out to you for verification at your email <a href={`mailto:${this.props.currentUser.uid}`}>{this.props.currentUser.email}</a>!
                  </p>
                  <p className="request-sent-details-2">
                    You should here back from us within 1-2 days! While waiting, please check out our website <a href="https://coopsight.com/" target="blank">here</a> to learn more about what Coopsight offers!
                  </p>
                  <p className="request-sent-details-3">
                    Once you have been accepted, you will automatically be connected to the company on our Coopsight Web App.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        )
      }

    }
    return (
      <Row className="company-search-page">
        <Col className="guide-inner-page">
          <Row className="csg-top-row">
            <Col>
              <h4 className="company-setup-main-title">Company Setup</h4>
            </Col>
          </Row>
          <Row className="csg-middle-row">
            <Col>
              <p className="guide-profile-process-header">What's your company's name?</p>
              <AsyncSelect
                placeholder="Company Name"
                className="company-search-input"
                defaultOptions

                onChange={this.updateSelectionChange}
                loadOptions={this.getSearchResults}

                components={{ IndicatorSeparator: () => null }}
                theme={themeChildType}
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    boxShadow: "none",
                    border: "none",
                    padding: "4px 0px",
                    fontSize: 16,
                    fontFamily: "Poppins",
                    height: "3em",

                  }),
                  valueContainer: (provided, state) => ({
                    ...provided,
                    margin: '0',
                    padding: '4px 12px',
                    color: 'white',
                  }),
                  multiValue: (provided) => ({
                    ...provided,
                    color: '#713ffe;',
                    background: '#ddd5fb',

                  }),
                  option: (base, state) => ({
                    ...base,
                    fontSize: 16,
                    fontFamily: "Poppins",
                    padding: "8px 12px",
                    cursor: "pointer",

                  })
                }}

              />
              <Button
                className="setup-new-company"
                onClick={() => this.props.history.push('/guide/create-company')}>Setup a New Company</Button>
            </Col>
          </Row>


          {/* if selectedValue is activated/changed */}
          {selectedCompanyData && (
            <>
              <Row className="csg-bottom-info">
                <Col>
                  <p className="guide-profile-process-header">Is this your company? (Scroll down to confirm. If not, retype your company above)</p>
                </Col>
              </Row>
              <Row className="csg-bottom-row">
                <Row className="t1mm-matching-top-wrapper"> {/* Main Purple Top */}
                  <Col className="t1-matching-top">
                    <Row className="match-label">{capitalizeName(selectedCompanyData.type)}</Row>
                    {!selectedCompanyData.isClaimed && (
                      <Row className="match-label-claimed">
                        {"Unclaimed"}
                      </Row>
                    )}

                    <Row className="match-score-main">
                      <Col>
                        <h2>{capitalizeName(selectedCompanyData.companyName)}</h2>
                      </Col>
                    </Row>
                    <Row className="match-interests">
                      <Col>
                        <ul className="match-list">
                          {selectedCompanyData.interests.map((eachInterest, index) => {
                            return (
                              <li key={index} className="each-interest">{eachInterest}</li>
                            )
                          })}
                        </ul>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row className="t1mm-matching-body-wrapper"> {/* Minor Bottom */}
                  <Col className="t1-matching-body">
                    <Row className="match-description">
                      <Col>
                        <h4>Description</h4>
                        <div>{selectedCompanyData.description}</div>
                      </Col>
                    </Row>
                    {!!selectedCompanyData.location && (
                      <Row className="match-location">
                        <Col>
                          <h4>Location</h4>
                          <div>{selectedCompanyData.location}</div>
                        </Col>
                      </Row>
                    )}
                    <div className="confirm-as-mine-wrapper">
                      <Button
                        className="confirm-company-as-mine"
                        disabled={addingRequest}
                        onClick={this.handleMyCompany}>{addingRequest ? "Requesting..." : "Confirm as Mine"}</Button>
                    </div>
                  </Col>
                </Row>
              </Row>
            </>
          )}
        </Col>
      </Row>
    );
  }
}
export default connect(mapStateToProps)(CompanySearchPage);
