//General Imports
import React from 'react'
import ReactLoading from 'react-loading'

export const LoadingMediumIcon = () => {
    return (
        <div className="medium-loading-icon">
            <ReactLoading className="loading-icon" type={"spin"} color={"#713ffe"} height={80} width={80}></ReactLoading>
        </div>
    )
}

export const StandardFullPageLoading = () => {
    return (
        <div className="loader-page-standard-wrapper">
            <div className="loader-page-standard" />
            {/*<p className="loading-text">Loading...</p>*/}
        </div>

    )
}

export const LoadingFullPageIcon = () => {
    return (
        <div className="loading-full-page">
            <ReactLoading className="loading-icon" type={"spinningBubbles"} color={"#713ffe"} height={100} width={100} />
            <p className="loading-text">Loading</p>
        </div>
    )
}
export const LoadingSmallIcon = () => {
    return (
        <div className="small-loading-icon">
            <ReactLoading className="loading-icon" type={"spin"} color={"#713ffe"} height={64} width={64}></ReactLoading>
        </div>
    )
}

export const NewLoadingIcon = () => {
    return (
        <div className="new-loading-wrapper">
            <div className="spinner">
                <div className="right-side">
                    <div className="bar"></div>
                </div>
                <div className="left-side">
                    <div className="bar"></div>
                </div>
            </div>
        </div>
    )
}