
import myFirebase from "../firebase";
import { createCompanyRefKey } from "./FunctionBucket"
// Files should ALWAYS have the schema of
// [ { name: "someFileName.jpg", fileBlob: fileBlob }, ... ]

//const bucketName = process.env.REACT_APP_FIREBASE_STAGING_STORAGE
const bucketName = 'coopsight-open-network.appspot.com'

const defaultProfile = {
    name: 'dpp-startup-rice-craker.png',
    fileURL: 'https://firebasestorage.googleapis.com/v0/b/' + process.env.REACT_APP_FIREBASE_PRODUCTION_STORAGE + '/o/defaultProfilePictures%2Fdpp-corporation-rocketship.png?alt=media&token=b092206e-fa43-4daa-8777-fef87bc8b4d0',
    fileURI: 'gs://' + process.env.REACT_APP_FIREBASE_PRODUCTION_STORAGE + '/defaultProfilePictures/dpp-corporation-rocketship.png'
}

export function checkMe() {
    console.log(bucketName)
}

// For General Upload Files
export async function addFilesToDatabase(associatedDocid, files, updateWith = null) {
    try {
        // files have schema such that {fileName.pdf: fileMetaData...}
        const filesProcessed = await addFilesToStorage(associatedDocid, files)
        const localFiles = await addFilesToFirestore(associatedDocid, filesProcessed, updateWith)
        console.log("Finished adding files")
        return localFiles // List of files to be used
    } catch (error) {
        console.log("Error with Files being added to database?: ", error)
        return []
    }
}

// Adds Files to Storage
export function addFilesToStorage(associatedDocid, files, chat = null) {
    console.log(associatedDocid, files, chat)
    let uploadTaskPromises = [];
    let urlLinkPromises = [];
    let newFilesList = []

    if (files.length > 0) {
        files.forEach(async eachFile => {
            const parsedFile = { name: eachFile.name }
            let filePath = null
            if (chat === null) {
                filePath = `PrivateBins/${associatedDocid}/${eachFile.name}`
            } else {
                filePath = `${chat.path}/${eachFile.name}`
            }

            const storageRef = myFirebase.storage().ref(filePath)
            //Promises
            const uploadTask = storageRef.put(eachFile.fileBlob).then(placeRef => {
                const urlLink = storageRef.getDownloadURL().then((url) => {
                    parsedFile['fileURL'] = url
                    //const bucketName = myFirebase.storage().bucket_.bucket;
                    //parsedFile['fileURI'] = "gs://" + 'coopsightstaging.appspot.com' + "/" + placeRef.metadata.fullPath
                    parsedFile['fileURI'] = "gs://" + bucketName + "/" + placeRef.metadata.fullPath
                    newFilesList.push(parsedFile)
                });
                urlLinkPromises.push(urlLink)
            });
            uploadTaskPromises.push(uploadTask)
        })

    }
    return Promise.all(uploadTaskPromises).then(tasks => {
        return Promise.all(urlLinkPromises).then(() => {
            return newFilesList
        })
    })
}

// Adds Files To Firestore
export async function addFilesToFirestore(associatedDocid, files, updateWith) {
    let dataToAdd = {
        filesList: files,
        docid: associatedDocid,
        timestamp: new Date(),
    };
    let filesRef = null
    if (updateWith === null) {
        filesRef = await myFirebase.firestore().collection('privateBins').add(dataToAdd)
        dataToAdd.selfid = filesRef.id
        dataToAdd.selfref = filesRef
    } else {
        dataToAdd.filesList = dataToAdd.filesList.concat(updateWith.oldFiles)
        filesRef = await updateWith.fileBinRef.update(dataToAdd)
    }

    return dataToAdd
}

// Create Company
export async function addCompanyFirestore(data, tier, companyid = null) {
    try {
        if (companyid === null) {
            const dataToAdd = {
                type: data.type,
                companyName: data.companyName.toLowerCase(),
                description: data.description,
                location: data.location,
                interests: data.interests,
                website: data.website
            }
            if (tier !== "tier1Network") {
                dataToAdd.childTypes = []
            }
            const companyRef = await myFirebase.firestore().collection(tier).add(dataToAdd)
            dataToAdd.selfid = companyRef.id
            dataToAdd.selfref = companyRef
            return dataToAdd
        }
    } catch (err) { console.log("There was an error with creating company: ", err) }
}



// For updating userid profile when the company is created
export async function updateProfileWithCompany(userid, newCompanyDocid) {
    try {
        // Should carry ref with redux but if not then
        const myUserProfileSnapshots = await myFirebase.firestore().collection('userProfiles').where('userid', '==', userid).get()
        const myUserProfileRef = myUserProfileSnapshots.docs[0].ref
        await myUserProfileRef.update({ companyDocid: newCompanyDocid })

        const dataReceived = myUserProfileSnapshots.docs[0].data()
        dataReceived.companyDocid = newCompanyDocid
        dataReceived.selfid = myUserProfileSnapshots.docs[0].id
        dataReceived.selfref = myUserProfileRef
        return dataReceived
    } catch (err) {
        console.log("Error with updating profile with company: ", err)
        return {}
    }
}


// Creates New Chat between two users
export async function createNewChatRoom(userids) {
    try {
        const dataToAdd = {
            members: userids,
            chatCreated: new Date(),
        }
        const chatRoomRef = await myFirebase.firestore().collection('chats').add(dataToAdd)
        dataToAdd.selfid = chatRoomRef.id
        dataToAdd.selfref = chatRoomRef
        dataToAdd.messages = { texts: [], files: [], pitchdecks: [] }
        return dataToAdd
    } catch (err) { console.log(err) }
}


// Add Profile Picture
export async function uploadProfilePicture(userid, file) {
    try {
        let ppfile = null
        if (file === null) {
            let parsedFile = defaultProfile
            return parsedFile
        } else {
            ppfile = file
            const parsedFile = { name: ppfile.name }
            let filePath = `ProfilePictures/${userid}/${ppfile.name}`
            const storageRef = myFirebase.storage().ref(filePath)

            const placeRef = await storageRef.put(ppfile)
            
            let url = await storageRef.getDownloadURL()

            parsedFile['fileURL'] = url
            parsedFile['fileURI'] = "gs://" + bucketName + "/" + placeRef.metadata.fullPath

            return parsedFile
        }

    } catch (error) {
        console.log(error);
        return defaultProfile
    }
}

// Editable
export async function updateUserProfile(currentUserProfile, newValues) {
    try {
        let whatNeedsToUpdate = {}
        for (var key in newValues) {
            if (key === "profilePicture") {
                if (newValues[key].file !== null) {
                    const profilePicFile = await uploadProfilePicture(currentUserProfile.userid, newValues[key].file)
                    whatNeedsToUpdate[key] = profilePicFile
                    currentUserProfile[key] = profilePicFile
                }
            } else if (currentUserProfile[key] !== newValues[key]) {
                whatNeedsToUpdate[key] = newValues[key]
                currentUserProfile[key] = newValues[key]
            }
        }
        await currentUserProfile.selfref.update(whatNeedsToUpdate)
        return { userProfile: currentUserProfile, success: true, message: "Sucessfully Updated User Profile!" }
    } catch (err) {
        console.log(err)
        return { userProfile: currentUserProfile, success: false, message: "Was Unable to Update User Profile!" }
    }
}

// Updates Email in UserLogin
export async function updateUserLoginEmail(myUserLogin, newEmail) {
    try {
        await myUserLogin.selfref.update({ email: newEmail })
    } catch (error) {
        console.log(error)
    }
}

// Updates my Company with appropiate info
export async function updateCompany(companyToUpdate, newValues) {
    try {
        let whatNeedsToUpdate = {}
        for (var key in newValues) {
            if (companyToUpdate[key] !== newValues[key]) {
                if (key === "companyName") {
                    whatNeedsToUpdate[key] = newValues[key].toLowerCase()
                    companyToUpdate[key] = newValues[key].toLowerCase()
                } else {
                    whatNeedsToUpdate[key] = newValues[key]
                    companyToUpdate[key] = newValues[key]
                }
            }
        }
        await companyToUpdate.selfref.update(whatNeedsToUpdate)
        return { company: companyToUpdate, success: true, message: "Sucessfully Updated Company!" }
    } catch (err) {
        console.log(err)
        return { company: companyToUpdate, success: false, message: "Was Unable to Update Company! Please Try Again Later!" }
    }
}

// Upload File Chat
export async function uploadToChat(currentChatRoom, senderid, files) {
    try {
        console.log('er')
    } catch (err) {
        console.log("err")
    }
}


// Create Child T2 
export async function addChildFirestore(parentRef, childSet, newChildData, t1Ref = null) {
    //console.log(parentRef, childSet, newChildData)
    try {
        //await addFilesToDatabase(docid, files, updateWith = null)
        const dataToAdd = {
            companyName: newChildData.companyName.toLowerCase(),
            description: newChildData.description,
            location: newChildData.location,
            interests: newChildData.interests,
            t1Ref: t1Ref,
        }

        let childRef = await parentRef.collection(childSet.typeid).add(dataToAdd)
        dataToAdd.selfid = childRef.id
        dataToAdd.selfref = childRef

        let localFiles = await addFilesToDatabase(childRef.id, newChildData.files)
        dataToAdd.files = localFiles
        dataToAdd.privateFiles = []
        return dataToAdd

    } catch (error) { console.log("Error with adding a child firestore", error) }
}

// Check if Company is unclaimed (no users attached to it)
export async function checkCompanyClaimed(companyDocid) {
    try {
        let teamsSnapshot = await myFirebase.firestore().collection('teams').where('companyDocid', '==', companyDocid).get()
        if (!teamsSnapshot.empty) {
            const teamData = teamsSnapshot.docs[0].data()
            if (teamData.admins.length === 0 && teamData.members.length === 0) {
                return false
            } else {
                return true
            }
            
        }
    } catch(error) {
        console.log(error)
        return false
    }
}