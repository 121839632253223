// General Use
import myFirebase from "../firebase";
import { getUserProfile, getCompanyTypeByDocid, capitalizeName } from "../GeneralUse/FunctionBucket";
import { populateDeck, generateBackendMatches, getCompanyTeam } from "../GeneralUse/PrimeFunctions";
import { continentList } from "../GeneralUse/CountriesWithContinents"

// Main Loader - Loads appropiate data based on the accountType
export default async function loadMyData(userid, accountType) {
  console.log("User Authenticated: ", userid, accountType)

  // Preliminary LoadedData
  const loadedData = {
    myUserLogin: {},
    myUserProfile: {},
    myTeam: {},
    myCompany: {},
    myChats: {
      chatRooms: [],
      requests: [],
    },
    myEcosystem: [],
    statistics: {},
    matching: {
      matchMe: {},
      deck: [],
      hand: {},
      filters: {},
    },
    feed: {
      notifications: {},
    }
  }

  switch (accountType) {
    case "tier1":
      return loadTier1Data(userid, loadedData)
    case "tier2":
      return loadTier2Data(userid, loadedData)
    case "tier3":
      return loadTier3Data(userid, loadedData)
    default:
      return loadGuideData(userid, loadedData)
  }
}

// Note for ALL function wrappers: Must be run in order provided because of dependencies

// Guide Account Type PreLoadFunctions
async function loadGuideData(userid, loadedData) {
  try {
    await loadMyUserLogin(userid, loadedData);
    await loadMyUserProfile(userid, loadedData);
  } catch (err) {
    throw new Error(`Failed to Retrieve Backend Data - ${err}`);
  }
  return loadedData;
}

// Pro(T1) Account Type PreLoadFunctions
async function loadTier1Data(userid, loadedData) {
  try {
    let preloadPromises = [];
    preloadPromises.push(loadMyUserLogin(userid, loadedData));
    preloadPromises.push(loadMyTeam(userid, loadedData));
    preloadPromises.push(loadMyUserProfile(userid, loadedData));
    preloadPromises.push(getMyMatch(userid, loadedData));
    preloadPromises.push(loadMyNotifications(userid, loadedData));
    await Promise.all(preloadPromises);
    await loadMyChats(userid, loadedData)
    await getMyFilters(userid, loadedData)
    await loadMyCompany(loadedData);
    await getDeckT1(userid, loadedData);
  } catch (err) {
    throw new Error(`Failed to Retrieve Backend Data - ${err}`);
  }
  return loadedData;
}

// Premier(T2) Account Type PreLoadFunctions
async function loadTier2Data(userid, loadedData) {
  try {
    await loadMyUserLogin(userid, loadedData);
    await loadMyTeam(userid, loadedData);
    await loadMyUserProfile(userid, loadedData);
    await getMyMatch(userid, loadedData);
    await loadMyCompany(loadedData);
    await loadMyChats(userid, loadedData);
    await loadMyEcosystem(loadedData);
    await getDeckT2(userid, loadedData);
  } catch (err) {
    throw new Error(`Failed to Retrieve Backend Data - ${err}`);
  }
  return loadedData;
}

// Enterprise(T3) AccountType PreLoadFunctions
async function loadTier3Data(userid, loadedData) {
  await loadMyUserLogin(userid, loadedData);
  await loadMyTeam(userid, loadedData);
  await loadMyUserProfile(userid, loadedData);
  await loadMyCompany(loadedData);
  await loadMyChats(userid, loadedData);

  return loadedData;
}

/// PRE LOAD HELPER FUNCTIONS ///

// Loads My User Login
async function loadMyUserLogin(userid, loadedData) {
  let userLoginsSnapshot = null;
  let matchesRef = null;
  try {
    userLoginsSnapshot = await myFirebase.firestore().collection("userLogins").where("userid", "==", userid).get();
    if (!userLoginsSnapshot.empty) {
      const userLoginDoc = userLoginsSnapshot.docs[0]
      loadedData.myUserLogin = userLoginDoc.data()
      loadedData.myUserLogin.selfid = userLoginDoc.id;
      loadedData.myUserLogin.selfref = userLoginDoc.ref;
    }
  } catch (error) {
    console.log("Error Caught: loadMyUserLogin from PreLoadFunctions", error);
  }
  return Promise.all([userLoginsSnapshot, matchesRef]);
}

// Loads My User Team
async function loadMyTeam(userid, loadedData) {
  try {
    let teamPromises = [];
    let teamsSnapshot = null;

    // Teams can consist of either members or admins (feature not really used now, but still in schema)
    // - Should handle both the admins and the members

    // Finds my associated team (where my userid appears)
    teamsSnapshot = await myFirebase.firestore().collection("teams").where("admins", "array-contains", userid).get();
    if (teamsSnapshot.empty) {
      teamsSnapshot = await myFirebase.firestore().collection("teams").where("members", "array-contains", userid).get();
    }
    if (!teamsSnapshot.empty) {
      loadedData.myTeam = teamsSnapshot.docs[0].data();
      loadedData.myTeam.selfid = teamsSnapshot.docs[0].id;
      loadedData.myTeam.selfref = teamsSnapshot.docs[0].ref;

      // After getting the team, replaces their userid (how it appears in the database) with their userProfile
      loadedData.myTeam.members.forEach((eachMemberUserid, index) => {
        let memberProfilePromise = myFirebase.firestore().collection("userProfiles").where("userid", "==", eachMemberUserid).get()
          .then((memberProfileSnapshot) => {
            if (!memberProfileSnapshot.empty) {
              loadedData.myTeam.members[index] = memberProfileSnapshot.docs[0].data()
            }
          });
        teamPromises.push(memberProfilePromise);
      });
      loadedData.myTeam.admins.forEach((eachAdminUserid, index) => {
        let adminProfilePromise = myFirebase.firestore().collection("userProfiles").where("userid", "==", eachAdminUserid).get()
          .then((adminProfileSnapshot) => {
            if (!adminProfileSnapshot.empty) {
              loadedData.myTeam.admins[index] = adminProfileSnapshot.docs[0].data()
            }
          });
        teamPromises.push(adminProfilePromise);
      });
    }
    return Promise.all(teamPromises);
  } catch (error) { console.log("Error Caught: loadMyTeam from PreLoadFunctions", error); }
}

// Loads My User Profile
async function loadMyUserProfile(userid, loadedData) {
  let myUserProfileSnapshot = null;
  try {
    myUserProfileSnapshot = await myFirebase.firestore().collection("userProfiles").where("userid", "==", userid).get()
    if (!myUserProfileSnapshot.empty) {
      loadedData.myUserProfile = myUserProfileSnapshot.docs[0].data();
      loadedData.myUserProfile.selfid = myUserProfileSnapshot.docs[0].id;
      loadedData.myUserProfile.selfref = myUserProfileSnapshot.docs[0].ref;

      // Legacy Code may not contain the filters so need to have incase !exist
      if (!loadedData.myUserProfile.currentMatchFilters) {
        if (loadedData.myUserProfile.accountType === "tier2") {
          loadedData.myUserProfile.currentMatchFilters = ["investor", "corporation"]
        } else {
          loadedData.myUserProfile.currentMatchFilters = ["startup"];
        }
      }
    }
  } catch (error) {
    console.log("Error Caught: loadMyUserProfile from PreLoadFunctions", error);
  }
  return Promise.all([myUserProfileSnapshot]);
}

// Loads My User Company
async function loadMyCompany(loadedData) {
  try {
    let companyDoc = await loadedData.myTeam.companyRef.get(); // here is error
    if (!companyDoc.empty) {
      loadedData.myCompany = companyDoc.data();
      loadedData.myCompany.companyName = capitalizeName(
        loadedData.myCompany.companyName
      );
      loadedData.myCompany.selfid = companyDoc.id;
      loadedData.myCompany.selfref = companyDoc.ref;
      try {
        // Recieves the privateBin (files) attached to the company since separated in database

        // anyone can create files to privateBins, only if its your files can you read and update
        let companyFilesDoc = await myFirebase.firestore().collection("privateBins").where("docid", "==", companyDoc.id).get();
        if (!companyFilesDoc.empty) {
          loadedData.files = companyFilesDoc.docs[0].data();
          loadedData.files.selfid = companyFilesDoc.docs[0].id;
          loadedData.files.selfref = companyFilesDoc.docs[0].ref;
        }
        let companyIndustryDoc = await myFirebase.firestore().collection("industries").where("docid", "==", companyDoc.id).get();
        if (!companyIndustryDoc.empty) {
          loadedData.myCompany.industryKeywords = companyIndustryDoc.docs[0].data();
          loadedData.myCompany.industryKeywords.selfid = companyIndustryDoc.docs[0].id;
          loadedData.myCompany.industryKeywords.selfref = companyIndustryDoc.docs[0].ref;
        }
      } catch (error) {
        console.log("Error Caught: loadMyCompany privateBin from PreLoadFunctions", error);
      }
    }
  } catch (error) {
    console.log("Error Caught: loadMyCompany from PreLoadFunctions", error);
  }
}

// Loads My Ecosystem (the children under me)
async function loadMyEcosystem(loadedData) {
  try {
    // For T2 users, we need to get their children and we use their childTypes array to find the subcollections under
    const myCompanyRef = loadedData.myCompany.selfref;
    if (!!loadedData.myCompany.childTypes) {
      // Has Children in Ecosystem
      let allChildrenPromises = [];
      loadedData.myCompany.childTypes.forEach((each) => {
        // Gets the different child types (ex. startups, funds, projects)
        const eachCopy = { ...each };
        let children = [];
        let childPromise = myCompanyRef.collection(each.typeid).get()
          .then((snapshot) => {
            if (!snapshot.empty) {
              snapshot.forEach(async (eachChildDoc) => {
                // Gets the children in each child type (ex. Facebook, Equity Partners, Bike Project)
                let childData = eachChildDoc.data();
                childData.selfid = eachChildDoc.id;
                childData.selfref = eachChildDoc.ref;

                // Gets the attached private bins like in myCompany
                allChildrenPromises.push(
                  myFirebase.firestore().collection("privateBins").where("docid", "==", eachChildDoc.id).get()
                    .then((companyFilesDocSnapshot) => {
                      if (!companyFilesDocSnapshot.empty) {
                        childData.files = companyFilesDocSnapshot.docs[0].data();
                        childData.files.selfid = companyFilesDocSnapshot.docs[0].id;
                        childData.files.selfref = companyFilesDocSnapshot.docs[0].ref;
                      }
                    })
                );
                childData.privateFiles = { filesList: [] };
                children.push(childData);
              });
            }
          });
        eachCopy.children = children;
        allChildrenPromises.push(childPromise);
        loadedData.myEcosystem.push(eachCopy);
      });
      return Promise.all(allChildrenPromises);
    } else {
      // Has NO children in ecosystem
      loadedData.myCompany.childTypes = [];
    }
  } catch (error) { console.log("Error Caught: loadMyEcosytem from PreLoadFunctions", error) }
}

// Gets My Notifications
async function loadMyNotifications(userid, loadedData) {
  try {
    const notifsSnapshot = await myFirebase.firestore().collection("notifications").doc(userid).get();
    if (notifsSnapshot.exists) {
      loadedData.feed.notifications = notifsSnapshot.data()
      loadedData.feed.notifications.selfref = notifsSnapshot.ref
    } else {
      let notifsObj = {
        lastLoggedIn: new Date(),
        lastViewedChat: [],
        chatNotificationsEnabled: false,
        emailNotificationsEnabled: false,
        lastEnabledSnooze: null,
        userid: userid,
      }
      await myFirebase.firestore().collection("notifications").doc(userid).set(notifsObj);
      loadedData.feed.notifications = notifsObj
      loadedData.feed.notifications.selfref = myFirebase.firestore().collection("notifications").doc(userid)
    }
  } catch (error) { console.log(error) }
}

// Loads My Chats
async function loadMyChats(userid, loadedData) {
  try {
    const chatsSnapshot = await myFirebase.firestore().collection("chats").where("members", "array-contains", userid).get();
    if (!chatsSnapshot.empty) {
      loadedData.myChats.chatRooms = [];
      loadedData.myChats.requests = [];
      loadedData.myChats.sortedBy = "recent";
      let chatPromises = [];

      // get the array of {chatroomid, timestamp}
      let lastViewedTimestamps = null
      if (!!loadedData.feed) { lastViewedTimestamps = loadedData.feed.notifications.lastViewedChat; }

      // Gets chatrooms (where my userid appears)
      chatsSnapshot.forEach(async (chatRoom) => {
        let eachChatRoomData = chatRoom.data();
        eachChatRoomData.selfid = chatRoom.id;
        eachChatRoomData.selfref = chatRoom.ref;
        eachChatRoomData.messages = { aggregate: [] };
        // Replaces the userids with their userProfile (like we do in teams)
        eachChatRoomData.members.forEach(
          (eachMemberUserid, eachMemberIndex) => {
            let userProfilePromise = getUserProfile(eachMemberUserid)
              .then((userProfileMember) => {
                if (!userProfileMember.empty) {
                  eachChatRoomData.members[eachMemberIndex] = userProfileMember;
                } else {
                  eachChatRoomData.members[eachMemberIndex] = { userid: eachMemberUserid }
                }
                return getCompanyTypeByDocid(userProfileMember.companyDocid)
                  .then((chatWithAccountType) => {
                    eachChatRoomData.members[eachMemberIndex].companyType = chatWithAccountType;
                  });
              });
            chatPromises.push(userProfilePromise);
          }
        );
        if (!!eachChatRoomData.waitingOnCompany) {
          // If there is no user attached to the company
          let companyNoUserDoc = await eachChatRoomData.waitingOnCompany.get();
          if (!companyNoUserDoc.empty) {
            let waitingOnCompany = companyNoUserDoc.data();
            waitingOnCompany.selfid = companyNoUserDoc.id;
            eachChatRoomData.waitingOnCompany = waitingOnCompany;
            eachChatRoomData.waitingOnCompany.team = await getCompanyTeam(waitingOnCompany.selfid);
          }
        }

        // Note: chatRooms have 3 subcollections "texts", "files", "pitchdecks" so we need to read them all then parse
        // * Adds all to aggregate

        // first check the chatroomid rein array lastViewedTimestamps
        // get the corresponding timesreatamp
        let lastViewedTimestampForThisChatroom = null
        if (!!lastViewedTimestamps) {
          lastViewedTimestamps.forEach((eachChat) => {
            if (eachChat.chatRoomid === eachChatRoomData.selfid) {
              lastViewedTimestampForThisChatroom = eachChat.lastViewed;
            }
          });
        }
        // Reads text messages (ex. "omg lol ur so funny brad", "haha thanks :)")
        let textsCollectionPromise = chatRoom.ref.collection("texts").get()
          .then((textDocs) => {
            if (!textDocs.empty) {
              var unreadMessagesCount = 0;
              textDocs.forEach(async (textDoc) => {
                const docData = textDoc.data();
                docData.selfref = textDoc.ref;
                // (loop through all chatrooms) for each chat, check read/unread
                if (lastViewedTimestampForThisChatroom !== undefined) {
                  const textSendTimestamp = docData.timestampSent;
                  if (textSendTimestamp > lastViewedTimestampForThisChatroom) {
                    docData.newMsg = true;
                    unreadMessagesCount = unreadMessagesCount + 1;
                  } else {
                    docData.newMsg = false;
                  }
                }
                eachChatRoomData.messages.aggregate.push(docData);
              });
              eachChatRoomData.unreadMessagesCount = unreadMessagesCount;
            }
          });

        // Reads file messages (ex. "annual_report_apple.pdf", "cool_excel_sheet.xslx")
        let filesCollectionPromise = chatRoom.ref.collection("files").get()
          .then((fileDocs) => {
            if (!fileDocs.empty) {
              fileDocs.forEach((fileDoc) => {
                const docData = fileDoc.data();
                docData.selfref = fileDoc.ref;
                eachChatRoomData.messages.aggregate.push(docData);
              });
            }
          });

        // Reads pitchdeck messages (ex. "my_pitchdeck.pptx", "PENDING pitchdeck", "REJECTED pitchdeck")
        let pitchdecksCollectionPromise = chatRoom.ref.collection("pitchdecks").get()
          .then((pitchdeckDocs) => {
            if (!pitchdeckDocs.empty) {
              pitchdeckDocs.forEach((pitchdeckDoc) => {
                const docData = pitchdeckDoc.data();
                docData.selfref = pitchdeckDoc.ref;
                eachChatRoomData.messages.aggregate.push(docData);
              });
            }
          });

        // Gets the chatroom score if it exists
        //eachChatRoomData.score = !!eachChatRoomData.scoreSnapshot ? eachChatRoomData.scoreSnapshot : 0

        // Updates the loadedData
        loadedData.myChats.chatRooms.push(eachChatRoomData);

        chatPromises.push(textsCollectionPromise);
        chatPromises.push(filesCollectionPromise);
        chatPromises.push(pitchdecksCollectionPromise);
      });
      await Promise.all(chatPromises);

      loadedData.myChats.chatRooms = loadedData.myChats.chatRooms.filter(
        (chatRoom) => {
          // Checks if has companyData attached
          let attributeCheckFlag = true
          chatRoom.members.forEach((memberCheck) => {
            if (!memberCheck.companyData) {
              attributeCheckFlag = false
            }
          })

          if (attributeCheckFlag === false) {
            return false
          }

          // Sort ALL chat msgs based on recent
          chatRoom.messages.aggregate.sort(function (a, b) {
            return (new Date(a.timestampSent * 1000) - new Date(b.timestampSent * 1000));
          });

          // Checks if it is a new request (I did NOT message in chat && I did NOT initiate the chat)
          let isNewRequest = false;
          if (!!chatRoom.initiatedByUser) {
            if (chatRoom.initiatedByUser !== userid) {
              isNewRequest = true;
              chatRoom.messages.aggregate.forEach((eachMsg) => {
                if (eachMsg.sender === userid) { isNewRequest = false }
              });
            }
          }

          if (isNewRequest) {
            // If its a request to me, then removes from chatRooms and puts into requests
            loadedData.myChats.requests.push({
              chatRoom: chatRoom,
              otheruser: chatRoom.members.filter((obj) => { return obj.userid !== userid })[0]
            });
          }
          return isNewRequest === false;
        }
      );
    } else {
      loadedData.myChats.chatRooms = [];
    }
  } catch (error) {
    console.log("Error Caught: loadMyChats from PreLoadFunctions", error);
  }
}

// Loads My User Matches (Basic Match Info, NOT history)
async function getMyMatch(userid, loadedData) {
  try {
    const userMatchRef = await myFirebase.firestore().collection("userMatches").doc(userid).get();
    if (!userMatchRef.empty) {
      loadedData.myUserLogin.matches = userMatchRef.data();
    }
  } catch (_) {
    // For legacy, if the person does NOT have the basic matchInfo
    const dataToAdd = {
      currentBalance: 5,
      lastActivity: null,
      matchesRedeemed: 0,
      totalMatchesRedeemedLeft: 0,
      totalPeopleReferred: 0,
      userid: userid,
    };
    try {
      const newUserMatchRef = await myFirebase.firestore().collection("userMatches").doc(userid).set(dataToAdd);
      if (!newUserMatchRef) {
        loadedData.myUserLogin.matches = dataToAdd;
      }
    } catch (error) {
      console.log("Error Caught: getMyMatch from PreLoadFunctions", error);
    }
  }
}

async function getMyFilters(userid, loadedData) {
  try {
    const userFiltersRef = await myFirebase.firestore().collection('userFilters').doc(userid).get()
    if (userFiltersRef.exists) {
      const userFiltersData = userFiltersRef.data()
      const parsedUserFilterData = { continents: userFiltersData.continents }
      parsedUserFilterData.selfid = userFiltersRef.id
      loadedData.matching.filters = parsedUserFilterData
    } else {
      await myFirebase.firestore().collection('userFilters').doc(userid).set({ userid: userid, continents: [...continentList] })
      const newParsedUserFilterData = { continents: [...continentList] }
      newParsedUserFilterData.selfid = userid
      loadedData.matching.filters = newParsedUserFilterData
    }
  } catch (error) {
    console.log(error)
  }
}



/// MATCHING ///
// See src/Planning/MatchingPlan for more info

// Loads My Deck(T2)
export async function getDeckT1(userid, loadedData, newlyCreatedParent = false) {
  try {
    let parentMatchSnapshot = await myFirebase.firestore().collection("matchCache").doc(userid).get();
    if (parentMatchSnapshot.exists) {
      let parentMatchData = parentMatchSnapshot.data();
      parentMatchData.selfid = userid;
      parentMatchData.selfref = parentMatchSnapshot.ref;
      loadedData.matching.matchMe = parentMatchData;
      loadedData.matching.filter = loadedData.myUserProfile.currentMatchFilters;

      let matchingRes = await populateDeck(
        parentMatchSnapshot.ref,
        loadedData.myCompany.selfid,
        [],
        [],
        loadedData.matching.filters
      );
      if (
        matchingRes.deck.length === 0 &&
        Object.keys(matchingRes.hand).length === 0
      ) {
        await generateBackendMatches(
          userid,
          "single",
          [loadedData.myCompany.selfid],
          [],
          loadedData.myUserProfile.currentMatchFilters,
          [loadedData.myCompany.selfid]
        );
        matchingRes = await populateDeck(
          parentMatchSnapshot.ref,
          loadedData.myCompany.selfid,
          [],
          [],
          loadedData.matching.filter
        );
      }
      loadedData.matching.deck = matchingRes.deck;
      loadedData.matching.hand = matchingRes.hand;
    } else {
      if (newlyCreatedParent === false) {
        // Conditional here to prevent any loops of running keywords incase parent not working
        try {
          await generateBackendMatches(
            userid,
            "single",
            [loadedData.myCompany.selfid],
            [],
            loadedData.myUserProfile.currentMatchFilters,
            [loadedData.myCompany.selfid]
          );
          await getDeckT1(userid, loadedData, true);
        } catch (error) {
          console.log("Error Caught: getDeckT1 from PreLoadFunctions", error);
        }
      }
    }
  } catch (error) {
    console.log("Error getting deck t1?", error);
  }
}

// Loads My Deck(T2)
export async function getDeckT2(userid, loadedData) {
  try {
    let parentMatchSnapshot = await myFirebase.firestore().collection("matchCache").doc(userid).get();
    if (!parentMatchSnapshot.empty) {
      let parentMatchData = parentMatchSnapshot.data();
      parentMatchData.selfid = userid;
      parentMatchData.selfref = parentMatchSnapshot.ref;
      loadedData.matching.matchMe = parentMatchData;
      loadedData.matching.filter = loadedData.myUserProfile.currentMatchFilters;

      let splitHandPath = parentMatchData.currentHand.path.split("/");
      let properSubcollection = splitHandPath[splitHandPath.length - 2];

      let matchingRes = await populateDeck(
        parentMatchSnapshot.ref,
        properSubcollection,
        [],
        loadedData.myEcosystem,
        loadedData.matching.filter
      );
      loadedData.matching.deck = matchingRes.deck;
      loadedData.matching.hand = matchingRes.hand;

      // Gets the generate
      let parameterLocalized = {
        generate: parentMatchData.currentParameters.generate,
        read: {
          currentList: [],
          ecosystem: loadedData.myEcosystem,
          mainCacheRef: parentMatchSnapshot.ref,
          subcollectionid: properSubcollection,
        },
      };
      loadedData.matching.parameters = parameterLocalized;
    }
  } catch (error) {
    console.log("Error Caught: getDeckT2 from PreLoadFunctions", error);
  }
}
